import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { StocksDefineComponent } from './define/stocks.define.component';
import { StocksLibraryComponent } from './library/stocks.library.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import {AuthGuardService as AuthGuard} from '../authGuard/auth-guard.services';


const routes = [
    {
        path     : 'stocks/define',
        component: StocksDefineComponent, canActivate: [AuthGuard]
    },
    {
        path     : 'stocks/define/:id',
        component: StocksDefineComponent, canActivate: [AuthGuard]
    },
    {
        path     : 'stocks/library',
        component: StocksLibraryComponent, canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        StocksDefineComponent,
        StocksLibraryComponent,

    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports     : [
        StocksDefineComponent,
        StocksLibraryComponent,

    ]
})

export class StocksModule
{
}
