import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import 'rxjs/add/operator/debounceTime';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MatTableDataSource } from '@angular/material';
import { ApiService } from '../../../services/api/api.service';
import * as moment from 'moment';
@Component({
    selector: 'StocksDefineComponent',
    templateUrl: './stocks.define.component.html',
    styleUrls: ['./stocks.define.component.scss']
})
export class StocksDefineComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    productForm: FormGroup;
    displayedColumns = ['name', 'color', 'size', 'quantity', 'productRow', 'productColumn', 'details', 'price', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    public filterControl = new FormControl();
    public filteredProducts: any;
    public products: any = [];
    characteristicsList: any = [];
    characteristicTypesList: any;
    colorOptions: any = [];
    sizeOptions: any = [];
    colorForm = new FormControl();
    sizeForm = new FormControl();
    selectedProductSizes: any = [];
    selectedProductColors: any = [];
    currentSaveProductEntity: any = [];
    productRow: any = [];
    productColumn: any = [];
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.productRow = Array.from(Array(15)).map((e, i) => i + 1);
        this.productColumn = Array.from(Array(15)).map((e, i) => i + 1);
        this.productForm = fb.group({
            'name': null,
            'quantity': null,
            'details': null,
            'color': null,
            'size': null,
            'price': null,
            'productRow': null,
            'productColumn': null,
            'id': null,
        });
        this.dataSource = new MatTableDataSource();
        this.api.getAll('Product').then(param1 => {
            this.products = param1;
            this.api.getAll('CharacteristicType').then(param2 => {
                this.characteristicTypesList = param2;
                this.api.getAll('Characteristic').then(param => {
                    for (const characteristic of <any>param) {
                        this.characteristicsList[characteristic._id] = {
                            'value': characteristic.value,
                            'type': this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name
                        };
                        if (this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name === 'color') {
                            this.colorOptions[characteristic._id] = {
                                'value': characteristic.value
                            };
                        }
                        else if (this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name === 'size'){
                            this.sizeOptions[characteristic._id] = {
                                'value': characteristic.value
                            };
                        }
                    }
                });
            });
        });
        this.productForm.controls['name'].disable();
        this.productForm.controls['price'].disable();
    }
    disableSubmit(): boolean {
        if (this.productForm.value.quantity && this.colorForm.value && this.sizeForm.value) {
            return false;
        }
        return true;
    }

    onSubmit(): void {
        const selectedProduct = this.filteredProducts.find(elem=> elem._id == this.productForm.controls['id'].value)
        this.currentSaveProductEntity = {
            'id': selectedProduct._id,
            'name': selectedProduct.name,
            'color': this.colorForm.value,
            'size': this.sizeForm.value,
            'productRow': selectedProduct.productRow,
            'productColumn': selectedProduct.productColumn,
            'quantity': this.productForm.value.quantity,
            'details': this.productForm.value.details,
            'price': selectedProduct.price,
        };
        this.dataSource.data.push(this.currentSaveProductEntity);
        this.dataSource._updateChangeSubscription();
        this.productForm.reset();
        this.colorForm.reset();
        this.sizeForm.reset();
        this.filterControl.reset();
        this.filteredProducts = [];
        this.selectedProductColors = [];
        this.selectedProductSizes = [];
    }

    public displayFn(object) {
        if (object) {
            if (object.name) {
                return object.name
            }
            else {
                return null;
            }
        }
        // return object ? object.identifier : null;
    }

    ngOnInit(): void {
        this.filterControl.valueChanges
            .debounceTime(500) // <-- la fiecare 500 de secunde sa nu rupi banda
            .subscribe(query => {
                this.selectedProductSizes = [];
                this.selectedProductColors = [];
                if (query !== null && query.length >= 1) {
                    this.filteredProducts = this.products.filter(elem => {
                        return elem.name.toLowerCase().indexOf(query.toLowerCase()) != -1
                    });
                }
                else if (query && query._id) {
                    this.productForm = this.fb.group({
                        'name': query.name,
                        'quantity': null,
                        'productRow': query.productRow,
                        'productColumn': query.productColumn,
                        'details': null,
                        'color': null,
                        'size': null,
                        'price': query.price,
                        'id': query._id
                    });
                    this.productForm.controls['name'].disable();
                    this.productForm.controls['price'].disable();
                    this.productForm.controls['productRow'].disable();
                    this.productForm.controls['productColumn'].disable();
                    for (const characteristic of query.characteristics) {
                        if (this.sizeOptions[characteristic]) {
                            this.selectedProductSizes.push({
                                'value': this.sizeOptions[characteristic].value,
                                'id': characteristic
                            });
                        }
                        if (this.colorOptions[characteristic]) {
                            this.selectedProductColors.push({
                                'value': this.colorOptions[characteristic].value,
                                'id': characteristic
                            });
                        }
                    }
                }
            });
    }

    delete(row: UserData): void {
        this.dataSource.data.splice(this.dataSource.data.indexOf(row), 1);
        this.dataSource._updateChangeSubscription();
    }

    sendOrder(): void {
        const productsInOrder: any = [];
        for (const product of this.dataSource.data) {// for each product in table
            let colorId, sizeId;
            for (const characteristic in this.characteristicsList) {// find characteristic id
                if (this.characteristicsList[characteristic].value === product['color']) {
                    colorId = characteristic;
                }
                else if (this.characteristicsList[characteristic].value === product['size']) {
                    sizeId = characteristic;
                }
            }
            productsInOrder.push({
                'product': product.id,
                'quantity': product.quantity,
                'details': product.details,
                'characteristics': [colorId, sizeId],
                'price': product.price,
                'producedAt': Date.now(),
                'order': null,
            });

        }
        let productsToAdd = [];
        productsInOrder.forEach(product => {
            productsToAdd = productsToAdd.concat(this.separateOrderProductsQty1(product));
        });
        this.api.post('OrderProduct', productsToAdd).then(param => {
            this.router.navigate(['stocks/library']);
        });
    }
    separateOrderProductsQty1(product): any {
        const orderProductsToCreateWithQuantity = [];
        let counter = product.quantity;
        while (counter >= 1) {
            orderProductsToCreateWithQuantity.push({
                'product': product.product,
                'details': product.details,
                'characteristics': product.characteristics,
                'price': product.price,
                'producedAt': product.producedAt,
                'order': null,
            });
            counter--;
        }
        return orderProductsToCreateWithQuantity;
    }
}

export interface UserData {
    id: string;
    name: string;
    size: string;
    color: string;
    details: string;
    quantity: string;
    price: string;
}

