import { Component, Inject, OnInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../../services/api/api.service';
import * as moment from 'moment'
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-emag-offer.component',
  templateUrl: './emag-offer.component.html',
  styleUrls: ['./emag-offer.component.scss']
})



export class EmagOfferComponent implements OnInit {
  public offerForm: FormGroup;
  displayedColumns = [];
  dataSource = new MatTableDataSource([]);
  addState = false;
  dt1 = null;
  displayLoader = false;
  data = null
  usedDates = [];
  nowTimestamp = moment().unix();
  constructor(
    public dialogRef: MatDialogRef<EmagOfferComponent>,
    public fb: FormBuilder,
    public api: ApiService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.data = info;
    this.initializeForm();
    this.prepareTable();
  }
  public initializeForm() {
    this.offerForm = this.fb.group({
      startDate: new FormControl(null, [
        Validators.required
      ]),
      salePrice: new FormControl(null, [
        Validators.required
      ]),
      minPrice: new FormControl(null, [
        Validators.required
      ]),
      maxPrice: new FormControl(null, [
        Validators.required
      ]),
      recommendedPrice: new FormControl(null, [
        Validators.required
      ]),
      initialStock: new FormControl(null, [
        Validators.required
      ]),
      id: new FormControl(null),
      status: new FormControl(null),
    })
  }
  private prepareTable() {
    this.displayedColumns = ['startDate', 'minPrice', 'maxPrice', 'salePrice', 'recommendedPrice', 'initialStock', 'status', 'details', 'actions'];
  }
  public submit() {
    this.displayLoader = true;
    this.api.obsPost('product_offer', {
      productId: this.data.id,
      startDate: this.offerForm.get('startDate').value,
      salePrice: this.offerForm.get('salePrice').value,
      recommendedPrice: this.offerForm.get('recommendedPrice').value,
      minSalePrice: this.offerForm.get('minPrice').value,
      maxSalePrice: this.offerForm.get('maxPrice').value,
      stock: this.offerForm.get('initialStock').value,
      status: this.offerForm.get('status').value,
    }).subscribe(res => {
      this.displayLoader = false;
      this.changeAddState();
      this.getProductOffer();
    }, err => {
      console.log(err);
      this.displayLoader = false;
      this.snackBar.open(err.error, 'Inchide', {
        duration: 5000,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
    })
  }

  public closeDialog() {
    this.dialogRef.close();
  }
  public changeAddState() {
    this.addState = !this.addState;
    if (this.addState) {
      if (this.dataSource.data.find(e => e.current)) {
        this.populateFormWithActiveOffer(this.dataSource.data.find(e => e.current))
      }
      else if (0 != this.dataSource.data.length) {
        this.populateFormWithActiveOffer(this.dataSource.data[0])
      }
    }
    else {
      this.initializeForm();
    }
  }
  private populateFormWithActiveOffer(offer, isEdit = false) {
    if (isEdit) {
      this.offerForm.get('id').setValue(offer.id);
      const [month, day, year] = offer.startDate.split('-')
      const date = new Date(year, parseInt(month) - 1, day);
      this.offerForm.get('startDate').setValue(date);
      this.offerForm.get('startDate').setErrors(null);
    }
    else {
      this.offerForm.get('startDate').setValue(null);
    }
    this.offerForm.get('salePrice').setValue(offer.salePrice);
    this.offerForm.get('minPrice').setValue(offer.minPrice);
    this.offerForm.get('maxPrice').setValue(offer.maxPrice);
    this.offerForm.get('recommendedPrice').setValue(offer.recommendedPrice);
    this.offerForm.get('initialStock').setValue(offer.initialStock);
    this.offerForm.get('status').setValue(offer.status);
  }
  public edit(offer) {
    this.populateFormWithActiveOffer(offer, true);
    if (!this.addState) {
      this.addState = !this.addState;
    }
  }
  public cancel(offer) {
    this.populateFormWithActiveOffer(offer, true);
    this.submit();
  }
  public logger(e) {
    console.log(e);
  }
  private findCurrentOffer(offers) {
    offers.sort((a: any, b: any) => {
      return moment(b.startDate).unix() - moment(a.startDate).unix();
    });
    let stop = false;
    offers.forEach(offer => {
      const date = new Date(offer.startDate);
      this.usedDates.push({
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear()
      });
      if (!stop && this.nowTimestamp > moment(offer.startDate).unix()) {
        offer.current = true;
        stop = true;
      }
    });
  }
  public dateFilter = (d: Date): boolean => {
    if (d.getTime() / 1000 < this.nowTimestamp) {
      return false;
    }
    return !this.usedDates.find(date => d.getDate() == date.day && d.getMonth() == date.month && d.getFullYear() == date.year);
  }
  public disableActionButtons(offer){
    const [month, day, year] = offer.startDate.split('-')
    const date = new Date(year, parseInt(month) - 1, day);
    if (date.getTime() / 1000 < this.nowTimestamp) {
      return true;
    }
    return false;
  }
  private getDetailsFromEmagResponse(emagDetails){
    if(0 == emagDetails.length){
      return 'N/A'
    }
  }
  private getProductOffer() {
    this.displayLoader = true;
    this.dataSource.data = [];
    this.dataSource._updateChangeSubscription();
    this.api.obsGet(`product_offer?productId=${this.data.id}`).subscribe((res: any) => {
      this.findCurrentOffer(res);
      res.forEach(element => {
        this.dataSource.data.push({
          startDate: moment(element.startDate).add(moment().utcOffset()).format('MM-DD-YYYY'),
          salePrice: element.salePrice,
          minPrice: element.minSalePrice,
          maxPrice: element.maxSalePrice,
          recommendedPrice: element.recommendedPrice,
          initialStock: element.stock,
          details: this.getDetailsFromEmagResponse(element.emagDetails),
          status: element.status,
          current: element.current ? element.current : false,
          id: element._id
        });
      });
      this.dataSource._updateChangeSubscription();
      this.displayLoader = false;
    }, err => {
      this.displayLoader = false;
    });
  }
  ngOnInit(): void {
    this.getProductOffer();
  }
}