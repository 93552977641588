import {Component} from '@angular/core';

import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {locale as english} from './i18n/en';
import {locale as turkish} from './i18n/tr';

import {ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource, MatTableModule} from '@angular/material';
import {ApiService} from '../../../../services/api/api.service';
import {Router} from '@angular/router';

@Component({
    selector: 'InvoicesPLibraryComponent',
    templateUrl: './invoicesP.library.component.html',
    styleUrls: ['./invoicesP.library.component.scss']
})
export class InvoicesPLibraryComponent {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    displayedColumns = ['serialKey', 'serialNumber', 'reservedNumbers', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private api: ApiService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.api.getAll('proformDetailSet').then(param => {
            const proformDetailSetDummy: UserData[] = <any>param;
            this.dataSource = new MatTableDataSource(proformDetailSetDummy);
        });
    }

//     ngAfterViewInit() {
//     this.dataSource.paginator = this.paginator;
//     this.dataSource.sort = this.sort;
// }
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }
    edit(id: number): void {
        this.router.navigate(['invoiceP/define', id]);
    }
}

export interface UserData {
    serialKey: string;
    serialNumber: string;
    reservedNumbers: string;
    buttons: string;
}
