import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialogConfig, MatDialog } from '@angular/material';
import { Router } from "@angular/router";
import { ApiService } from '../../../../services/api/api.service';
import { DialogDataComponent } from '../../../GenericDialog/dialogData.component';


@Component({
  selector: 'ClientsLibraryComponent',
  templateUrl: './clients.library.component.html',
  styleUrls: ['./clients.library.component.scss']
})
export class ClientsLibraryComponent {
  displayedColumns = ['firstName', 'address', 'iBan', 'phoneNumber', 'buttons'];
  dataSource: MatTableDataSource<UserData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    public api: ApiService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);

    this.api.getAll('Client').then((param: any) => {
      param.forEach(client => {
        client.name = client.firstName + ' ' + client.surName
      })
      const clientData: UserData[] = param;
      this.dataSource = new MatTableDataSource(clientData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }
  edit(id: number) {
    this.router.navigate(['client/define', id]);
  }

  delete(row: UserData) {
    this.api.delete('Client', row['_id'].toString()).then(() => {
      this.dataSource.data.splice(this.dataSource.data.indexOf(row), 1);
      this.dataSource._updateChangeSubscription();
    }).catch(() => {
    });
  }

  view(id: object): void {

    this.api.getOne('Client', { 'id': id }).then(param => {

      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.ariaLabel = 'Punct de vanzare';
      dialogConfig.width = '50%';
      dialogConfig.data = {
        'id': id,
        'title': 'Punct de vanzare',
        'Nume': param['firstName'],
        'Prenume': param['surName'],
        'Judet': param['district'],
        'Localitate': param['locality'],
        'Adresa': param['address'],
        'Numar de telefon': param['phoneNumber'],
        'IBan': param['iBan'] ? param['iBan'] : '-',
        'email': param['email'] ? param['email'] : '-',
        'Cod postal': param['postalCode']
      };

      this.dialog.open(DialogDataComponent, dialogConfig);

    });

  }
}
export interface UserData {
  firstName: string;
  surName: string;
  address: string;
  name: string;
  iBan: string;
}
