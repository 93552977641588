import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service'
import { MatSelectModule } from '@angular/material';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { take, takeUntil } from 'rxjs/operators';
import { LocalitiesService } from '../../../../services/Localities/localities.service';

interface district {
  name: string;
  StateId: string;

}
interface locality {
  name: string;
  StateId: string;
}
interface PostalCode {
  address: string;
  postalCode: string;
}
@Component({
  selector: 'ClientsDefineComponent',
  templateUrl: './clients.define.component.html',
  styleUrls: ['./clients.define.component.scss']
})
export class ClientsDefineComponent {
  /**
   * Constructor
   *
   * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
   */
  /** control for the selected district */
  public districtCtrl: FormControl = new FormControl();
  public localityCtrl: FormControl = new FormControl();
  public localityDefault: any = { 'name': 'Alta localitate' };
  public postalCodeCtrl: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  public districtFilterCtrl: FormControl = new FormControl();
  public localityFilterCtrl: FormControl = new FormControl();
  public postalCodeFilterCtrl: FormControl = new FormControl();
  /** list of districts */
  private districts: any[] = [];
  //  LocalitiesJson.bmc_states;
  private localities: any[] = [];
  private postalCodes: any[] = [];
  /** list of districts filtered by search keyword */
  public filtereddistricts: ReplaySubject<district[]> = new ReplaySubject<district[]>(1);
  public filteredLocalities: ReplaySubject<locality[]> = new ReplaySubject<locality[]>(1);
  public filteredPostalCodes: ReplaySubject<PostalCode[]> = new ReplaySubject<PostalCode[]>(1);
  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  editFields: any;
  clientsForm: FormGroup;
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private api: ApiService,
    private LocalitiesJson: LocalitiesService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    this.activeRoute.params.subscribe(params => { this.editFields = params; });
    this.districts = Object.keys(this.LocalitiesJson.getLocalities());

    // this.districtCtrl.setValue(this.districts[0]);
    this.localities = [];
    // this.localityCtrl.setValue(this.localities[0]);
    this.postalCodes = [];
    // this.postalCodeCtrl.setValue(this.postalCodes[0]);
    this.clientsForm = fb.group({
      'firstName': null,
      'surName': null,
      'address': null,
      'iBan': '',
      'phoneNumber': null,
      'district': null,
      'locality': null,
      'email': [null, [Validators.required, Validators.email]],
      'postalCode': null,
    });

    // this.districtCtrl.setValue(this.districts[9]);
    // this.localityCtrl.setValue(this.localities[9]);

    if (this.editFields.id) {

      this.api.getOne('client', { 'id': this.editFields.id }).then(param => {
        console.log(this.districts.find(district => district == param['district']));
        if (typeof this.districts.find(district => district == param['district']) == 'undefined') {
          param['district'] = null;
          param['locality'] = null;
          param['postalCode'] = null;
        }
        this.clientsForm = fb.group({
          'id': param['_id'],
          'firstName': param['firstName'],
          'surName': param['surName'],
          'address': param['address'],
          'iBan': param['iBan'],
          'phoneNumber': param['phoneNumber'],
          'district': param['district'],
          'locality': param['locality'],
          'email': param['email'],
          'postalCode': param['postalCode']
        });
        this.districtCtrl.setValue(param['district']);
        this.localityCtrl.setValue(param['locality']);

      });

    }

  }
  onClick() {
    //this.router.navigate(['employee/define', '2'])
  }
  disableSubmit(): boolean {
    if (this.clientsForm.value.firstName && this.clientsForm.value.surName
      && this.clientsForm.value.address && this.districtCtrl.value && this.localityCtrl.value && this.clientsForm.value.phoneNumber !== null
      && this.clientsForm.value.phoneNumber.length === 10 && this.postalCodeCtrl.value && (this.clientsForm.value.iBan.length === 24 || this.clientsForm.value.iBan.length === 0)) {
      return false;

    }
    return true;
  }

  onSubmit(value) {
    this.clientsForm.value.district = this.districtCtrl.value;
    this.clientsForm.value.locality = this.localityCtrl.value;
    this.clientsForm.value.postalCode = this.postalCodeCtrl.value.postalCode;
    if (this.clientsForm.value.email == null || this.clientsForm.value.email.length === 0) {
      this.clientsForm.value.email = null;
    }
    this.api.post('Client', this.clientsForm.value).then(param => {
      this.router.navigate(['client/library']);
    });

  }
  ngOnInit(): void {
    this.activeRoute.params.subscribe(params => console.log(params));

    // load the initial district list
    this.filtereddistricts.next(this.districts.slice());
    this.filteredLocalities.next(this.localities.slice());
    this.filteredPostalCodes.next(this.postalCodes.slice());
    // listen for search field value changes
    this.districtFilterCtrl.valueChanges.debounceTime(500)
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterdistricts();
      });
    // this.filteredLocalities.next(this.localities.slice());
    this.localityFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {

        this.filterLocalities();

      });
    this.postalCodeFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
      this.filterPostaCodes();
    });
    // selected district
    this.districtCtrl.valueChanges.debounceTime(500).subscribe(currentDistrict => {
      if (this.districts.includes(this.districtCtrl.value)) {
        this.districtFilterCtrl.setValue(currentDistrict);
        this.filtereddistricts.next(this.districts.slice());
        this.localities = this.LocalitiesJson.getLocalities()[currentDistrict];
        this.filteredLocalities.next(this.localities.slice());
        this.postalCodes = [];
        this.filteredPostalCodes.next(this.postalCodes);
      }
    });
    this.localityCtrl.valueChanges.debounceTime(500).subscribe(currentLocality => {
      if (this.localities.includes(currentLocality)) {
        this.api.get('/getPostalCodes?county=' + this.districtCtrl.value + '&city=' + this.localityCtrl.value).subscribe((result: any) => {
          this.postalCodes = result.address;
          this.filteredPostalCodes.next(this.postalCodes.slice());
          if (this.editFields.id) {

            this.postalCodeCtrl.setValue({ postalCode: this.clientsForm.value.postalCode });
          }


        });
      }
    });
    // this.postalCodeCtrl.valueChanges.debounceTime(500).subscribe(currentPostalCode => {
    //   console.log(this.localities);
    //   console.log(this.postalCodes);
    //   this.postalCodeFilterCtrl.setValue('plm');
    //   console.log('outusdsa');
    //   if (this.postalCodes.includes(currentPostalCode)) {
    //     console.log('inside this.');
    //   }
    // });
  }
  private filterdistricts() {
    if (!this.districts) {
      return;
    }
    // get the search keyword
    let search = this.districtFilterCtrl.value;
    if (!search) {
      // this.districts = Object.keys(this.LocalitiesJson.getLocalities());
      this.filtereddistricts.next(this.districts.slice());
      return;
    } else {
      search = search.toLowerCase();
      this.districtFilterCtrl.setValue(search);
      // this.districts = Object.keys(this.LocalitiesJson.getLocalities());
      this.filtereddistricts.next(this.districts.slice());
    }
    // filter the districts
    this.filtereddistricts.next(
      this.districts.filter(district => district.toLowerCase().indexOf(search) > -1),
    );
  }
  public compareWith(a, b): boolean { return a === b; }
  public compareWithPostalCode(a: any, b: any): boolean { return a && b ? a.postalCode === b.postalCode : a === b; }
  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  private filterLocalities(): void {
    if (!this.localities) {
      return;
    }
    // get the search keyword
    let search = this.localityFilterCtrl.value;
    if (!search) {
      this.filteredLocalities.next(this.localities.slice());

      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the localities
    this.filteredLocalities.next(
      this.localities.filter(locality => locality.toLowerCase().indexOf(search) > -1),
    );

  }
  private filterPostaCodes(): void {
    if (!this.postalCodes) {
      return;
    }
    // get the search keyword
    let search = this.postalCodeFilterCtrl.value;
    if (!search) {
      this.filteredPostalCodes.next(this.postalCodes.slice());

      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the localities
    this.filteredPostalCodes.next(
      this.postalCodes.filter(postalCode => postalCode.address.toLowerCase().indexOf(search) > -1),
    );
  }

}
