import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { DashboardComponent } from './dashboard.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { AuthGuardService as AuthGuard } from 'app/main/modules/authGuard/auth-guard.services';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';

const routes = [
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    providers: [
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }
    ],
    declarations: [
        DashboardComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports: [
        DashboardComponent
    ]
})

export class DashboardModule {
}
