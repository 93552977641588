import { Injectable, Component, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class PdfMakeService {
  
     buildGenericTableBody(data, columns, headerText): any {
        var body = [];
        const headersStyled = [];
        headerText.forEach( elem => {
            headersStyled.push({
                text: elem,
                fontSize: 14,
                bold: true
            });
        });
        body.push(headersStyled);
        data.forEach( (row) => {
            var dataRow = [];
            columns.forEach( (column) => {
                if (row[column] == null) {
                    row[column] = '-';
                }
                dataRow.push({
                    widths: '*',
                    text: row[column] != null ? row[column].toString(): '-'
                });
            });
            body.push(dataRow);
        });
        return body;
     }
     
      genericTable(data, columns, headerText): any {
        return this.buildGenericTableBody(data, columns, headerText);
     }
      getWidth(headerText): any {
        const widthArr = [];
        headerText.forEach((value,index) => {
            if(index == 0){
                widthArr.push('*');
            }
            else if (typeof value != 'undefined') {
                widthArr.push('auto');
            }
        });
        return widthArr;
     }
     genericPdfmake(data): any {
         console.log(data);
        const date = new Date();
        return {
            content: [
                {
                    text:[{text:date.getDate()+'/' + (date.getMonth() +1) +'/' +date.getFullYear() }]
                },
                {
                    width: "*",
                    text: [{
                        text: data.reportTitle,
                        fontSize: 30,
                        alignment: "center",
                    }]
                },
                {
                    text: [{
                        text: data.from + '-' + data.to,
                        alignment: 'center',
                    }],
                    marginTop: 10,
                    marginBottom: 10,
                },
                {
                    text: [{
                        text: data.subTitle,
                        
                    }],
                    marginTop: 10,
                    marginBottom: 10,
                },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        dontBreakRows: true,
                        widths: this.getWidth(data.headers),
                        body: this.genericTable(data.body,  data.headers, data.headersText),
                        heights: 20,
                    },
                 
     
                },
     
            ],
            footer:  (pagenumber, pagecount) => {
                return {
                    margin: [540, 0, 0, 0, 0],
                    text: pagenumber + '/' + pagecount
                };
            },
        }
     }
}
