import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MatTableModule } from '@angular/material';
import { Router } from '@angular/router';
import { DialogDataComponent } from '../../../GenericDialog/dialogData.component';
import { ApiService } from '../../../../services/api/api.service';

declare function require(url: string);

var jsonDB = require('../../../db.json');
// import {MatTableModule} from '@angular/material';
//json local db


@Component({
    selector: 'EmployeesLibrary',
    templateUrl: './employees.library.component.html',
    styleUrls: ['./employees.library.component.scss']
})
export class EmployeesLibraryComponent {
    displayedColumns = ['name', 'phoneNumber', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
        public api: ApiService,
        public dialog: MatDialog
    ) {

        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        this.api.getAll('Employee').then(param => {
            const employeeData: UserData[] = <any>param;
            this.dataSource = new MatTableDataSource(employeeData);
        });

        //     // this.db = jsonDB.employees;
        //     // this.userTypesBD = jsonDB.userTypes;
        //     // this.dataSource = new MatTableDataSource(this.db);
    }



    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    ngOnInit(): void {
    }

    edit(id: number) {
        this.router.navigate(['employee/define', id]);
    }

    delete(row: UserData) {
        this.api.delete('Employee', row['_id'].toString()).then(() => {
            this.dataSource.data.splice(this.dataSource.data.indexOf(row), 1);
            this.dataSource._updateChangeSubscription();
        }).catch(() => {
        });
    }

    view(id: number): void {
        this.api.getOne('Employee', { 'id': id }).then(param => {

            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.ariaLabel = 'Angajat';
            dialogConfig.width = '50%';

            dialogConfig.data = {
                'id': id,
                'Nume': param['name'],
                'Numar de telefon': param['phoneNumber'],
                'title': 'Angajat'
            };

            this.dialog.open(DialogDataComponent, dialogConfig);

        });
    }
}

export interface UserData {
    name: string;
    phoneNumber: string;
    buttons: string;
}
