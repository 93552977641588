import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import {Router} from "@angular/router";

@Component({
    selector   : 'dashboard',
    templateUrl: './logout.component.html',
    styleUrls  : ['./logout.component.scss']
})
export class LogoutComponent
{
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        localStorage.removeItem('authToken');
        this.router.navigate(['/auth/login']);
    }
}
