import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatButtonModule, MatCardModule, MatFormFieldModule,
    MatInputModule, MatTabsModule, MatToolbarModule,
    MatSidenavModule, MatIconModule, MatListModule,
    MatExpansionModule, MatSelectModule, MatDatepickerModule,
    MatNativeDateModule, MatTableModule, MatRadioModule,
    MatTooltipModule, MatAutocompleteModule, MatDialogModule,
    MatChipsModule, MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSortModule,
    MatPaginatorModule, MatSlideToggleModule
} from '@angular/material';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatRadioModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatChipsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatPaginatorModule,
        MatSlideToggleModule

    ],
    exports: [
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatToolbarModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatExpansionModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatTableModule,
        MatRadioModule,
        MatTooltipModule,
        MatAutocompleteModule,
        MatDialogModule,
        MatChipsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSortModule,
        MatPaginatorModule,
        MatSlideToggleModule

    ]
})
export class NgMatModule {
}
