import { NgModule } from '@angular/core';
import { DashboardModule } from 'app/main/modules/dashboard/dashboard.module';
import { ProductionPointsModule } from 'app/main/modules/crm/production-points/productionPoints.module';
import { EmployeesModule } from 'app/main/modules/crm/employees/employees.module';
import { UsersModule } from 'app/main/modules/crm/users/users.module';
import { ProductsModule } from 'app/main/modules/crm/products/products.module';
import { ClientsModule } from 'app/main/modules/crm/clients/clients.module';
import { OrdersModule } from 'app/main/modules/orders/orders.module';
import { InvoicesModule } from 'app/main/modules/business-intelligence/invoices/invoices.module';
import { InvoicesPModule } from 'app/main/modules/business-intelligence/invoices-p/invoicesP.module';
import { ReturnedProductsModule } from 'app/main/modules/business-intelligence/returnedProducts/returnedProducts.module';
import { InvoiceDetailsModule } from 'app/main/modules/business-intelligence/invoiceDetails/invoiceDetails.module';
import { StocksModule } from 'app/main/modules/stocks/stocks.module';
import { LogoutModule } from 'app/main/modules/logout/logout.module';
import { LoginModule } from 'app/main/modules/login/login.module';
import { ProductionModule } from './production/production.module';
import { SagaModule } from './business-intelligence/saga/saga.component.module';
import { ReportsModule } from './business-intelligence/reports-component/reports-component.component.module';
@NgModule({
    imports: [
        DashboardModule,
        ProductionPointsModule,
        EmployeesModule,
        UsersModule,
        ProductsModule,
        ClientsModule,
        OrdersModule,
        InvoicesModule,
        InvoicesPModule,
        ReturnedProductsModule,
        InvoiceDetailsModule,
        StocksModule,
        LogoutModule,
        LoginModule,
        ProductionModule,
        SagaModule,
        ReportsModule
    ],
    exports: [
        DashboardModule,
        ProductionPointsModule,
        EmployeesModule,
        UsersModule,
        ProductsModule,
        ClientsModule,
        OrdersModule,
        InvoicesModule,
        InvoicesPModule,
        ReturnedProductsModule,
        InvoiceDetailsModule,
        StocksModule,
        LogoutModule,
        SagaModule,
        ReportsModule
    ],
})
export class AllModule {
}
