import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

@Component({
    selector: 'ProductionHistoryComponent',
    templateUrl: './production.history.component.html',
    styleUrls: ['./production.history.component.scss']
})
export class ProductionHistoryComponent implements OnInit {
    displayedColumns = ['productName', 'size', 'color', 'addedInMold', 'worker', 'packedBy'];
    dataSource: MatTableDataSource<any>;
    dateFrom = new FormControl((moment(new Date())).subtract(7, 'd').toISOString());
    dateTo = new FormControl((new Date()).toISOString());
    characteristics: any = [];
    products: any = [];
    employees: any = [];
    isLoaded = true;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    constructor(public api: ApiService) {
    }
    ngOnInit(): void {
        this.api.getAll('User').then((employees: any) => {
            employees.map(employee => this.employees[employee._id] = employee);
            this.api.getAll('Characteristic').then((characteristics: any) => {
                characteristics.map(characteristic => this.characteristics[characteristic._id] = characteristic);
                this.api.getAll('Product').then((products: any) => {
                    products.map(product => this.products[product._id] = product);
                    this.searchByDate();
                });
            });

        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }
    searchByDate(): void {
        this.isLoaded = true;
        const ordersFrom = moment(this.dateFrom.value).format('YYYY-MM-DD') + 'T00:00';
        const ordersTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59';
        this.api.getByProductionDate('OrderProduct', { 'from': ordersFrom, 'to': ordersTo, params: [{ 'producedAt': { $ne: null }, 'moldId': { $ne: null } }] }).then((orderProducts: any) => {
            orderProducts.forEach(op => {
                op.productName = this.products[op.product].name
                op.color = this.characteristics[op.characteristics[0]].value
                op.size = this.characteristics[op.characteristics[1]].value
            });
            this.dataSource = new MatTableDataSource(orderProducts);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource._updateChangeSubscription();
            this.isLoaded = false;
        });
    }

}

