import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Form, Validators } from '@angular/forms';
import 'rxjs/add/operator/debounceTime';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSelectModule, MatSnackBar } from '@angular/material';
import { filter, isEmpty, single } from 'rxjs/operators';

import * as XLSX from 'xlsx';


import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Subject } from 'rxjs/Subject';
import { take, takeUntil } from 'rxjs/operators';
import { log } from 'util';
import { ApiService } from '../../../services/api/api.service';
import { LocalitiesService } from './../../../services/Localities/localities.service';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import * as moment from 'moment';
const COUNT_OF_PHONE_NUMBER_CHARACTERS_BEFORE_SEARCH = 4;
const MIN_VALUE_FOR_TRANSPORT_PAYD_BY_COMPANY = 300;
const DAYS_FOR_PRODUCTION_AND_DELIVERY = 2;
const DAYS_FOR_PRODUCTION_AND_DELIVERY_FOR_WEEKEND = 4;
interface district {
    name: string;
    StateId: string;
}

interface locality {
    name: string;
    StateId: string;
}

@Component({
    selector: 'OrdersDefineComponent',
    templateUrl: './orders.define.component.html',
    styleUrls: ['./orders.define.component.scss']
})

export class OrdersDefineComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    orderForm: FormGroup;
    productForm: FormGroup;
    public quantityController = new FormControl();
    public dimensionController = new FormControl();
    public filterControl = new FormControl();
    public filterControlProducts = new FormControl();
    public districtCtrl: FormControl = new FormControl();
    public orderProductsFromStock: any = [];
    public changeProductsFlag = false;
    public postalCodeCtrl: FormControl = new FormControl();
    displayedColumns = ['name', 'color', 'size', 'details', 'quantity', 'actions'];
    changeProductsColumns = ['name', 'color', 'size', 'details'];
    // this.dataSource.data list of products in the order
    dataSource: MatTableDataSource<UserData>;
    changeProductsDataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    /** control for the MatSelect filter keyword */
    public districtFilterCtrl: FormControl = new FormControl();
    /** list of districts filtered by search keyword */
    // public filteredClients=clients;
    public clients: any = [];
    public products: any = [];
    public filteredClients: any;

    public addedProducts: any = [];
    public similarStockProducts: any = [];
    characteristicTypesList: any;
    public selected: string;

    // public clients=jsonDB.clients;
    public newOrderID: string;
    public filteredProducts: any;
    public db: any;
    public dbProductionPoint: any;
    public deletePredefinedClient = true;
    public predefinedProduct = true;
    public characteristicsList: any = [];
    colorOptions: any = [];
    sizeOptions: any = [];
    selectedProductSizes: any = [];
    selectedProductColors: any = [];
    colorForm = new FormControl();
    sizeForm = new FormControl();
    selectedColor: any;
    selectedSize: any;

    public usedOrderProducts: any = [];
    public localityCtrl: FormControl = new FormControl();
    public localityDefault: any = { 'name': 'Alta localitate' };
    public localityFilterCtrl: FormControl = new FormControl();
    public postalCodeFilterCtrl: FormControl = new FormControl();
    public filtereddistricts: ReplaySubject<district[]> = new ReplaySubject<district[]>(1);
    public filteredLocalities: ReplaySubject<locality[]> = new ReplaySubject<locality[]>(1);
    public filteredPostalCodes: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
    public modifyOrderFlag = false;
    /** list of districts */
    private districts: any[] = [];
    private localities: any[] = [];
    private postalCodes: any[] = [];
    private _onDestroy = new Subject<void>();
    private orderToChange;
    changeItems;
    productsWithDetailsSelected = [];
    beginProductionDate = new FormControl();
    public isLoaded = true;


    // public clients; // ,<-- asta e JSON-ul pe care il filtrezi
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        public dialog: MatDialog,
        private router: Router,
        private LocalitiesJson: LocalitiesService,
        public snackBar: MatSnackBar,
    ) {

        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        // this.districts = this.LocalitiesJson.getLocalities().bmc_states;
        this.districts = Object.keys(this.LocalitiesJson.getLocalities());
        // this.activeRoute.params.subscribe(params => {
        //     this.editFields = params;
        // });

        // this.localities = this.LocalitiesJson.getLocalities().bmc_zones;
        // this.localities = this.localities.filter(locality => locality.StateId === '1');
        // this.api.getAll('Client').then(param => {
        // this.clients = param;
        // });
        ////////////////////
        // change products//
        ////////////////////


        this.orderForm = fb.group({
            'firstName': null,
            'surName': null,
            'address': null,
            'iBan': '',
            'phoneNumber': null,
            'district': null,
            'locality': null,
            'clientId': null,
            'email': [null, [Validators.email]],
            'postalCode': null,
        });
        this.productForm = fb.group({
            'id': null,
            'name': null,
            'quantity': null,
            'stock': null,
            'details': null,
            'color': null,
            'size': null,
            'price': null,
            'orderProduct': null
        });


    }
    dateFilter = (d: Date): boolean => {
        const day = d.getDay();
        return day !== 0;
    }
    clientNumberChange(): void {
        if (this.filterControl.value.length == COUNT_OF_PHONE_NUMBER_CHARACTERS_BEFORE_SEARCH) {
            this.api.findMany('Client', { "phoneNumber": { "$regex": this.filterControl.value, "$options": "i" } }).then(clients => {
                this.clients = clients;
                this.filteredClients = this.clients;
            });
        }
        else if (this.filterControl.value.length > COUNT_OF_PHONE_NUMBER_CHARACTERS_BEFORE_SEARCH) {
            this.filteredClients = this.clients.filter(client => client.phoneNumber.search(this.filterControl.value) !== -1);

            if (!(typeof this.filteredClients[0] === 'undefined') && this.filteredClients[0].phoneNumber === this.filterControl.value) {
                // this.districtCtrl.setValue(this.LocalitiesJson.getLocalities().bmc_states.filter(district => district.name === this.filteredClients[0].district)[0]);

                // this.localities = this.LocalitiesJson.getLocalities().bmc_zones.filter(locality => locality.StateId === this.districtCtrl.value.StateId);

                // this.localityCtrl.setValue(this.localities.filter(locality => locality.name === this.filteredClients[0].locality)[0]);
                this.setClientForm(this.filteredClients);

                this.deletePredefinedClient = true;
            }
            else if (this.deletePredefinedClient) {
                this.resetOrderForm();
                this.deletePredefinedClient = false;
            }
        }
    }
    resetOrder(): void {
        if (typeof localStorage.changeProducts !== 'undefined') {
            localStorage.removeItem('changeProducts');
        }
        if (typeof localStorage.editOrder !== 'undefined') {
            localStorage.removeItem('editOrder');
        }
        if (typeof this.dataSource !== 'undefined') {
            this.dataSource.data = [];
            this.dataSource._updateChangeSubscription();
        }
        this.productsWithDetailsSelected = [];
        this.addedProducts = [];
        this.resetOrderForm();
        this.filterControl.reset();
        this.changeProductsFlag = false;
    }
    addProduct(): void {
        let colorID;
        let sizeID;
        for (const characteristicID in this.characteristicsList) {
            if (this.characteristicsList[characteristicID].value === this.selectedColor) {
                colorID = characteristicID;
            }
            else if (this.characteristicsList[characteristicID].value === this.selectedSize) {
                sizeID = characteristicID;
            }
        }
        this.addedProducts.push({
            'name': this.filteredProducts[0].name,
            'color': this.selectedColor,
            'size': this.selectedSize,
            'colorID': colorID,
            'sizeID': sizeID,
            'productID': this.filteredProducts[0]._id,
            'quantity': 1,
            'details': this.productForm.controls['details'].value,
            'price': this.filteredProducts[0].price,
        });
        this.dataSource = new MatTableDataSource(this.addedProducts);
        this.dataSource.data = this.addedProducts;
        this.sizeForm.reset();
        this.colorForm.reset();
        this.selectedProductSizes = [];
        this.selectedProductColors = [];
        this.productForm.reset();
        this.filterControlProducts.reset();
        this.filteredProducts = [];
    }

    deleteProduct(row): void {
        this.addedProducts.splice(this.addedProducts.indexOf(row), 1);
        this.dataSource.data = this.addedProducts;
        this.dataSource._updateChangeSubscription();
    }

    disableSubmit(): boolean {
        if (this.selectedColor && this.selectedSize) {
            return false;
        }
        return true;
    }

    disableSubmitOrder(): boolean {
        let ok = 0;
        if (!(typeof this.dataSource === 'undefined')) {
            this.dataSource.data.forEach(product => {

                if (product.quantity === null) {
                } else {
                    ok = 1;
                }
            });
        }
        if (this.districtCtrl.value && !(typeof this.dataSource === 'undefined') && ok && !this.orderForm.invalid) {
            if (typeof this.orderForm.value.iBan !== 'undefined') {
                if (this.orderForm.value.iBan && (this.orderForm.value.iBan.length === 0 || this.orderForm.value.iBan.length === 24)) {
                    return false;
                }
            }
            return false;
        }
        else {
            return true;
        }
    }
    async modifyOrder() {
        // 4 steps for modifying an order
        // first step is to split products with quantity 1,
        // second step is to set the final products( delete, add if is not in the final list)
        // third step is to create needed orderProducts, update them and search them in stock 
        // last step is to update the entities, create OP, update OP, update Order
        let singleProducts = [];
        // split products to quantity 1
        const stockOrderProducts = [];
        this.addedProducts.forEach(product => {
            stockOrderProducts.push({
                product: product.productID,
                characteristics: [product.colorID, product.sizeID],
                details: product.details,
                order: null,
                _id: product._id
            });
            if (product.quantity > 1) {
                if (product.orderProduct) {// is from stock
                    singleProducts = singleProducts.concat(this.separateOrderProductsQty1(product));
                    singleProducts.pop(); // remove one item to add the stock one
                    singleProducts.push(product);
                } else {
                    singleProducts = singleProducts.concat(this.separateOrderProductsQty1(product));
                }
            }
            else if (product.quantity == 1) {
                singleProducts.push(product);
            }
        });
        console.log(stockOrderProducts);

        const opUpdate = [];
        const opCreate = [];
        const hardDeleteOPs = [];
        let orderUpdate = {
            'id': this.orderToChange._id,
            'orderProducts': []
        };
        this.orderToChange.orderProductObjects.forEach(orderProd => { // find orderProducts to delete
            const returnToStock = singleProducts.find(elem => elem.orderProduct == orderProd._id); // found in list, remains there
            if (typeof returnToStock == 'undefined') { // orderProd not in the final list, return to stock
                if (orderProd.inProductionAt != null || orderProd.producedAt != null) {
                    opUpdate.push({
                        'id': orderProd._id,
                        'order': null
                    });
                } else {
                    hardDeleteOPs.push(orderProd._id);
                }
            }
        });
        let modifiedOrderPrice = 0;
        await this.api.getWithParams('OrderProduct', stockOrderProducts.filter(op => { if ('undefined' != typeof op._id) return { '_id': op._id } })).then((result: any) => {
            singleProducts.forEach(elem => {
                modifiedOrderPrice += parseInt(elem.price);
                let stockArray = result;
                if (typeof elem.orderProduct == 'undefined') { // orderProducts to create
                    const foundInStock = stockArray.find(stockProduct => stockProduct.product == elem.productID &&
                        stockProduct.characteristics[0] == elem.colorID &&
                        stockProduct.characteristics[1] == elem.sizeID);
                    if (typeof foundInStock !== 'undefined') {// found in stock, update the current, no new creation
                        orderUpdate.orderProducts.push(foundInStock._id);
                        opUpdate.push({
                            'id': foundInStock._id,
                            'order': this.orderToChange._id
                        });
                        stockArray.splice(stockArray.indexOf(foundInStock), 1);
                    } else {
                        opCreate.push({
                            'product': elem.productID,
                            'details': elem.details,
                            'characteristics': [elem.colorID, elem.sizeID],
                            'price': elem.price,
                            'order': this.orderToChange._id,
                        });
                    }
                } else { // orders to add
                    orderUpdate.orderProducts.push(elem.orderProduct);
                }
            });
        });
        if (opUpdate.length > 0) {
            await this.api.postArray('OrderProduct', opUpdate).then(OrderProductUpdate => {
            });
        }
        if (hardDeleteOPs.length > 0) {
            await this.api.hardDelete('OrderProduct', hardDeleteOPs).then(response => { console.log(response); });
        }
        if (0 != opCreate.length) {
            await this.api.post('OrderProduct', opCreate).then((createdOrderProducts: any) => {
                console.log('order here', orderUpdate);
                if (!Array.isArray(createdOrderProducts)) {
                    orderUpdate.orderProducts.push(createdOrderProducts._id);
                }
                else {
                    createdOrderProducts.forEach(elem => {
                        orderUpdate.orderProducts.push(elem['_id']);
                    });
                }
            });
        }
        if (modifiedOrderPrice > MIN_VALUE_FOR_TRANSPORT_PAYD_BY_COMPANY) {
            orderUpdate['paymentMethod'] = false;
        }
        else {
            orderUpdate['paymentMethod'] = true;
        }
        this.api.post('Order', orderUpdate).then(orderUpdated => {
            this.resetOrderForm();
            this.dataSource = new MatTableDataSource([]);
            this.dataSource._updateChangeSubscription();
            this.addedProducts = [];
            this.filterControl.reset();
            this.resetOrder();
            this.snackBar.open('Comanda modificata cu succes!', 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        });

    }
    async submitOrder() {
        try {
            await this.createClientIfNotExist();
        }
        catch (err) {
            this.snackBar.open('Clientul nu a putut fi adaugat. Va rugam reincarcati pagina si incercati din nou', 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            return;
        }
        try {
            await this.createNewOrder();
        }
        catch (err) {
            this.snackBar.open('Comanda nu a putut fi adaugata. Va rugam reincarcati pagina si incercati din nou. Clientul a fost adaugat!', 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            return;
        }
        await this.findStockOrderProducts();
        let arrayOfOp = [];
        this.addedProducts.forEach(element => {
            if (element.quantity == 1) {
                arrayOfOp.push(element);
            }
            else if (element.quantity > 1) {
                arrayOfOp = arrayOfOp.concat(this.separateOrderProductsQty1(element));
            }
        });
        let orderProductsToCreate = [];
        let orderProductsToUpdate = [];
        let totalOrderPrice = 0;
        arrayOfOp.forEach(orderProduct => {
            totalOrderPrice += parseInt(orderProduct.price);
            const foundInStock = this.orderProductsFromStock.filter(stockOP => stockOP.product.toString() === orderProduct.productID.toString() &&
                stockOP.characteristics[0].toString() === orderProduct.colorID.toString() &&
                stockOP.characteristics[1].toString() === orderProduct.sizeID.toString() &&
                stockOP.details === orderProduct.details);
            if (foundInStock.length > 0) {
                orderProductsToUpdate.push({
                    'id': foundInStock[0]._id,
                    'order': this.newOrderID
                });
                this.orderProductsFromStock.splice(this.orderProductsFromStock.findIndex(op =>
                    op._id.toString() === foundInStock[0]._id.toString()), 1);
            } else {
                orderProductsToCreate.push({
                    'product': orderProduct.productID,
                    'details': orderProduct.details,
                    'characteristics': [orderProduct.colorID, orderProduct.sizeID],
                    'price': orderProduct.price,
                    'order': this.newOrderID,
                });
            }
        });
        // if (this.changeProductsFlag) {
        //     orderProductsToUpdate.forEach(elem => {
        //         elem.price = 0;
        //     });
        //     orderProductsToCreate.forEach(elem => {
        //         elem.price = 0;
        //     });
        // }
        const orderProductsToReturn = [];
        if (typeof localStorage.changeProducts !== 'undefined') {



            const allOrderProducts = [];
            const orderProductsToUpdateChange = [];
            this.changeItems.orderProducts.forEach(orderProduct => {
                allOrderProducts.push(orderProduct._id);
                if (orderProduct.checked === true) { // items to return 
                    orderProductsToReturn.push(orderProduct._id);
                    orderProductsToUpdateChange.push({// add items to return to stock
                        'id': orderProduct._id,
                        'order': null
                        //  'price': orderProduct.productObject.price
                    });
                }
            });
            console.log('===', orderProductsToReturn);
            const remainedOrderProducts = allOrderProducts.filter(x => !orderProductsToReturn.includes(x));
            const orderUpdatePromise = new Promise((resolve, reject) => {
                const orderUpdateParams = {
                    'id': this.changeItems.orderProducts[0].order,
                    'orderProducts': remainedOrderProducts,
                    'paymentMethod': totalOrderPrice > MIN_VALUE_FOR_TRANSPORT_PAYD_BY_COMPANY
                };
                console.log(orderUpdateParams);
                this.api.post('Order', orderUpdateParams).then(orderUpdated => {
                    resolve(orderUpdated);
                });
            });
            const orderProductPromise = new Promise((resolve, reject) => {
                this.api.postArray('OrderProduct', orderProductsToUpdateChange).then(OrderProductUpdate => {
                    resolve();
                });
            });
            Promise.all([orderUpdatePromise, orderProductPromise]).then(response => {
                localStorage.removeItem('changeProducts');
            });
        }
        this.api.postArray('OrderProduct', orderProductsToUpdate).then(OrderProductUpdate => { });
        this.api.post('OrderProduct', orderProductsToCreate).then((createdOrderProducts: any) => {
            const usedOrderProducts = orderProductsToUpdate.map(elem => elem.id);
            if (!Array.isArray(createdOrderProducts)) {
                usedOrderProducts.push(createdOrderProducts._id);
            }
            else {
                createdOrderProducts.forEach(elem => {
                    usedOrderProducts.push(elem['_id']);
                });
            }
            let paymentMethod = true;
            if (totalOrderPrice > MIN_VALUE_FOR_TRANSPORT_PAYD_BY_COMPANY || 'undefined' != typeof this.changeItems && this.changeItems.transportFeeFlag == true) {
                paymentMethod = false;
            }
            const newOrderParams = {
                'id': this.newOrderID,
                'orderProducts': usedOrderProducts,
                'returnedOrderProducts': orderProductsToReturn,
                'paymentMethod': paymentMethod
            };
            console.log(newOrderParams);
            this.api.post('Order', newOrderParams).then(orderUpdated => {
                this.resetOrderForm();
                this.dataSource = new MatTableDataSource([]);
                this.dataSource._updateChangeSubscription();
                this.addedProducts = [];
                this.filterControl.reset();
                this.resetOrder();
                this.changeProductsFlag = false;
                this.snackBar.open('Comanda adaugata cu succes!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            });

        });


    }
    separateOrderProductsQty1(product): any {
        const orderProductsToCreateWithQuantity = [];
        let counter = product.quantity;
        while (counter >= 1) {
            orderProductsToCreateWithQuantity.push({
                'productID': product.productID,
                'details': product.details,
                'colorID': product.colorID,
                'sizeID': product.sizeID,
                'price': product.price,
                'order': this.newOrderID,
            });
            counter--;
        }
        return orderProductsToCreateWithQuantity;
    }
    setClientForm(client: any): void {
        this.orderForm.patchValue({
            'clientId': client[0]._id,
            'firstName': client[0].firstName,
            'surName': client[0].surName,
            'address': client[0].address,
            'iBan': client[0].iBan,
            'phoneNumber': client[0].phoneNumber,
            'district': client[0].district,
            'locality': client[0].locality,
            'email': client[0].email,
            'postalCode': client[0].postalCode,
        });
        this.districtCtrl.setValue(client[0].district);
        this.localityCtrl.setValue(client[0].locality);
        this.postalCodeCtrl.setValue({ postalCode: this.orderForm.value.postalCode });

        this.orderForm.controls['firstName'].disable();
        this.orderForm.controls['surName'].disable();
        this.orderForm.controls['address'].disable();
        this.orderForm.controls['iBan'].disable();
        this.orderForm.controls['phoneNumber'].disable();
        this.orderForm.controls['locality'].disable();
        this.orderForm.controls['email'].disable();
        this.postalCodeCtrl.disable();
        this.districtCtrl.disable();
        this.localityCtrl.disable();
    }
    resetOrderForm(): void {
        // console.log(this.beginProductionDate.value.toISOString());
        this.orderForm.reset();
        this.orderForm.value.iBan = '';
        this.districtCtrl.reset();
        this.localityCtrl.reset();
        this.postalCodeCtrl.reset();
        this.localities = [];
        this.filteredLocalities.next(this.localities.slice());
        this.postalCodes = [];
        this.filteredPostalCodes.next(this.postalCodes.slice());
        this.orderForm.enable();
        this.districtCtrl.enable();
        this.localityCtrl.enable();
        this.filterControl.enable();
        this.postalCodeCtrl.enable();
        this.sizeForm.reset();
        this.colorForm.reset();
        this.selectedProductSizes = [];
        this.selectedProductColors = [];
        this.productForm.reset();
        this.filterControlProducts.reset();
        this.filteredProducts = [];
    }
    ngOnInit(): void {
        console.log(this.beginProductionDate.value);
        if (typeof localStorage.changeProducts !== 'undefined') {
            this.filterControl.disable();
            this.changeItems = JSON.parse(localStorage.getItem('changeProducts'));
            this.api.getOne('client', { 'id': this.changeItems.clientID }).then(client => {
                this.clients.push(client);
                const changeClientProducts = [client];//this.clients.filter(client => client._id === this.changeItems.clientID);
                this.setClientForm(changeClientProducts);
                this.changeProductsFlag = true;
                // changeProductsDataSource
                console.log(this.changeItems);
                const itemsToDataSourceChange = [];
                this.changeItems.orderProducts.forEach(orderProduct => {
                    if (orderProduct.checked === true) {
                        itemsToDataSourceChange.push(orderProduct);
                    }
                });
                this.changeProductsDataSource = new MatTableDataSource(itemsToDataSourceChange);
            });

        }
        //////////////
        // edit order//
        ///////////////
        if (typeof localStorage.editOrder !== 'undefined') {
            this.modifyOrderFlag = true;
            this.orderToChange = JSON.parse(localStorage.getItem('editOrder'));
            this.api.getOne('client', { 'id': this.orderToChange.client }).then(client => {

                const user = [];
                // orderToChange.clientObject.clientId = orderToChange.clientObject._id;
                user.push(client);
                this.setClientForm(user);
                this.orderToChange.orderProductObjects.forEach((orderProduct: any) => {
                    this.addedProducts.push({
                        'name': orderProduct.productObject.name,
                        'color': orderProduct.color.value,
                        'size': orderProduct.size.value,
                        'colorID': orderProduct.color._id,
                        'sizeID': orderProduct.size._id,
                        'productID': orderProduct.productObject._id,
                        'quantity': 1,
                        'details': orderProduct.details,
                        'price': orderProduct.productObject.price,
                        'orderProduct': orderProduct._id
                    });
                });

                this.dataSource = new MatTableDataSource(this.addedProducts);
                this.dataSource.data = this.addedProducts;
                this.filterControl.disable();
            });

        };
        this.api.getAll('CharacteristicType').then(param => {
            this.characteristicTypesList = param;
            this.api.getAll('Characteristic').then(param => {
                for (const characteristic of <any>param) {
                    this.characteristicsList[characteristic._id] = {
                        'value': characteristic.value,
                        'type': this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name
                    };
                    if (this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name === 'color') {
                        this.colorOptions[characteristic._id] = {
                            'value': characteristic.value
                        };
                    }
                    else if (this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name === 'size') {
                        this.sizeOptions[characteristic._id] = {
                            'value': characteristic.value
                        };
                    }
                }
                this.api.getAllDisplayingProducts('Product').then(param => {
                    this.products = param;
                });
            });
        });


        this.filterControl.valueChanges
            .debounceTime(500)
            .subscribe(query => {
                if (query !== null && query.length >= 0) {
                    this.filteredClients = this.clients.filter(client => client.phoneNumber.search(query) !== -1);
                    console.log(query, this.filteredClients[0])

                    if (!(typeof this.filteredClients[0] === 'undefined') && this.filteredClients[0].phoneNumber === this.filterControl.value) {
                        // this.districtCtrl.setValue(this.LocalitiesJson.getLocalities().bmc_states.filter(district => district.name === this.filteredClients[0].district)[0]);

                        // this.localities = this.LocalitiesJson.getLocalities().bmc_zones.filter(locality => locality.StateId === this.districtCtrl.value.StateId);

                        // this.localityCtrl.setValue(this.localities.filter(locality => locality.name === this.filteredClients[0].locality)[0]);
                        this.setClientForm(this.filteredClients);
                        console.log('da')

                        this.deletePredefinedClient = true;
                    }
                    else if (this.deletePredefinedClient) {
                        console.log('nu');
                        this.resetOrderForm();
                        this.deletePredefinedClient = false;
                    }


                }

            });
        this.filterControlProducts.valueChanges
            .debounceTime(500) // <-- la fiecare 500 de secunde sa nu rupi banda
            .subscribe(query => {
                if (query !== null && query.length >= 0) {
                    this.selectedProductSizes = [];
                    this.selectedProductColors = [];
                    this.filteredProducts = this.products.filter((product) => {
                        return product.name.toLowerCase().search(query.toLowerCase()) !== -1;
                    });
                    const currentProduct = this.products.find(product => product.name == this.filterControlProducts.value);
                    // if (!(typeof this.filteredProducts[0] === 'undefined') && this.filteredProducts[0].name === this.filterControlProducts.value) {
                    if ('undefined' != typeof currentProduct) {
                        this.filteredProducts[0] = currentProduct;
                        for (const characteristic of this.filteredProducts[0].characteristics) {
                            if (this.sizeOptions[characteristic]) {
                                this.selectedProductSizes.push({
                                    'value': this.sizeOptions[characteristic].value,
                                    'id': characteristic
                                });
                            }
                            if (this.colorOptions[characteristic]) {
                                this.selectedProductColors.push({
                                    'value': this.colorOptions[characteristic].value,
                                    'id': characteristic
                                });
                            }
                        }
                        this.predefinedProduct = true;
                    }
                    else if (this.predefinedProduct) {
                        this.selectedProductSizes = [];
                        this.selectedProductColors = [];
                        this.predefinedProduct = false;
                    }
                }
                else {
                    this.filteredClients = [];
                }
            });
        this.sizeForm.valueChanges.debounceTime(500).subscribe(query => {
            if (this.selectedProductSizes.length > 0) {
                let sizeID = this.selectedProductSizes.filter(size => size.value === query)[0].id;
                let filteredBySizeProducts = [];
                this.api.getWithParams('OrderProduct', [{ 'product': this.filteredProducts[0]._id, 'order': null, 'details': { $ne: null } }]).then(param => {
                    for (const product of <any>param) {
                        if (this.productsWithDetailsSelected.includes(product._id)) {
                            continue;
                        }
                        product.colorID = product.characteristics[0];
                        product.sizeID = product.characteristics[1];
                        product.characteristics[0] = this.characteristicsList[product.characteristics[0]].value;
                        product.characteristics[1] = this.characteristicsList[product.characteristics[1]].value;
                        if (product.sizeID === sizeID) {
                            filteredBySizeProducts.push(product);
                        }
                    }
                    if (filteredBySizeProducts.length > 0) {
                        const dialogRef = this.dialog.open(StocksProductsDialogComponent, {
                            width: '60%',
                            data: {
                                name: this.filteredProducts[0].name,
                                size: 'asdasd',
                                title: 'Produse similare din stoc:',
                                stockProducts: filteredBySizeProducts
                            }
                        });
                        dialogRef.afterClosed().subscribe(result => {
                            if (!(typeof result === 'undefined')) {
                                this.productsWithDetailsSelected.push(result._id);
                                this.similarStockProducts.push(result);
                                this.selectedColor = result.characteristics[0];
                                this.selectedSize = result.characteristics[1];
                                this.addedProducts.push({
                                    'name': this.filteredProducts[0].name,
                                    'productID': this.filteredProducts[0]._id,
                                    'color': result.characteristics[0],
                                    'size': result.characteristics[1],
                                    'colorID': result.colorID,
                                    'sizeID': result.sizeID,
                                    'quantity': 1,
                                    'price': result.price,
                                    'details': result.details,
                                    '_id': result._id
                                });
                                this.productForm.controls['details'].setValue(result.details);
                                this.sizeForm.reset();
                                this.colorForm.reset();
                                this.selectedProductSizes = [];
                                this.selectedProductColors = [];
                                this.productForm.reset();
                                this.filterControlProducts.reset();
                                this.filteredProducts = [];
                                sizeID = '';
                                filteredBySizeProducts = [];
                                this.dataSource = new MatTableDataSource(this.addedProducts);
                                this.dataSource.data = this.addedProducts;
                            }
                        });

                    }
                    else {
                    }

                });
            }
        });
        // set initial selection
        // this.districtCtrl.setValue(this.districts[0]);
        // load the initial district list
        this.filtereddistricts.next(this.districts.slice());
        // listen for search field value changes
        this.districtFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterdistricts();
            });
        // this.filteredLocalities.next(this.localities.slice());
        this.localityFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterLocalities();

            });
        this.districtCtrl.valueChanges.debounceTime(500).subscribe(currentDistrict => {
            // if (typeof currentDistrict.StateId === 'undefined') {
            //     const currentDistrict = this.districts.filter(district => district.name === this.districtCtrl.value.name)[0];
            //     // this.localities = this.LocalitiesJson.getLocalities().bmc_zones.filter(locality => locality.StateId === currentDistrict.StateId);
            //     this.filteredLocalities.next(this.localities.slice());
            // }
            // else {
            //     // this.localities = this.LocalitiesJson.getLocalities().bmc_zones.filter(locality => locality.StateId === currentDistrict.StateId);
            //     this.filteredLocalities.next(this.localities.slice());
            // }
            //  this.filteredLocalities.filter( locality => locality.StateId === currentDistrict.StateId);
            if (this.districts.includes(this.districtCtrl.value)) {
                this.districtFilterCtrl.setValue(currentDistrict);
                this.filtereddistricts.next(this.districts.slice());
                this.localities = this.LocalitiesJson.getLocalities()[currentDistrict];
                this.filteredLocalities.next(this.localities.slice());
                this.postalCodes = [];
                this.filteredPostalCodes.next(this.postalCodes);
            }
        });
        this.localityCtrl.valueChanges.debounceTime(500).subscribe(currentLocality => {
            if (this.localities.includes(currentLocality)) {
                this.api.get('/getPostalCodes?county=' + this.districtCtrl.value + '&city=' + this.localityCtrl.value).subscribe((result: any) => {
                    this.postalCodes = result.address;
                    this.filteredPostalCodes.next(this.postalCodes.slice());
                });
            }
        });
        this.postalCodeFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterPostaCodes();
        });

    }
    private filterPostaCodes(): void {
        if (!this.postalCodes) {
            return;
        }
        // get the search keyword
        let search = this.postalCodeFilterCtrl.value;
        if (!search) {
            this.filteredPostalCodes.next(this.postalCodes.slice());

            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the localities
        this.filteredPostalCodes.next(
            this.postalCodes.filter(postalCode => postalCode.address.toLowerCase().indexOf(search) > -1),
        );
    }

    public compareWith(a, b) {
        return a === b;
    }
    public compareWithPostalCode(a: any, b: any): boolean { return a && b ? a.postalCode === b.postalCode : a === b; }

    createClientIfNotExist(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.orderForm.controls['clientId'].value === null) {
                this.orderForm.value.district = this.districtCtrl.value;
                this.orderForm.value.locality = this.localityCtrl.value;
                this.orderForm.value.postalCode = this.postalCodeCtrl.value.postalCode;
                this.api.post('Client', this.orderForm.value).then(params => {
                    this.clients.push(params);
                    this.orderForm.controls['clientId'].setValue(params['_id']);
                    resolve();
                }).catch(err => {
                    console.log(err);
                    reject(err);
                });

            } else {
                resolve();
            }
        });
    }

    createNewOrder(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.post('Order', { 'client': this.orderForm.controls['clientId'].value }).then(param => {
                this.newOrderID = param['_id'];
                resolve();
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        });
    }

    findStockOrderProducts(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.getWithParams('OrderProduct', [{
                $or: [
                    { 'inProductionAt': { $ne: null } },
                    { 'producedAt': { $ne: null } },
                ],
                'order': null
            }]).then((param: any) => {
                this.orderProductsFromStock = param;
                resolve();
            });
        });
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    onFileSelected(evt): void {
        this.isLoaded = false;
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) {
            this.snackBar.open('Maxim un fisier permis!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            evt.target.value = null;
            return;
        }
        console.log(evt.target.files[0].name.split('.').pop());
        if ('json' != evt.target.files[0].name.split('.').pop()) {
            this.snackBar.open('Tipul fisierului este gresit!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            evt.target.value = null;
            this.isLoaded = true;
            return;
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            let worpressJsonExport;
            try {
                worpressJsonExport = JSON.parse(bstr);
            } catch (e) {
                this.snackBar.open('Fisierul JSON este corupt!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                evt.target.value = null;
                this.isLoaded = true;
                return false;
            }
            this.api.post('wordpressInvoice', worpressJsonExport).then((param: any) => {
                if (param['length'] > 0) {
                    let strDownload = "";
                    param.forEach(elem => {
                        strDownload = strDownload + elem.text + "\r\n";
                    })
                    this.download('Errors import', JSON.stringify(strDownload));
                    this.isLoaded = true;
                }
            });
            // const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            // /* grab first sheet */
            // const wsname: string = wb.SheetNames[0];
            // const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            // let startKey = 8;
            // const xlsObj = XLSX.utils.sheet_to_json(ws, { header: 1 })
            // this.xlsxToJson(xlsObj);
        };
        evt.target.value = null;
        return;
    }

    private download(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename + '.txt');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    private xlsxToJson(sheet) {
        const productDetailsKey = sheet[0].indexOf('Product Variation');
        const productId = sheet[0].indexOf('Variation Id');
        if (-1 != productDetailsKey && -1 != productId) {
            const resArr = [];
            let it = 1;
            while (true) {
                if ('undefined' == typeof sheet[it]) {
                    break;
                }
                else {
                    const productCharacteristics = this.getSizeAndColorFromProductVariation(sheet[it][productDetailsKey])
                    if (null != productCharacteristics.color && null != productCharacteristics.size) {
                        resArr.push({ productId: sheet[it][productId], color: productCharacteristics.color, size: productCharacteristics.size })
                    }
                }
                it++;
            }
            console.log(resArr);
            this.api.post('wordpressInvoice', resArr).then(param => {
                console.log(param);
            });
        }
    }

    private getSizeAndColorFromProductVariation(productVariation) {
        let resObj = { color: null, size: null };
        const characteristicsArr = productVariation.split('|');
        characteristicsArr.forEach(characteristic => {
            const utilArr = characteristic.split(':');
            if (utilArr[0].includes('Culoare')) {
                resObj.color = utilArr[1].trim();
            }
            if (utilArr[0].includes('Marime')) {
                resObj.size = utilArr[1].trim();
            }
        })
        return resObj;
    }

    private filterLocalities(): void {
        if (!this.localities) {
            return;
        }
        // get the search keyword
        let search = this.localityFilterCtrl.value;
        if (!search) {
            this.filteredLocalities.next(this.localities.slice());

            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the localities
        this.filteredLocalities.next(
            this.localities.filter(locality => locality.toLowerCase().indexOf(search) > -1),
        );

    }

    // ngAfterViewInit(): void {
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    // }

    private filterdistricts() {
        if (!this.districts) {
            return;
        }
        // get the search keyword
        let search = this.districtFilterCtrl.value;
        if (!search) {
            this.filtereddistricts.next(this.districts.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the districts
        this.filtereddistricts.next(
            this.districts.filter(district => district.toLowerCase().indexOf(search) > -1),
        );

    }

    private submitOrder2() {
        this.isLoaded = false;
        let orderObject = {
            orderProducts: []
        }

        if (typeof localStorage.editOrder !== 'undefined') {
            orderObject['orderId'] = JSON.parse(localStorage.getItem('editOrder'))._id;
            localStorage.removeItem('editOrder');
        }

        if (typeof localStorage.changeProducts !== 'undefined') {
            orderObject['returnedOrderProducts'] = JSON.parse(localStorage.getItem('changeProducts')).orderProducts.map(elem => elem._id);
            orderObject['oldOrder'] = JSON.parse(localStorage.getItem('changeProducts')).orderProducts[0].order;
            localStorage.removeItem('changeProducts');
        }

        if (this.orderForm.controls['clientId'].value === null) {
            this.orderForm.value.district = this.districtCtrl.value;
            this.orderForm.value.locality = this.localityCtrl.value;
            this.orderForm.value.postalCode = this.postalCodeCtrl.value.postalCode;
            orderObject['client'] = this.orderForm.value
        }
        else {
            orderObject['clientId'] = this.orderForm.controls['clientId'].value
        }

        let arrayOfOp = [];
        let negativeQuantityFlag = false;
        this.addedProducts.forEach(element => {
            if (element.quantity <= 0) {
                negativeQuantityFlag = true;
            }
            else if (element.quantity == 1) {
                arrayOfOp.push(element);
            }
            else if (element.quantity > 1) {
                arrayOfOp = arrayOfOp.concat(this.separateOrderProductsQty1(element));
            }
        });
        if (negativeQuantityFlag) {
            this.snackBar.open('Comanda nu a putut fi adaugata. Va rugam reincarcati pagina si incercati din nou. Cantitatea nu poate fi negativa!', 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            return;
        }
        arrayOfOp.forEach(op => {
            const opObject = {
                product: op.productID,
                details: op.details ? op.details.trim() : null,
                characteristics: [op.colorID, op.sizeID],
                price: op.price,
                // beginProductionAt: this.beginProductionDate.value != null ? this.beginProductionDate.value.toISOString() : 'nimicu',
            };
            if (this.beginProductionDate.value != null) {
                const selectedDeliveryDate = new Date(this.beginProductionDate.value);

                if (selectedDeliveryDate.getDay() == 1 || selectedDeliveryDate.getDay() == 2) {
                    opObject['beginProductionAt'] = (moment(selectedDeliveryDate)).subtract(DAYS_FOR_PRODUCTION_AND_DELIVERY_FOR_WEEKEND, 'd').add(2, 'h').toISOString();
                }
                else {
                    opObject['beginProductionAt'] = (moment(selectedDeliveryDate)).subtract(DAYS_FOR_PRODUCTION_AND_DELIVERY, 'd').add(2, 'h').toISOString();
                }
            }
            // if a selectat vreo data
            // opObject.beginProductionAt = data din ce alege userul,altfel, nimic
            orderObject['orderProducts'].push(opObject)
        });
        if (this.beginProductionDate.value != null) {
            orderObject['beginProductionAt'] = (moment(new Date(this.beginProductionDate.value))).add(2, 'h').toISOString();
        }
        // console.log(String(orderObject));
        this.api.post('orderTransaction', orderObject).then(res => {
            this.resetOrderForm();
            this.dataSource = new MatTableDataSource([]);
            this.dataSource._updateChangeSubscription();
            this.addedProducts = [];
            this.filterControl.reset();
            this.resetOrder();
            this.changeProductsFlag = false;
            this.beginProductionDate.setValue(null);
            this.isLoaded = true;
            console.log(res);
        }).catch(err => {
            this.isLoaded = true;
            console.log(err);
        })
    }
}

export interface UserData {
    product: string;
    quantity: string;
    dimension: string;
    // defaultOption: string;
}

@Component({
    selector: 'stocksProductsDialog',
    templateUrl: './stocksProductsDialog.html',
})
export class StocksProductsDialogComponent {
    stockDisplayedColumns = ['product', 'color', 'size', 'details', 'buttons'];
    stocksDataSource: MatTableDataSource<UserData>;

    constructor(
        public dialogRef: MatDialogRef<StocksProductsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.stocksDataSource = new MatTableDataSource(data.stockProducts);

    }

    select(Object: any): void {
        this.dialogRef.close(Object);
    }

}

export interface DialogData {
    name: string;
    color: string;
    size: string;
    stockProducts: any;
}
