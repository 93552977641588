import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { NavigationService } from './main/services/navigationService/navigation.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';

import { AuthService } from './main/modules/authGuard/auth.service'


@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    navigation: any;
    fuseConfig: any;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _translateService: TranslateService,
        private navigationService: NavigationService,
        public auth: AuthService
    ) {
        // Get default navigation
        // this.navigation = navigation;
        this.navigation = navigationService.adminNavigation();

        // Register the navigation to the service
        this._fuseNavigationService.register('admin', navigationService.adminNavigation());
        this._fuseNavigationService.register('operator', navigationService.operatorNavigation());
        this._fuseNavigationService.register('production', navigationService.productionNavigation());
        this._fuseNavigationService.register('sender', navigationService.senderNavigation());
        this._fuseNavigationService.register('operatorCallCenter', navigationService.operatorCallCenterNavigation());
        this._fuseNavigationService.register('error', navigationService.errorNavigation());

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('error');
        if (auth.isAuthenticated()) {
            this._fuseNavigationService.setCurrentNavigation(auth.getRoles());
        }

        // this._fuseNavigationService.setCurrentNavigation('admin');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');

        // Set the private defaults
        this._unsubscribeAll = new Subject();
        //
        // this._fuseConfigService.config = {
        //     layout          : {
        //         style         : 'vertical-layout-2',
        //         width         : 'fullwidth',
        //         navbar        : {
        //             hidden    : false,
        //             position  : 'left',
        //             folded    : false,
        //             background: 'mat-fuse-dark-700-bg'
        //         },
        //         toolbar       : {
        //             hidden    : false,
        //             position  : 'below-static',
        //             background: 'mat-white-500-bg'
        //         },
        //         footer        : {
        //             hidden    : false,
        //             position  : 'below-static',
        //             background: 'mat-fuse-dark-900-bg'
        //         }
        //     },
        //     customScrollbars: true
        // };

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes

        const customFunctionNavItem = {};
        this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig = config;


            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
