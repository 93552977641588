import { Component, Inject, ViewChild, OnInit, QueryList, ViewChildren, AfterViewInit, ElementRef } from '@angular/core';
import { ApiService } from '../../../services/api/api.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';

import { AuthService } from '../../authGuard/auth.service';
const milisecondsUntilNextRefresh = 1800000;

@Component({
    selector: 'ProductionLibraryComponent',
    templateUrl: './production.library.component.html',
    styleUrls: ['./production.library.component.scss']
})

export class ProductionLibraryComponent implements OnInit {
    displayedColumns = ['product', 'color', 'size', 'details', 'lengthOfMold', 'employee', 'buttons'];
    displayedColumnsProduced = ['product', 'color', 'size', 'details', 'inProductionAt', 'employee', 'buttons'];
    productionPoints: any = [];
    employeesController = new FormControl();
    employees: any = [];
    molds: any = [];
    dataSourceToBeProduced: MatTableDataSource<Molds> = new MatTableDataSource([]);
    dataSourceProduced: MatTableDataSource<Molds> = new MatTableDataSource([]);
    orderProducts: any = [];
    orderProductsObj: any = {};
    characteristicsList: any = [];
    products: any = [];
    productsObj: any = {};
    selectedEmployee = false;
    employeesObjects: any = [];
    productionPointOrdersToFind: any;
    enableDataSource = false;
    isLoaded = false;
    desablePack = false;
    role = 'unk';

    @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
    @ViewChildren(MatSort) sort = new QueryList<MatSort>();

    constructor(
        public api: ApiService,
        public auth: AuthService
    ) {
        this.api.getAll('Employee').then(param => {
            this.employees = param;
            for (const employeeKey in this.employees) {
                if (!this.employees.hasOwnProperty(employeeKey)) { continue; }
                this.employeesObjects[this.employees[employeeKey]._id] = this.employees[employeeKey];
            }
        });
        if (auth.isAuthenticated()) {
            this.role = auth.getRoles().toString();
        }
    }
    apiCalls(): Promise<{}> {
        return new Promise((resolve, reject) => {
            this.api.getWithParams('OrderProduct', [{ 'producedAt': null }]).then(param => {
                this.orderProducts = param;
                this.orderProducts.forEach(element => {
                    this.orderProductsObj[element._id] = element;
                });
                this.api.getWithParams('Mold', [{ 'producedAt': null }]).then((molds: any) => {
                    this.molds = molds;
                    // molds.forEach(currentMold => {
                    //     if ('undefined' != typeof this.orderProducts.find(orderProduct => orderProduct._id === currentMold.orderProducts[0])) {
                    //         this.molds.push(currentMold);
                    //     }
                    // });
                    this.api.getAll('Characteristic').then(characteristics => {
                        this.characteristicsList = characteristics;
                        this.api.getAll('Product').then(products => {
                            this.products = products;
                            this.products.forEach(element => {
                                this.productsObj[element._id] = element;
                            })
                            resolve();
                        });
                    });
                });
            });
        });

    }
    ngOnInit(): void {
        this.apiCalls().then(asd => {
            this.generateMoldEntities();
        });
        setInterval(() => {
            this.isLoaded = false;
            this.apiCalls().then(asd => {
                this.generateMoldEntities();
            });
        }, milisecondsUntilNextRefresh);
        // this.apiCalls().then(asd => {
        //     this.generateMoldEntities();
        // });
    }
    generateMoldEntities() {
        const tableDataToBeProduced = [];
        const tableDataProduced = [];
        const tableOrderProductProduced = [];
        this.molds.forEach(currentMold => {
            this.enableDataSource = true;
            let verifyOrderProduct = this.orderProducts.find(orderProduct => currentMold.orderProducts.includes(orderProduct._id));
            if ('undefined' != typeof verifyOrderProduct) {
                const currentProduct = this.products.filter(product => product._id === verifyOrderProduct.product.toString())[0];
                const employeeToAdd = (typeof currentMold.employee === 'undefined') ? null : currentMold.employee;
                const employeeName = (employeeToAdd === null) ? null : this.employees.filter(employee => employee._id === employeeToAdd.toString())[0];
                const colorsArray = {};

                currentMold.orderProducts.forEach(currentOrderProduct => {
                    if (0 != currentMold.employee && this.orderProductsObj[currentOrderProduct] && null == this.orderProductsObj[currentOrderProduct].producedAt) {
                        tableOrderProductProduced.push({
                            'product': this.productsObj[this.orderProductsObj[currentOrderProduct].product].name,
                            'moldID': currentMold._id,
                            'color': this.characteristicsList.filter(color => color._id === this.orderProductsObj[currentOrderProduct].characteristics[0])[0].value,
                            'lengthOfMold': currentMold.orderProducts.length,
                            'size': this.characteristicsList.filter(size => size._id === this.orderProductsObj[currentOrderProduct].characteristics[1])[0].value,
                            'employees': employeeToAdd,
                            'employeeName': employeeName,
                            'details': this.orderProductsObj[currentOrderProduct].details,
                            'id': currentOrderProduct,
                            'inProductionAt': this.orderProductsObj[currentOrderProduct].inProductionAt
                        });
                    }
                    // if (currentMold.orderProducts.indexOf(currentOrderProduct) === 0) {
                    // }
                    else if (this.orderProductsObj[currentOrderProduct]) {
                        const itColor = this.characteristicsList.find(color => color._id == this.orderProductsObj[currentOrderProduct].characteristics[0]);
                        if (typeof colorsArray[itColor._id] !== 'undefined') {
                            colorsArray[itColor._id].count++;
                        }
                        else {
                            colorsArray[itColor._id] = {
                                'color': itColor.value,
                                'count': 1
                            };
                        }
                        // let firstColor = this.characteristicsList.filter(color => color._id === verifyOrderProduct.characteristics[0])[0];
                        // colorsArray[firstColor._id] = {
                        //     'color': firstColor.value,
                        //     'count': 1
                        // };
                        // verifyOrderProduct = this.orderProducts.find(orderProduct => currentMold.orderProducts.includes(orderProduct._id));
                        // firstColor = this.characteristicsList.filter(color => color._id === verifyOrderProduct.characteristics[0])[0];
                        // if (typeof colorsArray[firstColor._id] !== 'undefined') {
                        //     colorsArray[firstColor._id].count++;
                        // }
                        // else {
                        //     colorsArray[firstColor._id] = {
                        //         'color': firstColor.value,
                        //         'count': 1
                        //     };
                        // }

                    }
                });

                let colors = '';
                for (const colorKey in colorsArray) {
                    if (!colorsArray.hasOwnProperty(colorKey)) { continue; }
                    colors = colors + colorsArray[colorKey].color + ' (' + colorsArray[colorKey].count + '), ';
                }
                colors = colors.substring(0, colors.length - 2); // delete the last comma and space
                const obj = {
                    'product': currentProduct.name,
                    'moldID': currentMold._id,
                    'color': colors,
                    'lengthOfMold': currentMold.orderProducts.length,
                    'size': this.characteristicsList.filter(size => size._id === verifyOrderProduct.characteristics[1])[0].value,
                    'employees': employeeToAdd,
                    'employeeName': employeeName,
                    'details': verifyOrderProduct.details,
                    'orderProducts': currentMold.orderProducts
                };
                if (currentMold.employee.length === 0) {
                    tableDataToBeProduced.push(obj);
                } else {
                    tableDataProduced.push(obj);
                }
            }



        });
        this.dataSourceToBeProduced = new MatTableDataSource(tableDataToBeProduced);
        this.dataSourceProduced = new MatTableDataSource(tableOrderProductProduced);
        this.dataSourceToBeProduced.paginator = this.paginator.toArray()[0];
        // this.dataSourceToBeProduced.sort = this.sort.toArray()[0];
        this.dataSourceProduced.paginator = this.paginator.toArray()[1];
        this.dataSourceProduced.sort = this.sort.toArray()[0];
        this.dataSourceToBeProduced._updateChangeSubscription();
        this.dataSourceProduced._updateChangeSubscription();
        this.isLoaded = true;
    }
    disableSetEmployee(row): boolean {
        if (row.employees.length) { return false; }
        return true;
    }
    generateMolds(): void {
        this.api.generateMold().then(result => {
            this.apiCalls().then(calls => {

                this.generateMoldEntities();
            });
        });
    }

    setEmployee(row): void {
        this.api.postArray('Mold', { 'id': row.moldID, 'employee': row.employees, 'inProductionAt': Date.now() }).then((moldUpdate: any) => {
            const orderProductsSetWorker = [];
            moldUpdate.orderProducts.forEach(op => {
                orderProductsSetWorker.push({
                    'id': op,
                    'worker': this.employees.find(employee => employee._id == row.employees).name
                });
            });
            this.api.postArray('OrderProduct', orderProductsSetWorker).then(orderProductsUpdates => {
            });
            this.dataSourceToBeProduced.data.splice(this.dataSourceToBeProduced.data.indexOf(row), 1);
            this.molds.filter(mold => mold._id === row.moldID)[0].employee = row.employees;
            this.dataSourceToBeProduced._updateChangeSubscription();
            row.orderProducts.forEach(currentOrderProduct => {
                this.dataSourceProduced.data.push({
                    'product': this.productsObj[this.orderProductsObj[currentOrderProduct].product].name,
                    'moldID': row.moldID,
                    'color': this.characteristicsList.filter(color => color._id === this.orderProductsObj[currentOrderProduct].characteristics[0])[0].value,
                    'lengthOfMold': 1,
                    'size': this.characteristicsList.filter(size => size._id === this.orderProductsObj[currentOrderProduct].characteristics[1])[0].value,
                    'employee': row.employeeToAdd,
                    'employees': row.employees,
                    'employeeName': this.employees.find(elem => elem._id == row.employees).name,
                    'details': this.orderProductsObj[currentOrderProduct].details,
                    'id': this.orderProductsObj[currentOrderProduct]._id,
                    'inProductionAt': new Date()                   
                });
            });
            // this.dataSourceProduced.data.push(row);
            this.dataSourceProduced._updateChangeSubscription();
        }).catch(err => {
            this.isLoaded = false;
            this.apiCalls().then(asd => {
                this.generateMoldEntities();
            });
        });
    }
    producedMold(row): void {
        this.desablePack = true;
        this.api.postArray('OrderProduct', [{ 'id': row.id, 'producedAt': Date.now() }]).then(moldUpdate => {
            this.orderProductsObj[row.id].producedAt = Date.now();
            this.dataSourceProduced.data.splice(this.dataSourceProduced.data.indexOf(row), 1);
            // this.molds.splice(this.molds.findIndex(mold => mold._id.toString() === row.moldID.toString()), 1);
            this.dataSourceProduced._updateChangeSubscription();
            this.desablePack = false;
        });
    }
    removeFromProduction(row): void {
        this.desablePack = true;
        const currentMold = this.molds.find(mold => mold._id == row.moldID);
        currentMold.orderProducts.splice(currentMold.orderProducts.indexOf(row.id), 1);
        this.api.post('Mold', { 'id': currentMold._id, 'orderProducts': currentMold.orderProducts }).then(moldUpdate => {
            this.api.postArray('OrderProduct', [{ 'id': row.id, 'inProductionAt': null, 'worker': null, moldId: null }]).then(opUpdate => {
                this.dataSourceProduced.data.splice(this.dataSourceProduced.data.indexOf(row), 1);
                // this.molds.splice(this.molds.findIndex(mold => mold._id.toString() === row.moldID.toString()), 1);
                this.dataSourceProduced._updateChangeSubscription();
                this.desablePack = false;
            });
        });
    }

    hardDelete(row): void {
        this.desablePack = true;
        const currentMold = this.molds.find(mold => mold._id == row.moldID);
        currentMold.orderProducts.splice(currentMold.orderProducts.indexOf(row.id), 1);
        this.api.hardDelete('OrderProduct', [row.id]).then(response =>{
            this.api.post('Mold', { 'id': currentMold._id, 'orderProducts': currentMold.orderProducts }).then(moldUpdate => {
                this.dataSourceProduced.data.splice(this.dataSourceProduced.data.indexOf(row), 1);
                this.dataSourceProduced._updateChangeSubscription();
                this.desablePack = false;
                this.desablePack = false;
            }).catch(err =>{
                console.log(err);
                this.desablePack = false;
            });
        }).catch(err =>{
            console.log(err);
            this.desablePack = false;
        })
    }
    
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSourceProduced.filter = filterValue;
    }

}

export interface Molds {
    product: string;
    size: string;
    color: string;
    employee: string;
    employees: string;
    details: string;
    moldID: string;
    lengthOfMold: Number;
    employeeName: String;
    id: String;
    inProductionAt: Date
}
