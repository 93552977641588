import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { SagaComponent, DownloadConfirmDialogComponent } from './saga.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
const routes = [
    {
        path: 'saga',
        component: SagaComponent
    },
    {
        path: 'stocksDialog',
        component: DownloadConfirmDialogComponent
    },
];
@NgModule({
    declarations: [
        SagaComponent,
        DownloadConfirmDialogComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports: [
        SagaComponent,
        

    ]
})

export class SagaModule {
}
