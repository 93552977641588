import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';
import { LogoutComponent } from './logout.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';

import { AuthGuardService as AuthGuard } from 'app/main/modules/authGuard/auth-guard.services';

const routes = [
    {
        path: 'logout',
        component: LogoutComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        LogoutComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports: [
        LogoutComponent
    ]
})

export class LogoutModule {
}
