import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ReportsComponentComponent,  } from './reports-component.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
const routes = [
    {
        path: 'reportsTable',
        component: ReportsComponentComponent
    },

];
@NgModule({
    declarations: [
        ReportsComponentComponent,
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports: [
        ReportsComponentComponent,
        

    ]
})

export class ReportsModule {
}
