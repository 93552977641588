import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';
import { Router } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';


@Component({
    selector: 'UserDefine',
    templateUrl: './user.define.component.html',
    styleUrls: ['./user.define.component.scss']
})


export class UserDefineComponent {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    userForm: FormGroup;
    editFields: any;
    permissionsOptions: any;
    defaultOption: number;
    userRoles;
    userRoleForm: FormControl = new FormControl();

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        this.activeRoute.params.subscribe(params => {
            this.editFields = params;
        });
        this.userRoles = [
            { 'name': 'Operator', 'key': 'operator' },
            { 'name': 'Operator Call Center', 'key': 'operatorCallCenter' },
            { 'name': 'Productie', 'key': 'production' },
            { 'name': 'Expeditor', 'key': 'sender' },
            { 'name': 'Administrator', 'key': 'admin' },
        ];
        this.userRoleForm.setValue(this.userRoles[0]);
        console.log(this.userRoles);

        if (this.editFields.id) {
            this.api.getOne('User', { 'id': this.editFields.id }).then(param => {
                this.userForm = fb.group({
                    'firstName': param['firstName'],
                    'lastName': param['lastName'],
                    'email': param['email'],
                    'password': null,
                    '_id': this.editFields.id
                });
            }, error => {

            });
            console.log(this.editFields);
        }

    }

    onClick(): void {
        this.api.get(this.api.getBaseUrl()).subscribe((data) => {
        });
    }

    onSubmit(value): void {
        value.role = this.userRoleForm.value.key;
        console.log(value);
        this.api.register(value).then(result => {
            this.router.navigate(['user/library']);
        });

    }

    disableSubmit(): boolean {
        if (this.userForm.value.firstName && this.userForm.value.lastName && this.userForm.value.email && this.userForm.value.password) {
            return false;
        }
        return true;
    }

    ngOnInit(): void {

        this.activeRoute.params.subscribe(params => console.log(params));
        this.userForm = this.fb.group({
            'firstName': null,
            'lastName': null,
            'email': [null, [Validators.email]],
            'password': null
        });
    }
}
