import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { EmployeesDefineComponent } from './define/employees.define.component';
import { EmployeesLibraryComponent } from './library/employees.library.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { AuthGuardService as AuthGuard } from '../../authGuard/auth-guard.services';
const routes = [
    {
        path: 'employee/define',
        component: EmployeesDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'employee/define/:id',
        component: EmployeesDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'employee/library',
        component: EmployeesLibraryComponent, canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        EmployeesDefineComponent,
        EmployeesLibraryComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports: [
        EmployeesDefineComponent,
        EmployeesLibraryComponent
    ]
})

export class EmployeesModule {
}
