import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

declare function require(url: string);

@Component({
    selector: 'ProductionPointsDefineComponent',
    templateUrl: './productionPoints.define.component.html',
    styleUrls: ['./productionPoints.define.component.scss']
})
export class ProductionPointsDefineComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    nameTest = '';
    productionPointForm: FormGroup;
    editFields: any;
    public invoiceDetails: any;
    public invoiceDetailsController: FormControl = new FormControl();


    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        public api: ApiService,
        private router: Router,
        public snackBar: MatSnackBar

    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        this.activeRoute.params.subscribe(params => { this.editFields = params; });

        this.productionPointForm = fb.group({
            'name': null,
            'address': null,
            'invoiceDetail': null,
            'rowLimit': null,
            'columnLimit': null,
        });
        this.api.getAll('invoiceDetail').then(param => {
            if (param['length'] < 1) {
            }
            this.invoiceDetails = param;
            this.invoiceDetailsController.setValue(this.invoiceDetails[0]);
            if (this.editFields.id) {
                this.api.getOne('productionPoint', { 'id': this.editFields.id }).then(param => {
                    this.productionPointForm = fb.group({
                        'id': param['_id'],
                        'name': param['name'],
                        'address': param['address'],
                        'rowLimit': param['rowLimit'],
                        'columnLimit': param['columnLimit'],
                    });
                    this.invoiceDetailsController.setValue(this.invoiceDetails.filter(invoiceDetail => invoiceDetail._id === param['invoiceDetail'])[0]);
                });
            }
        });
    }
    onClick(): void {
    }

    onSubmit(): void {
        if (typeof this.invoiceDetailsController.value === 'undefined') {
            this.productionPointForm.value.invoiceDetail = null;
        }
        else {
            this.productionPointForm.value.invoiceDetail = this.invoiceDetailsController.value._id;
        }

        this.api.post('ProductionPoint', this.productionPointForm.value).then(param => {
            this.router.navigate(['productionPoint/library']);
        });


    }
    disableSubmit(): boolean {

        if (this.productionPointForm.value.name && this.productionPointForm.value.address
            && this.productionPointForm.value.address.length >= 2 && this.invoiceDetailsController.value
            && this.productionPointForm.value.rowLimit && this.productionPointForm.value.columnLimit) {
            return false;
        }
        return true;
    }
    ngOnInit(): void {
        this.activeRoute.params.subscribe(params => console.log(params));

    }
}
