import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { ViewChild } from '@angular/core';
import * as moment from 'moment';
import { PdfMakeService } from 'app/main/services/pdfMakeService/pdfmake.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AuthService } from '../../authGuard/auth.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'sagaComponent',
    templateUrl: './saga.component.html',
    styleUrls: ['./saga.component.scss']
})
export class SagaComponent implements OnInit {
    dateFrom = new FormControl((moment(new Date())).subtract(7, 'd').toISOString());
    dateTo = new FormControl((new Date()).toISOString());
    isLoaded = false;
    transportTotal = 0;
    generalTotal = 0;
    productsById = {};
    characteristicsbyId = {};
    sellingPointsById = {};
    displayedColumns = ['product', 'color', 'size', 'count', 'price', 'total'];
    dataSource: MatTableDataSource<any>;
    opByProductAndColorAndSize = {};
    negativeopByProductAndColorAndSize = {};
    invoiceDetails = [];
    selectedInvoiceDetails = {};
    renderedData;
    tableHeaderTitles = {
        product: 'Produs',
        color: 'Culoare',
        count: 'Cantitate',
        price: 'Pret',
        total: 'Total'
    };
    authEmail = null;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public pdfMakeService: PdfMakeService,
        public auth: AuthService,
    ) {
        this.dataSource = new MatTableDataSource([]);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (auth.isAuthenticated()) {
            this.authEmail = auth.getuser()['email'];
        }
    }
    searchClients(): void {
        this.isLoaded = true;
        this.api.get('/xml/clients').subscribe((elem: any) => {
            this.isLoaded = false;
            this.downloadXml('clienti-' + moment(new Date()).format('DD/MM/YYYY-hh:mm:ss'), elem.xml);
        }, err => {
            this.isLoaded = false;
        });
    }

    searchInvoice(): void {
        this.isLoaded = true;
        const orderFrom = moment(this.dateFrom.value).format('YYYY-MM-DD') + 'T00:00';
        const orderTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59';
        this.api.getByDate('XmlInvoice',
            {
                interval: {
                    dateFrom: orderFrom,
                    dateTo: orderTo
                }, invoiceDetails: this.selectedInvoiceDetails['_id']
            })
            .then((resp: any) => {
                this.isLoaded = false;
                const dialogRef = this.dialog.open(DownloadConfirmDialogComponent, {
                    width: '30%',
                    data: {
                        title: 'Doresti sa descarci facturile?',
                        total: 'Suma totala facturata:' + resp.total,
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'true') {
                        this.downloadXml('F_' + moment(new Date()).format('DD/MM/YYYY-hh:mm:ss'), resp.xml);
                    }
                });
            }).catch(err => {
                this.snackBar.open('Cautarea a esuat, reincercati mai tarziu!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                this.isLoaded = false;
            });
    }

    searchNegativeInvoice(): void {
        this.isLoaded = true;
        this.api.getByDate('XmlNegativeInvoice',
            { 
                invoiceDetails: this.selectedInvoiceDetails['_id']
            })
            .then((resp: any) => {
                this.isLoaded = false;
                const dialogRef = this.dialog.open(DownloadConfirmDialogComponent, {
                    width: '30%',
                    data: {
                        title: 'Doresti sa descarci facturile?',
                        total: 'Suma totala facturata:' + resp.total,
                    }
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'true') {
                        this.downloadXml('F_NEG_' + moment(new Date()).format('DD/MM/YYYY-hh:mm:ss'), resp.xml);
                    }
                });
            }).catch(err => {
                this.snackBar.open('Cautarea a esuat, reincercati mai tarziu!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                this.isLoaded = false;
            });
    }

    downloadXml(filename, text) {
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename + '.xml');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    }

    async getAllDataForProction() {
        this.isLoaded = true;
        this.transportTotal = 0;
        this.generalTotal = 0;

        // get orders with delivery date in interval
        const orderFrom = moment(this.dateFrom.value).format('YYYY-MM-DD') + 'T00:00';
        const orderTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59';
        const orders: any = await this.api.getByDeliveryDate('Order', { 'from': orderFrom, 'to': orderTo, invoiceDetails: this.selectedInvoiceDetails['_id'] });
        let opArray = [];
        let negativeOpArray = [];
        orders.forEach(order => {
            if (order.invoiceInfo.length > 0) {
                opArray = opArray.concat(order.invoiceInfo[0].orderProducts);
                this.transportTotal += order.invoiceInfo[0].transportFee
            } else {
                // console.log('invoiceInfo not found');
            }
            if (order.returnedOrderProducts.length > 0) {
                negativeOpArray = negativeOpArray.concat(order.returnedOrderProducts);
            }
        });


        // get characteristics and map them by id
        const characteristics: any = await this.api.getAll('Characteristic');
        characteristics.forEach(characteristic => {
            this.characteristicsbyId[characteristic._id] = characteristic;
        });

        // get products and map them by id
        const products: any = await this.api.getAll('Product');
        products.forEach(product => {
            this.productsById[product._id] = product;
        })

        const sellingPoints: any = await this.api.getAll('ProductionPoint');
        sellingPoints.forEach(sellingPoint => {
            this.sellingPointsById[sellingPoint._id] = sellingPoint;
        })

        // get orderProducts from order
        const orderProducts: any = await this.api.getWithParams('OrderProduct', [{ _id: { $in: opArray } }]);
        const negativeOrderProducts: any = await this.api.getWithParams('OrderProduct', [{ _id: { $in: negativeOpArray } }]);
        

        // count all order products by same product and same color
        this.opByProductAndColorAndSize = {};
        this.negativeopByProductAndColorAndSize = {};
        orderProducts.forEach(orderProduct => {
            this.generalTotal += Number(orderProduct.price);
            if ('undefined' == typeof this.opByProductAndColorAndSize[orderProduct.product]) {
                this.opByProductAndColorAndSize[orderProduct.product] = {};
            }
            if ('undefined' == typeof this.opByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]]) {
                this.opByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]] = {};
            }
            if ('undefined' == typeof this.opByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]][orderProduct.characteristics[1]]) {
                console.log(orderProduct, this.opByProductAndColorAndSize);
                this.opByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]][orderProduct.characteristics[1]] = 0;
            }
            this.opByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]][orderProduct.characteristics[1]]++;
        });
        negativeOrderProducts.forEach(orderProduct => {
            this.generalTotal -= Number(orderProduct.price);
            if ('undefined' == typeof this.negativeopByProductAndColorAndSize[orderProduct.product]) {
                this.negativeopByProductAndColorAndSize[orderProduct.product] = {};
            }
            if ('undefined' == typeof this.negativeopByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]]) {
                this.negativeopByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]] = {};
            }
            if ('undefined' == typeof this.negativeopByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]][orderProduct.characteristics[1]]) {
                this.negativeopByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]][orderProduct.characteristics[1]] = 0;
            }
            this.negativeopByProductAndColorAndSize[orderProduct.product][orderProduct.characteristics[0]][orderProduct.characteristics[1]]--;
        });
        
        const tableData = [];
        Object.keys(this.negativeopByProductAndColorAndSize).forEach(orderProduct => {
            Object.keys(this.negativeopByProductAndColorAndSize[orderProduct]).forEach(color => {
                Object.keys(this.negativeopByProductAndColorAndSize[orderProduct][color]).forEach(size => {
                    tableData.push({
                        product: this.productsById[orderProduct].name,
                        color: this.characteristicsbyId[color].value,
                        size: this.characteristicsbyId[size].value,
                        count: this.negativeopByProductAndColorAndSize[orderProduct][color][size],
                        productionPoint: this.sellingPointsById[this.productsById[orderProduct].productionPoint].name,
                        price: this.productsById[orderProduct].price,
                        total: this.negativeopByProductAndColorAndSize[orderProduct][color][size] * this.productsById[orderProduct].price
                    });
                });
            });
        });
        Object.keys(this.opByProductAndColorAndSize).forEach(orderProduct => {
            Object.keys(this.opByProductAndColorAndSize[orderProduct]).forEach(color => {
                Object.keys(this.opByProductAndColorAndSize[orderProduct][color]).forEach(size => {
                    tableData.push({
                        product: this.productsById[orderProduct].name,
                        color: this.characteristicsbyId[color].value,
                        size: this.characteristicsbyId[size].value,
                        count: this.opByProductAndColorAndSize[orderProduct][color][size],
                        productionPoint: this.sellingPointsById[this.productsById[orderProduct].productionPoint].name,
                        price: this.productsById[orderProduct].price,
                        total: this.opByProductAndColorAndSize[orderProduct][color][size] * this.productsById[orderProduct].price
                    });
                });
            });
        });
        this.isLoaded = false;
        this.dataSource.data = tableData;
        this.dataSource._updateChangeSubscription();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.sortingDataAccessor = this.sortingData;
        this.dataSource.connect().subscribe(d => this.renderedData = d);
        // this.dataSource._updateChangeSubscription();
    }
    sortingData(row: any, property) {
        switch (property) {
            case 'product':
                return row[property] + ' ' + row['color'];
            default: return row[property];
        }
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    ObjectKeys(obj) {
        return Object.keys(obj);
    }
    generatePDFMake() {
        const headersText = [];
        const objectData = {
            reportTitle: 'Produse facturate',
            // subTitle: 'Valoarea totala a transportului: ' + this.transportTotal,
            subTitle: `Valoarea totala a transportului:${this.transportTotal}, Valoarea totala:${this.generalTotal}`,
            // body: this.renderedData,
            // body: this.dataSource.data,
            body: this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort),
            headers: this.displayedColumns,
            headersText: headersText,
            from: moment(this.dateFrom.value).format('DD-MM-YYYY'),
            to: moment(this.dateTo.value).format('DD-MM-YYYY'),
        };
        this.displayedColumns.forEach(elem => {
            headersText.push(this.tableHeaderTitles[elem]);
        });
        try {
            pdfMake.createPdf(this.pdfMakeService.genericPdfmake(objectData)).open();
        } catch (e) {
            console.log(e);
        }
    }

    ngOnInit(): void {
        this.api.getAll('InvoiceDetail').then((param: any) => {
            this.invoiceDetails = param;
            this.selectedInvoiceDetails = this.invoiceDetails[0];
        });

    }
    logger(L) {
        console.log(L);
    }
}
@Component({
    selector: 'dialog-confirm-download',
    templateUrl: 'dialog-confirm-download.html',
})
export class DownloadConfirmDialogComponent {
    constructor(public dialogRef: MatDialogRef<DownloadConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }
    dialogResponse(confirm: string): void {
        this.dialogRef.close(confirm);
    }
}