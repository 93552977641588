import { Injectable, Component, Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class MergeOPsService {
    mergeOrderProducts(orderProducts: any): any {
        const returnedOrderProducts = [];
        while (orderProducts.length > 0) {
            const currentOP = orderProducts[0];
            const b = orderProducts.filter(orderProduct => {
                return orderProduct.name.toString() === currentOP.name.toString() &&
                    orderProduct.characteristics[0].toString() === currentOP.characteristics[0].toString() &&
                    orderProduct.characteristics[1].toString() === currentOP.characteristics[1].toString() &&
                    Number(orderProduct.price) === Number(currentOP.price) &&
                    orderProduct.details === currentOP.details;
            });
            // if (b.length == 0) {
            //     break;
            // }
            returnedOrderProducts.push(b[0]);
            const opIdArray = [];
            b.forEach(element => {
                opIdArray.push(element._id);
            });
            const index = returnedOrderProducts.indexOf(b[0]);
            returnedOrderProducts[index].quantity = b.length;

            returnedOrderProducts[index].OPsIDs = opIdArray;
            b.forEach(orderProduct => {
                orderProducts.splice(orderProducts.indexOf(orderProduct), 1);
            });
        }
        return returnedOrderProducts;
    }
    mergeNames(orderProducts: any): any {
        let returnedName = {};
        orderProducts.forEach(elem => {
            if ('undefined' !== typeof elem.productObject && typeof returnedName[elem.productObject.name] !== 'undefined') {
                returnedName[elem.productObject.name].count++;
            }
            else if('undefined' !== typeof elem.productObject) {
                returnedName[elem.productObject.name] = {
                    'count': 1
                };
            }
        });
        let oPNames = '';
        for (const name in returnedName) {
            if (!returnedName.hasOwnProperty(name)) { continue; }
            oPNames = oPNames + name + '(' + returnedName[name].count + '), ';
        }
        return oPNames = oPNames.substring(0, oPNames.length - 2);
    }
    reportTableMerge(orderProducts: any): any{
        let returnedName = {};
        orderProducts.forEach(elem => {
            if ('undefined' !== typeof elem.product && typeof returnedName[elem.product.name] !== 'undefined') {
                returnedName[elem.product.name].count++;
            }
            else if('undefined' !== typeof elem.product) {
                // console.log(returnedName[elem.product.name]);
                returnedName[elem.product.name] = { 
                    'count': 1
                };
                // console.log(returnedName[elem.product.name]);
            }
            else{
                console.log(1, elem);
            }
        });
        let oPNames = '';
        // console.log(returnedName);
        for (const name in returnedName) {
            if (!returnedName.hasOwnProperty(name)) { continue; }
            oPNames = oPNames + name + '(' + returnedName[name].count + '), ';
        }
        return oPNames = oPNames.substring(0, oPNames.length - 2);
    }
    mergeNamesByOpPaul(orderProducts){
        console.log(1234);
        let counter = {}
        orderProducts.forEach(orderProduct =>{
            if (!counter[orderProduct.product.name]){
                counter[orderProduct.product.name] = 0;
            }
            counter[orderProduct.product.name]++;
        })
        let strResult = '';
        Object.keys(counter).forEach(element => {
            strResult = `${strResult} ${element}(${counter[element]}),`
        });
        return strResult.slice(0, -1);
    }
}
