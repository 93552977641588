import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';


import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { MatTableDataSource } from '@angular/material';
import { UserData } from '../../invoices-p/library/invoicesP.library.component';

declare function require(url: string);

let jsonDB = require('../../../db.json');
const db = jsonDB.invoice;

@Component({
    selector: 'InvoiceDetailsDefineComponent',
    templateUrl: './invoiceDetails.define.component.html',
    styleUrls: ['./invoiceDetails.define.component.scss']
})
export class InvoiceDetailsDefineComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    invoiceForm: FormGroup;
    editFields: any;
    activeFalse: boolean;
    public invoiceDetails: any;
    public proformDetails: any;
    public invoiceDetailSetController: FormControl = new FormControl();
    public proformDetailSetController: FormControl = new FormControl();

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.activeRoute.params.subscribe(params => {
            this.editFields = params;
        });

        this.invoiceForm = fb.group({
            'name': null,
            'registerNumber': null,
            'cif': null,
            'vat': 0,
            'address': null,
            'bankIban': null,
            'bankName': null,
            'invoiceDetailSet': null,
            'proformDetailSet': null,
            'postalCode': null
        });

        this.api.getAll('proformDetailSet').then(param => {
            this.proformDetails = param;
            this.proformDetailSetController.setValue(this.proformDetails[0]);
            this.api.getAll('invoiceDetailSet').then(param => {
                this.invoiceDetails = param;
                this.invoiceDetailSetController.setValue(this.invoiceDetails[0]);
                if (this.editFields.id) {
                    this.api.getOne('InvoiceDetail', { 'id': this.editFields.id }).then(param => {
                        this.invoiceForm = fb.group({
                            'name': param['name'],
                            'registerNumber': param['registerNumber'],
                            'cif': param['cif'],
                            'vat': param['vat'],
                            'address': param['address'],
                            'bankIban': param['bankIban'],
                            'bankName': param['bankName'],
                            'invoiceDetailSet': param['invoiceDetailSet'],
                            'proformDetailSet': param['proformDetailSet'],
                            'id': param['_id'],
                            'postalCode': param['postalCode']
                        });
                        this.proformDetailSetController.setValue(this.proformDetails.filter(proformDetail => proformDetail._id === param['proformDetailSet'])[0]);
                        this.invoiceDetailSetController.setValue(this.invoiceDetails.filter(invoiceDetail => invoiceDetail._id === param['invoiceDetailSet'])[0]);
                    });
                }
            });
        });
    }

    onSubmit(): void {

        this.invoiceForm.value.invoiceDetailSet = this.invoiceDetailSetController.value._id;
        this.invoiceForm.value.proformDetailSet = this.proformDetailSetController.value._id;
        this.api.post('InvoiceDetail', this.invoiceForm.value).then(param => {
            this.router.navigate(['invoiceDetails/library']);
        });
    }

    disableSubmit(): boolean {
        if (this.invoiceForm.value.name && this.invoiceForm.value.address && this.invoiceForm.value.registerNumber &&
            this.invoiceForm.value.cif && this.invoiceForm.value.bankIban && this.invoiceForm.value.bankName &&
            this.invoiceDetailSetController.value && this.proformDetailSetController.value && this.invoiceForm.value.postalCode) {
            return false;
        }
        else {
            return true;
        }


    }

    ngOnInit(): void {


    }

}
