import { AuthService } from './main/modules/authGuard/auth.service';
import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private inj: Injector) { }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {
        const auth = this.inj.get(AuthService);
        // Modificari asupra requestului inainte ca acesta sa fie facut
        // Aici adaugam headerul de autentificare

        if (auth.getToken()) {
            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${auth.getToken()}`,
                    'x-hainute-key': auth.getToken()
                }
            });
        }

        return next.handle(req);
        //    .catch(( err: any, caught: Observable<HttpEvent<any>> ) => {
        //        return caught;
        //    });
    }
}