import { FuseNavigation } from '@fuse/types';

function customNavigation() {
    var customNavArray = [];
    //customNavArray.push(addCustomize());
    customNavArray.push(addDashboard());
    customNavArray.push(addCRM());
    customNavArray.push(addOrder());
    customNavArray.push(addBI());
    customNavArray.push(addStocks());
    customNavArray.push(addProduction());
    customNavArray.push(addSettings());
    customNavArray.push(addLogout());

    return customNavArray;
}

function addCustomize() {
    var customizeObject = [];
    customizeObject.push({
        'id': 'customize',
        'title': 'Customize',
        'type': 'item',
        'icon': 'settings',
        'function': () => {
            this.toggleSidebarOpen('themeOptionsPanel');
        }
    });
    return customizeObject[0];
}

function addStocks() {
    var stocksModule = {
        id: 'stocks',
        title: 'Stocuri',
        type: 'collapse',
        icon: 'border_all',
        children: [],
    };

    stocksModule.children.push({
        id: 'stocks/define',
        title: 'Adauga stoc',
        type: 'item',
        url: 'stocks/define'
    });
    stocksModule.children.push({
        id: 'stocks/library',
        title: 'Regleaza stoc',
        type: 'item',
        url: 'stocks/library'
    });

    return stocksModule;
}
function addProduction() {
    const productionModule = {
        id: 'production',
        title: 'Productie',
        type: 'collapse',
        icon: 'work_outline',
        children: [],
    };
    productionModule.children.push({
        id: 'production/daily',
        title: 'Productie zilnica',
        type: 'item',
        url: '/production/daily',
    });
    productionModule.children.push({
        id: 'production/history',
        title: 'Istoric productie',
        type: 'item',
        url: '/production/history',
    });
    return productionModule;
}
function addOrder() {
    var orderModule = {
        id: 'order',
        title: 'Comenzi',
        type: 'collapse',
        icon: 'shopping_cart',
        children: []
    };
    orderModule.children.push(addDefine('order'));
    orderModule.children.push(addLibrary('order'));
    orderModule.children.push(addHistory('order'));
    orderModule.children.push(addEmagOrders());
    return orderModule;
}

function addEmagOrders() {
    return {
        id: 'emag_library',
        title: 'Emag',
        type: 'item',
        url: '/emag/library'
    };
}

function addLogout() {
    var logOutModule = {
        id: 'logout',
        title: 'Logout',
        type: 'item',
        icon: 'power_settings_new',
        url: '/logout',
    };

    return logOutModule;
}

function addDashboard() {
    var dashboardArray = [];
    dashboardArray.push({
        id: 'dashboard',
        title: 'Dasboard',
        translate: 'NAV.DASHBOARD.TITLE',
        type: 'item',
        icon: 'dashboard',
        url: '/dashboard',
    });
    return dashboardArray[0];
}




function addBI() {
    var biModule = {
        id: 'bi',
        title: 'Financiare',
        type: 'collapse',
        icon: 'monetization_on',
        children: []
    };

    var parentInvoice = {
        id: 'invoice',
        title: 'Detalii serii facturi',
        type: 'collapsable',
        icon: 'email',
        children: []
    };

    var parentInvoiceP = {
        id: 'invoiceP',
        title: 'Detalii serii proforma',
        type: 'collapsable',
        icon: 'email',
        children: []
    };
    var parentInvoiceDetails = {
        id: 'invoiceDetails',
        title: 'Informatii facturare',
        type: 'collapsable',
        icon: 'info',
        children: []
    };
    var returnedItems = {
        id: 'returnedProducts',
        title: 'Retururi',
        type: 'item',
        icon: 'info',
        url: '/returnedProducts'
    };

    // parentInvoice.children.push(addDefine('invoice'));
    // parentInvoice.children.push(addLibrary('invoice'));
    // biModule.children.push(parentInvoice);

    // parentInvoiceP.children.push(addDefine('invoiceP'));
    // parentInvoiceP.children.push(addLibrary('invoiceP'));
    // biModule.children.push(parentInvoiceP);
    // returnedItems.children.push(addDefine('returnItems'));
    // returnedItems.children.push(addLibrary('returnItems'));
    parentInvoiceDetails.children.push(addDefine('invoiceDetails'));
    parentInvoiceDetails.children.push(addLibrary('invoiceDetails'));
    biModule.children.push(parentInvoiceDetails);
    biModule.children.push(returnedItems);

    return biModule;
}

function addCRM() {
    var crmObject = {
        id: 'crm',
        title: 'CRM',
        translate: 'NAV.CRM.TITLE',
        type: 'collapse',
        icon: 'dehaze',
        children: []
    };
    var parentProductionPoint = {
        id: 'ProductionPoints',
        title: 'Puncte de vanzare',
        type: 'collapsable',
        icon: 'email',
        children: []
    };
    var parentProducts = {
        id: 'Products',
        title: 'Produse',
        type: 'collapsable',
        icon: 'all_inbox',
        children: []

    };
    var parentClients = {
        id: 'Clients',
        title: 'Clienti',
        type: 'collapsable',
        icon: 'account_box',
        children: []

    };
    var parentEmployees = {
        id: 'Employees',
        title: 'Angajati',
        type: 'collapsable',
        icon: 'group',
        children: []
    };

    // parentProductionPoint.children.push(addDefine('productionPoint'));
    // parentProductionPoint.children.push(addLibrary('productionPoint'));
    // crmObject['children'].push(parentProductionPoint);

    // parentEmployees.children.push(addDefine('employee'));
    // parentEmployees.children.push(addLibrary('employee'));
    // crmObject['children'].push(parentEmployees);

    parentProducts.children.push(addDefine('product'));
    parentProducts.children.push(addLibrary('product'));
    crmObject['children'].push(parentProducts);

    parentClients.children.push(addDefine('client'));
    parentClients.children.push(addLibrary('client'));
    crmObject['children'].push(parentClients);
    return crmObject;
}

function addSettings() {
    var settingsObject = {
        id: 'settings',
        title: 'Setari',
        //translate: 'NAV.SETTINGS.TITLE',
        type: 'collapse',
        icon: 'settings',
        children: []
    };
    var parentProductionPoint = {
        id: 'ProductionPoints',
        title: 'Puncte de vanzare',
        type: 'collapsable',
        icon: 'room',
        children: []
    };

    var parentEmployees = {
        id: 'Employees',
        title: 'Angajati',
        type: 'collapsable',
        icon: 'group',
        children: []
    };

    var parentInvoice = {
        id: 'invoice',
        title: 'Detalii serii facturi',
        type: 'collapsable',
        icon: 'format_list_numbered',
        children: []
    };

    var parentInvoiceP = {
        id: 'invoiceP',
        title: 'Detalii serii proforma',
        type: 'collapsable',
        icon: 'format_list_numbered_rtl',
        children: []
    };

    var users = {
        id: 'users',
        title: 'Utilizatori',
        type: 'collapsable',
        icon: 'account_box',
        children: []
    };

    users.children.push(addDefine('user'));
    users.children.push(addLibrary('user'));
    settingsObject['children'].push(users);

    parentProductionPoint.children.push(addDefine('productionPoint'));
    parentProductionPoint.children.push(addLibrary('productionPoint'));
    settingsObject['children'].push(parentProductionPoint);

    parentEmployees.children.push(addDefine('employee'));
    parentEmployees.children.push(addLibrary('employee'));
    settingsObject['children'].push(parentEmployees);

    parentInvoice.children.push(addDefine('invoice'));
    parentInvoice.children.push(addLibrary('invoice'));
    settingsObject.children.push(parentInvoice);

    parentInvoiceP.children.push(addDefine('invoiceP'));
    parentInvoiceP.children.push(addLibrary('invoiceP'));
    settingsObject.children.push(parentInvoiceP);

    return settingsObject;
}

function addDefine(name: string) {
    return {
        id: name + 'define',
        title: 'Defineste',
        type: 'item',
        url: '/' + name + '/define'
    };
}

function addLibrary(name: string) {
    return {
        id: name + 'library',
        title: 'Librarie',
        type: 'item',
        url: '/' + name + '/library'
    };
}
function addHistory(name: string) {
    return {
        id: name + 'history',
        title: 'Finalizate',
        type: 'item',
        url: '/' + name + '/history'
    };
}


export const navigation: FuseNavigation[] = customNavigation();


// const customFunctionNavItem = {
//     'id'      : 'custom-function',
//     'title'   : 'Custom Function',
//     'type'    : 'group',
//     'icon'    : 'settings',
//     'children': [
//         {
//             'id'      : 'customize',
//             'title'   : 'Customize',
//             'type'    : 'item',
//             'icon'    : 'settings',
//             'function': () => {
//                 this.toggleSidebarOpen('themeOptionsPanel');
//             }
//         }
//     ]
// };
//this._fuseNavigationService.addNavigationItem(customFunctionNavItem, 'end');
