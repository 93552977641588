import { Component, Inject, OnInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../../services/api/api.service';
import * as moment from 'moment'
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-ean-modal.component',
  templateUrl: './ean-modal.component.html',
  styleUrls: ['./ean-modal.component.scss']
})



export class EanModalComponent implements OnInit {
  public offerForm: FormGroup;
  displayedColumns = [];
  dataSource = new MatTableDataSource([]);
  displayLoader = false;
  data = null
  usedDates = [];
  constructor(
    public dialogRef: MatDialogRef<EanModalComponent>,
    public fb: FormBuilder,
    public api: ApiService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.data = info;
    this.prepareTable();
  }
  
  private prepareTable() {
    this.displayedColumns = ['name', 'size', 'color', 'ean'];
  }
  public submit() {
    this.displayLoader = true;
    console.log(this.dataSource.data);
    this.api.obsGet(this.api.obsPost(`product_instance?productId=${this.data.id}`, { productInstances: this.dataSource.data}).subscribe((res: any) => {
      console.log(res);
      this.displayLoader = false;
    }))
  }

  public closeDialog() {
    this.dialogRef.close();
  }
  
  public logger(e) {
    console.log(e);
  }
  public valueChange(event, row){
    row.ean = event.srcElement.value;
  }
  private getProductInstances() {
    this.displayLoader = true;
    this.api.obsGet(this.api.obsGet(`product_instance?productId=${this.data.id}`).subscribe((res: any) => {
      this.dataSource.data = res;
      this.dataSource._updateChangeSubscription();
      this.displayLoader = false;
    }))
  }
  ngOnInit(): void {
    this.getProductInstances();
  }
}