import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MatTableModule } from '@angular/material';
import { Router } from '@angular/router';
import { DialogDataComponent } from '../../../GenericDialog/dialogData.component';
import { ApiService } from '../../../../services/api/api.service';

declare function require(url: string);


@Component({
    selector: 'UsersLibrary',
    templateUrl: './users.library.component.html',
    styleUrls: ['./users.library.component.scss']
})
export class UsersLibraryComponent {
    displayedColumns = ['name', 'surname', 'email', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
        public api: ApiService,
        public dialog: MatDialog
    ) {

        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        this.api.getAll('User').then((param: any) => {
            const userData: UserData[] = param.filter(user => user.email != 'dev@dev.com');
            this.dataSource = new MatTableDataSource(userData);
        });
    }



    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }
    editUser(id: number) {
        this.router.navigate(['user/define', id]);
    }
    ngOnInit(): void {
    }
}

export interface UserData {
    name: string;
    surname: string;
    email: string;
}
