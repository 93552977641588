import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, } from '@angular/material';
import { ViewChild } from '@angular/core';
import * as moment from 'moment';
@Component({
    selector: 'returnedItemsComponent',
    templateUrl: './returnedProducts.component.html',
    styleUrls: ['./returnedProducts.component.scss']
})
export class ReturnedProductsComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    dateFrom = new FormControl((moment(new Date())).subtract(3, 'd').toISOString());
    dateTo = new FormControl((moment(new Date())).add(3, 'd').toISOString());
    dataSource: MatTableDataSource<ReturnedItems>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    displayedColumns = ['name', 'date', 'maturityDate', 'invoiceDetails', 'price', 'buttons'];
    filterControl = new FormControl();
    uploadedFile: File;
    ordersToDate = [];
    clientsToDate = [];
    isLoaded = false;
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router
        
    ) {

        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
    }
    ngOnInit(): void {
        this.searchByDate();
        // this.api.getWithParams('Order', { invoiceInfo: { $ne: null } }).then((resp: any) => {
        //     console.log('-', resp);
        // });
    }
    searchByDate(): void {
        const ordersFrom = moment(this.dateFrom.value).subtract(1, 'd').format('YYYY-MM-DD') + 'T22:00:00.000Z';
        const ordersTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59:59.000Z';
        this.isLoaded = false;
        this.api.gerReturnedOrdersByDate('Order', { 'from': ordersFrom, 'to': ordersTo }).then((orders: any) => {
            this.ordersToDate = orders;
            console.table(ordersFrom,ordersTo);
            const clients = [];
            this.ordersToDate.forEach(order => {
                clients.push(order.client);
            });
            console.log(this.ordersToDate.length);
            // nume client, data (created at), serie+ numar concatenate, 
            this.api.findMany('Client', { '_id': { '$in': clients } }).then((resp: any) => {
                this.clientsToDate = resp;
                const itemsToAdd = [];
                this.ordersToDate.forEach(order => {
                    if (order.invoiceInfo) {
                        order.invoiceInfo.forEach(inv => {
                            const toDate = new Date(ordersTo).toISOString();
                            const maturityDate = new Date(inv.invoiceDate);
                            maturityDate.setDate(maturityDate.getDate() + 14);
                            if ((inv.totalPrice < 0 || inv.isStorno) && toDate >= maturityDate.toISOString()) {
                                const orderClient = this.clientsToDate.find(client => client._id == order.client);
                                itemsToAdd.push({
                                    'name': orderClient.surName + ' ' + orderClient.firstName,
                                    'date': inv.invoiceDate,
                                    'invoiceDetails': inv.serialKey + ' ' + inv.serialNumber,
                                    'orderId': order._id,
                                    'serialKey': inv.serialKey,
                                    'serialNumber': inv.serialNumber,
                                    'fileName': inv.files[inv.files.length - 1],
                                    'totalPrice': inv.totalPrice + inv.transportFee,
                                    'iBan': orderClient.iBan ? orderClient.iBan : '-',
                                    'maturityDate': maturityDate.toISOString()
                                });
                            }
                        });
                    }
                });
                this.dataSource = new MatTableDataSource(itemsToAdd);
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
                this.dataSource._updateChangeSubscription();
                this.isLoaded = true;
            }).catch(err => { this.isLoaded = true; });
        }).catch(err => { this.isLoaded = true; });
    }
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }
    viewFile(row) {
        this.api.postFile('File', row).then(res => {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.href = window.URL.createObjectURL(res);
            a.download = row.fileName;
            a.click();
            window.URL.revokeObjectURL(window.URL.createObjectURL(res));
        });
    }
    onFileSelected(event, row): void {
        if (event.target.files[0]) {
            const fd = new FormData();
            fd.append('images', event.target.files[0], event.target.files[0].name)
            fd.append("data", JSON.stringify(row));
            this.api.post("File", fd).then(res => {
                row.fileName = res['fileName'];
                console.log(res);
            })
        }
    }
}
export interface ReturnedItems {
    name: string;
    date: string;
    invoiceDetails: string;

}

