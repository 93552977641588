import { Component, OnInit, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';   
import { MatSnackBar, MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ApiService } from '../../../services/api/api.service';
import { MergeOPsService } from '../../../services/mergeOPs/mergeOPs.service';
import { SinglePrderModalComponent } from '../single-order-modal/single-order-modal.component';
import { OrderFilesModalComponent } from '../order-files-modal/order-files-modal.component';

@Component({
    selector: 'EmagOrdersLibraryComponent',
    templateUrl: './emag.orders.library.component.html',
    styleUrls: ['./emag.orders.library.component.scss']
})

export class EmagOrdersLibraryComponent implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public filterForm: FormGroup;
    displayedColumns: string[] = ['emagId', 'firstName', 'productNames', 'createdAt', 'displayedStatus', 'waitingEmagResponse','actions'];
    dataSource = new MatTableDataSource([]);  
    displayLoader = false;
    EMAG_STATUS_NUMBER = {
        ALL: -1,
        NEW: 1,
        IN_PROGRESS: 2,
        PREPARED: 3,
        FINALIZED: 4,
        CANCELED: 0,
        RETURNED: 5,

    }
    statuses = [
        {
            id: this.EMAG_STATUS_NUMBER.ALL,
            display: 'Toate',
            displaySingle: '',
        },
        {
            id: this.EMAG_STATUS_NUMBER.IN_PROGRESS,
            display: 'In lucru',
            displaySingle: 'In lucru',
        },
        {
            id: this.EMAG_STATUS_NUMBER.CANCELED,
            display: 'Anulate',
            displaySingle: 'Anulata',
        }, 
        {
            id: this.EMAG_STATUS_NUMBER.NEW,
            display: 'Noi',
            displaySingle: 'Noua',
        },
        {
            id: this.EMAG_STATUS_NUMBER.PREPARED,
            display: 'Pregatite',
            displaySingle: 'Pregatita',
        }, 
        {
            id: this.EMAG_STATUS_NUMBER.FINALIZED,
            display: 'Finalizate',
            displaySingle: 'Finalizata',
        }, 
        {
            id: this.EMAG_STATUS_NUMBER.RETURNED,
            display: 'Returnate',
            displaySingle: 'Returnata',
        },
    ]
    constructor(
        public fb: FormBuilder,
        public api: ApiService,
        public mergeOP: MergeOPsService,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        private changeDector: ChangeDetectorRef
    ) {
        
    }
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
    private initializeForm() {
        this.filterForm = this.fb.group({
            status: new FormControl(null),
            startDateTime: new FormControl(null),
            endDateTime: new FormControl(null),
        })
        this.filterForm.get('status').setValue(this.statuses[0])
        this.filterForm.get('endDateTime').setValue(new Date())
        let date = new Date();
        date.setDate(date.getDate() - 15);
        this.filterForm.get('startDateTime').setValue(date)
    }
    public filter(){
        this.displayLoader = true;
        this.api.obsPost('orders/emag',{
            from: this.filterForm.get('startDateTime').value,
            to: this.filterForm.get('endDateTime').value,
            status: this.filterForm.get('status').value.id,
        }).subscribe((res: any) =>{
            res.forEach(order => { 
                order.productNames = this.mergeOP.mergeNamesByOpPaul(order.orderProducts);
                order.firstName = order.client.firstName;
                order.displayedStatus = this.statuses.find(elem => elem.id == order.emagStatus).displaySingle;
                const notReadyOp = order.orderProducts.find(op => !op.producedAt)
                if (notReadyOp){
                    order.ready = false;
                }
                else{
                    order.ready = true;
                }
            });
            // this.dataSource.data = res;
            this.dataSource = new MatTableDataSource(res);
            // this.dataSource._updateChangeSubscription();
            this.displayLoader = false;
            this.changeDector.detectChanges()
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
        }, err =>{
            this.displayLoader = false;
        })
    }
    public markAsReady(row){
        this.changeOrderEmagStatus(row, this.EMAG_STATUS_NUMBER.PREPARED);
    }
    public markAsCanceled(row) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '30%',
            data: {
                title: 'Confirmare',
                question: 'Sunteti sigur ca doriti sa efectuati aceasta operatiune?'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.changeOrderEmagStatus(row, this.EMAG_STATUS_NUMBER.CANCELED);
            } else {
                
            }
        });
    }
    public refreshOrder(row){
        // this.displayLoader = true;
        this.api.obsPost('emag_orders/refresh', {
            emagId: row.emagId
        }).subscribe((res: any) => {
            this.genericEmagToast();
            this.displayLoader = false;
            row.waitingEmagResponse = true;
        }, err => {
            this.genericErrorToast();
            this.displayLoader = false;
        })
    }
    public viewOrder(row){
        const dialogRef = this.dialog.open(SinglePrderModalComponent, {
            width: '90%',
            height: '90%',
            data: row 
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result && result.refresh){
                this.filter();
            }

        });
    }
    public openFilesModal(order) {
        const dialogRef = this.dialog.open(OrderFilesModalComponent, {
            width: '30%',
            height: '40%',
            data: order
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if (result && result.refresh) {
                this.filter();
            }

        });
    }
    private addInvoice(order){
        this.displayLoader = true;
        this.api.obsPost('invoice_v2', {
            orderId: order._id,
            options: {
                type: 'invoice'
            }
        }).subscribe((res: any) => {
            this.genericEmagToast();
            this.displayLoader = false;
        }, err => {
            console.log(err);
            if (err.error.message) {
                this.snackBar.open(err.error.message, 'Inchide', {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            }
            else {
                this.genericErrorToast();
            }
            this.displayLoader = false;
        })
    }
    public prepareAwb(order){
        this.displayLoader = true;
        this.api.obsPost('emag_orders/generate_awb', {
            orderId: order._id,
        }).subscribe((res: any) => {
            this.genericEmagToast();
            this.displayLoader = false;
        }, err => {
            console.log(err);
            if (err.error.message) {
                this.snackBar.open(err.error.message, 'Inchide', {
                    duration: 5000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            }
            else {
                this.genericErrorToast();
            }
            this.displayLoader = false;
        })
    }
    private changeOrderEmagStatus(order, status){
        this.displayLoader = true;
        this.api.obsPost('emag_orders/change_status', {
            emagId: order.emagId,
            status: status
        }).subscribe((res: any) => {
            this.genericEmagToast();
            this.displayLoader = false;
            order.waitingEmagResponse = true;
        }, err => {
            this.genericErrorToast();
            this.displayLoader = false;
        })
    }
    private genericEmagToast(){
        this.snackBar.open('Modificarile au fost trimise catre emag. Rezultatul va fi vizibil in cateva minute!', 'Inchide', {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
        });
    }
    private genericErrorToast(){
        this.snackBar.open('A fost o problema la persistarea datelor. Reincercati in cateva minute!', 'Inchide', {
            duration: 5000,
            horizontalPosition: 'end',
            verticalPosition: 'top',
        });
    }
    ngOnInit(): void {
        this.initializeForm();
        this.filter();
    }
}
@Component({
    selector: 'confirmDialog',
    templateUrl: 'confirmDialog.html',
})
export class ConfirmDialogComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data) {

    }

    confirm(): void {
        this.dialogRef.close(true);
    }
    decline(): void {
        this.dialogRef.close(false);
    }
}