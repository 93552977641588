import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './intercepter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule, MatSnackBarModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { MatSelectSearchModule } from 'app/mat-select-search/mat-select-search.module';
import { AllModule } from 'app/main/modules/all.module';
import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { AuthService } from './main/modules/authGuard/auth.service';
import { AuthGuardService as AuthGuard, AuthGuardService } from './main/modules/authGuard/auth-guard.services';
import { LoginComponent } from './main/modules/login/login.component';
import { DashboardComponent } from './main/modules/dashboard/dashboard.component';
import { DialogDataComponent } from './main/modules/GenericDialog/dialogData.component';
import { QuickPanelModule } from './layout/components/quick-panel/quick-panel.module';
import { ChatPanelModule } from './layout/components/chat-panel/chat-panel.module';
import { DialogConfirmation } from './main/services/api/api.service';
import { ConfirmDialogComponent, OrdersLibraryViewOrderComponent } from './main/modules/orders/library/orders.library.component';

import { OrdersHistoryViewOrderComponent } from './main/modules/orders/history/orders.history.component';
import { DialogConfirmationComponent } from './main/modules/stocks/library/stocks.library.component';
import { SelectCompanyViewOrderComponent } from './main/modules/orders/library/orders.library.component';
// import { AppRoutingModule } from 'app/main/modules/authGuard/app-routing.module';
// import { CanActivateRouteGuard } from 'app/main/modules/authGuard/can-activate-route.guard';
// import { AuthService } from './auth.service';

const appRoutes: Routes = [
    { path: 'auth/login', component: LoginComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
    { path: '**', redirectTo: 'auth/login' }
];

@NgModule({
    declarations: [
        AppComponent,
        DialogDataComponent,
        DialogConfirmation,
        ConfirmDialogComponent,
        OrdersLibraryViewOrderComponent,
        OrdersHistoryViewOrderComponent,
        DialogConfirmationComponent,
        SelectCompanyViewOrderComponent

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSelectSearchModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        AllModule,

        NgMatModule,
        MatSnackBarModule,
        ChatPanelModule
    ],
    entryComponents: [DialogDataComponent, DialogConfirmation,
        OrdersLibraryViewOrderComponent, ConfirmDialogComponent,
        OrdersHistoryViewOrderComponent, DialogConfirmationComponent,
        SelectCompanyViewOrderComponent],

    providers: [
        AuthService,
        AuthGuardService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
