import {Component, Inject} from '@angular/core';

import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';

import {locale as english} from './i18n/en';
import {locale as turkish} from './i18n/tr';
import {DialogDataComponent} from '../GenericDialog/dialogData.component';
import {MatDialog, MatDialogConfig} from '@angular/material';

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent {
    animal: string;
    name: string;

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

    }

    onClick(): void {
    }

    openDialog(): void {
        //  this.dialog.open(DialogDataComponent, {
        //     width: '80%',
        //     height: '500px',
        //     data: {
        //         animal: 'panda'
        //     },
        // });
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = false;
        dialogConfig.autoFocus = true;
        dialogConfig.ariaLabel = 'asdasdsad';
        dialogConfig.width = '150px';

        this.dialog.open(DialogDataComponent, dialogConfig);
    }

    // openDialog(): void {
    //     const dialogRef = this.dialog.open(DialogDataComponent, {
    //         width: '250px',
    //         data: {name: this.name, animal: this.animal}
    //     });
    //
    //     dialogRef.afterClosed().subscribe(result => {
    //         this.animal = result;
    //     });
    // }
}
