import { Injectable, Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { Router } from '@angular/router';

export interface DialogData {
    animal: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})

export class ApiService {
    //var baseUrl = 'http://httpbin.org/ip';

    constructor(
        private http: HttpClient,
        public snackBar: MatSnackBar,
        public dialog: MatDialog,
        public router: Router
    ) {
        this.init();
    }

    getBaseUrl() {
        //return 'http://localhost:3000';
        return 'https://api.poshatelier.ro';
    }

    get(path: string) {

        return this.http.get(this.getBaseUrl() + path);
    }

    getFullPath(path: string) {
        return this.http.get(path);
    }

    hardDelete(object: string, params: object) {
        let path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/hardDelete';
        const promise = new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(DialogConfirmation, {
                width: '250px',
                data: {}
            });
            dialogRef.afterClosed().subscribe(result => {
                if ('closed' == result) {
                    console.log('12');
                    return reject();
                }
                else if ('confirmed' == result) {
                    this.http.post(path, params).subscribe(result => {
                        this.snackBar.open('Entitatea a fost stearsa', 'Inchide', {
                            duration: 10000,
                            horizontalPosition: 'end',
                            verticalPosition: 'top',
                        });
                        resolve(result);
                    });

                }
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }
    softDelete(object: string, params: object) {
        let path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/softDelete';
        const promise = new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(DialogConfirmation, {
                width: '250px',
                data: {}
            });
            dialogRef.afterClosed().subscribe(result => {
                if ('closed' == result) {
                    return reject();
                }
                else if ('confirmed' == result) {
                    this.http.post(path, params).subscribe(result => {
                        this.snackBar.open('Entitatea a fost stearsa', 'Inchide', {
                            duration: 10000,
                            horizontalPosition: 'end',
                            verticalPosition: 'top',
                        });
                        resolve(result);
                    });

                }
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }
    register(params: object) {
        var path = this.getBaseUrl();
        console.log(params);
        if ('undefined' != typeof params['_id']) {
            path += '/users/update';
        }
        else {
            path += '/users/register';
        }
        return new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                this.snackBar.open('Entitatea a fost adaugata', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });

                resolve(result);

            }, err => {
                this.snackBar.open('Entitatea nu a putut fi adaugata!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                console.log(err);
            });
        });
    }
    post(object: string, params: object) {

        var path = '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's';

        path = this.getBaseUrl() + path;
        console.log(path);
        if ('id' in params) {
            path = path + '/update';
        }
        else {
            path = path + '/create';
        }
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                this.snackBar.open('Entitatea a fost adaugata', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });

                resolve(result);

            }, err => {
                if (err.error) {
                    this.snackBar.open(err.error, 'Inchide', {
                        duration: 10000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top',
                    });
                }
                else {
                    this.snackBar.open('Entitatea nu a putut fi adaugata!', 'Inchide', {
                        duration: 10000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top',
                    });
                }
                reject();
            });
        });
        return promise;
    }

    postFile(object: string, params: object) {

        //let finalPath = this.getBaseUrl() + '/' + path;
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/generate';
        return this.http.post(path, params, { responseType: 'blob' }).toPromise();

        // return this.http.post(path, params);
    }
    generateMold() {
        const path = this.getBaseUrl() + '/mold/generate';
        return new Promise((resolve, reject) => {
            this.http.get(path).subscribe(result => {
                resolve(result);
                this.snackBar.open('Spanurile au fost generate!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });

        });
    }

    postChat(object: string, params: object) {
        var path = '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1);
        path = this.getBaseUrl() + path;
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                this.snackBar.open('Entitatea a fost adaugata', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                resolve(result);
            }, err => {
                this.snackBar.open('Entitatea nu a putut fi adaugata!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            });
        });
        return promise;
    }

    postChatNoToast(object: string, params: object): Promise<any> {
        let path = '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1);
        path = this.getBaseUrl() + path;
        return new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {

            });
        });
    }

    rawPost(object: string, params: object): Promise<any> {
        let path = '/' + object;
        path = this.getBaseUrl() + path;
        return new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {

            });
        });
    }
    obsPost(url, obj){
        return this.http.post(`${this.getBaseUrl()}/${url}`, obj);
    }

    obsGet(url){
        // url = `${this.getBaseUrl()}/${url}`
        return this.http.get(`${this.getBaseUrl()}/${url}`);
    }

    postArray(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/update';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                this.snackBar.open('Entitatea a fost adaugata', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
                else if (err.error) {
                    this.snackBar.open(err.error, 'Inchide', {
                        duration: 10000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top',
                    });
                    reject(err)
                }
                else {
                    this.snackBar.open('Entitatea nu a putut fi adaugata!', 'Inchide', {
                        duration: 10000,
                        horizontalPosition: 'end',
                        verticalPosition: 'top',
                    });
                }
            });
        }).catch((err) => {
            if (err.error) {
                this.snackBar.open(err.error, 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            }
            else {
                this.snackBar.open(err, 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            }
        });
        return promise;
    }

    postInvoice(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/generate';
        return new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                this.snackBar.open('Entitatea a fost generata', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        }).catch((err) => {
            this.snackBar.open(err, 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        });

    }

    getByProductionDate(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/getByProductionDate';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }

    getByDate(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/getByDate';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                } else {
                    reject(err);
                }
            });
        });
        return promise;
    }

    getByDeliveryDate(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/getByDeliveryDate';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }

    getOrderHistory(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/history';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }

    getRenderedOrders(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/generate';
        let promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;

    }
    getHistoryOrders(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/generateHistory';
        let promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;

    }
    getRenderedOrders2(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/generate2';
        let promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                } else {
                    reject(err);
                }
            });
        });
        return promise;

    }
    getReportstable(object: string, params: object) {
        const path = this.getBaseUrl() + '/report/' + object;
        console.log(path);
        return new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                } else {
                    reject(err);
                }
            });
        });
    }
    getHistoryOrders2(object: string, params: object) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/generateHistory2';
        let promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                } else {
                    reject(err);
                }
            });
        });
        return promise;
    }
    getAll(object: string) {
        var path = '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's';

        path = this.getBaseUrl() + path + '/getAll';
        var promise = new Promise((resolve, reject) => {
            this.http.get(path).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }
    getAllDisplayingProducts(object: string) {
        var path = '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's';

        path = this.getBaseUrl() + path + '/displayingProducts';
        var promise = new Promise((resolve, reject) => {
            this.http.get(path).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }

    getOne(object: string, params: object) {
        var path = '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's';
        path = this.getBaseUrl() + path + '/getOne';
        var promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
        // return this.http.post(path, params);
    }
    findMany(object: string, params: object) {
        let path = '/' + object.charAt(0).toLowerCase() + object.slice(1) + 's';
        path = this.getBaseUrl() + path + '/findMany';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                } else {
                    reject(err);
                }
            });
        });
        return promise;
    }
    getWithParams(object: string, params: object) {
        let path = '/' + object.charAt(0).toLowerCase() + object.slice(1) + 's';
        path = this.getBaseUrl() + path + '/getWithParams';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
            });
        });
        return promise;
    }
    deleteMany(object: string, param: any) {
        const path = this.getBaseUrl() + '/' + object.charAt(0).toLocaleLowerCase() + object.slice(1) + 's/deleteMany';
        let promise = new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(DialogConfirmation, {
                width: '250px',
                data: {}
            });
            dialogRef.afterClosed().subscribe(result => {
                if ('closed' == result) {
                    return reject();
                }
                else if ('confirmed' == result) {
                    this.http.post(path, param).subscribe(result => {
                        this.snackBar.open('Entitatea a fost stearsa', 'Inchide', {
                            duration: 10000,
                            horizontalPosition: 'end',
                            verticalPosition: 'top',
                        });
                        resolve(result);
                    }, err => {
                        console.log(err);
                        if (401 == err.status) {
                            this.router.navigate(['auth/login']);
                        }
                    });

                }
            });
        });
        return promise;
    }
    delete(entityModel: string, _id: string) {
        //this.computePath(entityModel, _id, 'delete');
        var promise = new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(DialogConfirmation, {
                width: '250px',
                data: {}
            });

            dialogRef.afterClosed().subscribe(result => {
                if ('closed' == result) {
                    return reject();
                }
                else if ('confirmed' == result) {
                    this.http.post(this.computePath(entityModel, _id, 'delete'), { id: _id }).subscribe(result => {
                        this.snackBar.open('Entitatea a fost stearsa', 'Inchide', {
                            duration: 10000,
                            horizontalPosition: 'end',
                            verticalPosition: 'top',
                        });
                        resolve(result);
                    }, err => {
                        console.log(err);
                        if (401 == err.status) {
                            this.router.navigate(['auth/login']);
                        }
                    });
                }
            });
        });
        return promise;
    }
    gerReturnedOrdersByDate(object: string, params: object) {
        let path = '/' + object.charAt(0).toLowerCase() + object.slice(1) + 's';
        path = this.getBaseUrl() + path + '/getReturnedOrdersByDate';
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                console.log(err);
                if (401 == err.status) {
                    this.router.navigate(['auth/login']);
                }
                else {
                    reject(err);
                }
            });
        });
        return promise;
    }

    computePath(entityModel: string, _id: string, action: string) {
        return this.getBaseUrl() + '/' + entityModel.charAt(0).toLocaleLowerCase() + entityModel.slice(1) + 's/' + action;
    }


    init() {

    }
}

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'modalConfirmation.html',
})
export class DialogConfirmation {
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmation>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }
}
