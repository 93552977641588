import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ClientsDefineComponent } from './define/clients.define.component';
import { ClientsLibraryComponent } from './library/clients.library.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { MatSelectSearchModule } from 'app/mat-select-search/mat-select-search.module';
import { AuthGuardService as AuthGuard } from '../../authGuard/auth-guard.services';
const routes = [
    {
        path: 'client/define',
        component: ClientsDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'client/define/:id',
        component: ClientsDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'client/library',
        component: ClientsLibraryComponent, canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ClientsDefineComponent,
        ClientsLibraryComponent,


    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        MatSelectSearchModule,
        NgMatModule
    ],
    exports: [
        ClientsDefineComponent,
        ClientsLibraryComponent,
        MatSelectSearchModule,

    ]
})

export class ClientsModule {
}
