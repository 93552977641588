import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { OrdersDefineComponent, StocksProductsDialogComponent } from './define/orders.define.component';
import { OrdersLibraryComponent } from './library/orders.library.component';
import { OrdersHistoryComponent } from './history/orders.history.component';


import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { MatSelectSearchModule } from 'app/mat-select-search/mat-select-search.module';
import { AuthGuardService as AuthGuard } from '../authGuard/auth-guard.services';
import { DialogDataComponent } from '../GenericDialog/dialogData.component';
import { OrdersViewComponent } from './viewOrderDetails/orders.view.component';
import { InternalOrderComponent } from './internal/orders.internal.component';
import { EmagOrdersLibraryComponent, ConfirmDialogComponent  } from './emag/emag.orders.library.component'
import { SinglePrderModalComponent } from './single-order-modal/single-order-modal.component'
import { OrderFilesModalComponent } from './order-files-modal/order-files-modal.component'
import { ClientOrdersModalComponent } from './client-orders-modal/client-orders-modal.component'

import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
const routes = [
    {
        path: 'order/define',
        component: OrdersDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'order/define/:id',
        component: OrdersDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'order/library',
        component: OrdersLibraryComponent, canActivate: [AuthGuard]
    },
    {
        path: 'stocksDialog',
        component: StocksProductsDialogComponent, canActivate: [AuthGuard]
    },
    {
        path: 'order/history',
        component: OrdersHistoryComponent, canActivate: [AuthGuard]
    },
    {
        path: 'order/view',
        component: OrdersViewComponent, canActivate: [AuthGuard]
    },
    {
        path: 'order/internal',
        component: InternalOrderComponent, canActivate: [AuthGuard]
    },
    {
        path: 'emag/library',
        component: EmagOrdersLibraryComponent, canActivate: [AuthGuard]
    }
    
];

@NgModule({
    declarations: [
        OrdersDefineComponent,
        OrdersLibraryComponent,
        StocksProductsDialogComponent,
        OrdersHistoryComponent,
        OrdersViewComponent,
        InternalOrderComponent,
        EmagOrdersLibraryComponent,
        SinglePrderModalComponent,
        ConfirmDialogComponent,
        OrderFilesModalComponent,
        ClientOrdersModalComponent,

    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        MatSelectSearchModule,
        NgMatModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
    ],
    exports: [
        OrdersDefineComponent,
        OrdersLibraryComponent,
        StocksProductsDialogComponent,
        OrdersHistoryComponent,
        OrdersViewComponent,
        InternalOrderComponent,
        EmagOrdersLibraryComponent
    ],
    entryComponents: [
        SinglePrderModalComponent,
        ConfirmDialogComponent,
        OrderFilesModalComponent,
        ClientOrdersModalComponent,
    ],
})

export class OrdersModule {
}
