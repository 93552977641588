import {Component, Inject} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

export interface DialogData {
    animal: string;
    name: string;
    entity: string;
}

@Component({
    selector: 'dialogBox',
    templateUrl: './dialog.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogDataComponent {
    public keys = [];
    // public viewEntity: any;


    constructor(
        public dialogRef: MatDialogRef<DialogDataComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {

        for (const prop in data) {
            this.keys.push(prop);
        }
    }
}
