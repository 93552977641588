import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../../main/services/api/api.service';
import { AuthService } from '../../../main/modules/authGuard/auth.service';


import { timer } from 'rxjs/observable/timer';
import { concatMap, map, tap } from 'rxjs/operators';

@Injectable()
export class ChatPanelService {
    contacts: any;
    user: any;

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     * @param {ApiService} api
     * @param {AuthService} authService
     */
    constructor(
        private _httpClient: HttpClient,
        private api: ApiService,
        private authService: AuthService
    ) {

    }

    getPollingStream(resolve = null, reject = null): void {
        const chat = this.api.get('/chat/conversation/list');

        timer(0, 10000).pipe(
            // timer(0, 1).pipe(
            concatMap(_ => chat),
            map((response: any) => response),
        ).subscribe(chats => {
            this.api.getAll('User')
                .then((users: any) => {
                    this.user = users.find(user => user._id === this.authService.getuser().sub);

                    const contacts = {};
                    for (const user of users) {
                        if (user._id !== this.user._id) {
                            contacts[user._id] = {
                                'avatar': 'https://image.flaticon.com/icons/png/512/147/147140.png',
                                'name': user.firstName + ' ' + user.lastName,
                                'id': user._id,
                                'unread': 0,
                                'status': 'busy',
                            };
                        }
                    }

                    for (const conv of chats) {
                        // only handle peer to peer chats for now
                        const other = conv.conversation.members
                            .find(item => item != this.user._id);

                        contacts[other].unread = conv.unseen;
                        contacts[other].conversation = conv.conversation;
                    }

                    this.contacts = Object.values(contacts);

                    if (resolve) {
                        resolve();
                    }
                })
                .catch(err => {
                    if (reject) {
                        reject(err);
                    }
                })
                ;
        });
    }

    seeChat(contactId): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.get('/chat/conversation/list').subscribe((res: any) => {
                let conv = res.find(item => item.conversation.members.includes(contactId));
                if (conv) {
                    this.api.postChatNoToast('chat/conversation/see', {
                        conversation: conv.conversation._id,
                    })
                        .then(() => {
                            resolve();
                        });
                }
            });
        });
    }

    contactsObject(): any {
        const contacts = {};
        for (const contact of this.contacts) {
            contacts[contact.id] = contact;
        }

        return contacts;
    }

    /**
     * Get chat
     *
     * @param contactId
     * @returns {Promise<any>}
     */
    getChat(contactId): Promise<any> {

        return new Promise((resolve, reject) => {
            const contacts = this.contactsObject();
            if (contacts.hasOwnProperty(contactId)) {
                const contact = contacts[contactId];
                if (!contact.hasOwnProperty('conversation')) {
                    this.api.postChat('chat/conversation', { with: [contactId] })
                        .then(conversation => {
                            contact.conversation = conversation;

                            resolve({
                                id: contact.conversation._id,
                                dialog: contact.conversation.messages,
                            });
                        })
                        ;
                } else {
                    resolve({
                        id: contact.conversation._id,
                        dialog: contact.conversation.messages,
                    });
                }
            } else {
                reject();
            }
        });
    }

    /**
     * Update the chat
     *
     * @param chat
     * @param message
     * @returns {Promise<any>}
     */
    updateChat(chat, message): Promise<any> {
        return new Promise((resolve, reject) => {
            this.api.postChat('chat/message', { conversation: chat.id, body: message })
                .then(updatedChat => {
                    resolve(updatedChat);
                }, reject);
        });

    }
}
