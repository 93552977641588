import { Component, Inject, ViewChild, OnInit } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MatTableModule, MatSnackBar } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApiService } from '../../../services/api/api.service';
import { MergeOPsService } from '../../../services/mergeOPs/mergeOPs.service';
import { Router } from '@angular/router';
import { AuthService } from '../../authGuard/auth.service';
@Component({
    selector: 'OrdersLibraryComponent',
    templateUrl: './orders.library.component.html',
    styleUrls: ['./orders.library.component.scss']
})

export class OrdersLibraryComponent implements OnInit {
    // displayedColumns = ['client','createdAt', 'products', 'status', 'buttons'];
    displayedColumns = ['client', 'productNames', 'createdAt', 'dateToDeliver', 'status', 'transport', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dateFrom = new FormControl((moment(new Date())).subtract(4, 'd').toISOString());
    dateTo = new FormControl((new Date()).toISOString());
    transportFeeToggle = new FormControl();

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    orderForm: FormGroup;
    public clients: any = [];
    public filterControl = new FormControl();
    public orderProducts: any;
    public filteredClients: any;
    public currentTimeOrders: any = [];
    public ordersToAdd: any = [];
    public apiOrderProducts: any = [];
    public apiProduct: any = [];
    public currentOrderProducts: any = [];
    public characteristicTypesList: any = [];
    public characteristicsList: any = [];
    public renderedOrders: any = {};
    public isLoaded = false;
    public authEmail = '';
    public role = '';
    public filterValue = '';
    public apiCallInProgressFlag = false;


    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        public api: ApiService,
        public dialog: MatDialog,
        public MergeOp: MergeOPsService,
        private router: Router,
        public auth: AuthService,
        public snackBar: MatSnackBar
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        // this.filterControl.disable();
        // this.transportFeeToggle.setValue(true);
        this.searchByDate();
        if (auth.isAuthenticated()) {
            this.authEmail = auth.getuser()['email'];
            this.role = auth.getRoles().toString();
        }
    }

    // ngAfterViewInit() {
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    // }

    applyFilter() {
        // this.filterValue = this.filterValue.trim(); // Remove whitespace
        this.filterValue = this.filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = this.filterValue;
    }
    searchByDate(): void {
        this.ordersToAdd = [];
        this.isLoaded = false;
        const ordersFrom = moment(this.dateFrom.value).format('YYYY-MM-DD') + 'T00:00';
        const ordersTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59';
        const params = {
            orderProducts: {
                $exists: true
            },
            $where: 'this.orderProducts.length>0',
            deliveryDate: null
        };
        this.api.getRenderedOrders2('Order', { 'from': ordersFrom, 'to': ordersTo, 'params': params }).then((orders: any) => {
            for (const order in orders) {
                if (!orders.hasOwnProperty(order)) { continue; }
                const opIdArr = [];
                const neededOPObjects = [];
                let orderStatus = 'Comandat';
                const orderDate = moment(orders[order].createdAt).format('YYYY/MM/DD');
                const today = moment(Date.now()).format('YYYY/MM/DD');
                if (orderDate < today) {
                    orderStatus = 'In productie';
                }
                
                orders[order].producedAt = false;
                       let ok = true; 
                // orders[order].orderProducts.forEach(op =>{
                for (const opIndex in Object.keys(orders[order].orderProducts)) {
                    if (!Object.keys(orders[order].orderProducts.hasOwnProperty(opIndex))) { continue; }
                    const op = orders[order].orderProducts[opIndex];
                    if (op.producedAt === null) {
                        ok = false;
                    }
                    // console.log(orders[order]._id.toString(), op.order.toString());
                    if (null != op.order && orders[order]._id.toString() === op.order.toString()){
                        op.color = op.characteristics[0];
                        op.size = op.characteristics[1];
                        op.characteristics = [op.characteristics[0]._id, op.characteristics[1]._id];
                        op.productObject = op.product;
                        op.product = op.product._id;
                        
                        opIdArr.push(op._id);
                        neededOPObjects.push(op);
                    }
                    // else{
                    //     orders[order].orderProducts.splice(op, 1)
                    // }
                }
                if (ok) {
                    orders[order].producedAt = true;
                    orderStatus = 'Gata de expediere';
                }

                if (typeof orders[order].proformInfo !== 'undefined') {
                    orderStatus = 'Predat la curier';
                }
                if (orders[order].invoiceInfo.length > 0) {
                    orderStatus = 'Incasat';
                }
                orders[order].orderProductObjects = neededOPObjects;
                orders[order].orderProducts = opIdArr;
                orders[order].clientObject = orders[order].client;
                orders[order].client = orders[order].clientObject._id;
                this.renderedOrders[orders[order]._id] = orders[order];
                // console.log(orders);
                
                // orders[order].producedAt = false;
                // let ok = true;
                // orders[order].orderProductObjects.forEach(op => {
                //     if (op.producedAt === null) {
                //         ok = false;
                //     }
                // });
                // if (ok) {
                //     orders[order].producedAt = true;
                // }
                const mergedNames = this.MergeOp.mergeNames(orders[order].orderProductObjects);
                this.ordersToAdd.push({
                    'client': orders[order].clientObject.firstName + ' ' + orders[order].clientObject.surName,
                    'orderProducts': orders[order].orderProductObjects,
                    'productNames': mergedNames,
                    'id': orders[order]._id,
                    'order': orders[order],
                    'status':orderStatus
                });
                
            }
            this.dataSource = new MatTableDataSource(this.ordersToAdd);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource._updateChangeSubscription();
            this.applyFilter();
            this.isLoaded = true;
        });
    }

    ngOnInit(): void {
        this.filterControl.valueChanges
            .debounceTime(500) // <-- la fiecare 500 de secunde sa nu rupi banda
            .subscribe(query => {

                if (query !== null && query.length > 2) {
                    this.filteredClients = this.clients.filter(client => client.phoneNumber.search(query) !== -1);
                    if (!(typeof this.filteredClients[0] === 'undefined') && this.filteredClients[0].phoneNumber === this.filterControl.value) {

                    }
                }
                // plasat, produs, livrat, returnat
                // adauga in stoc cu produs now
                else {
                    // this.filteredClients = this.clients.filter(client => client.phoneNumber.search(query) !== -1);

                }

            });
    }
    edit(order: any): void {
        // orderProductsToModal = this.MergeOp.mergeOrderProducts(orderProductsToModal);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = false;
        dialogConfig.minHeight = '80%';
        dialogConfig.width = '60%';
        dialogConfig.data = {
            order: order,
            status: order.status,
            title: 'Vizualizare comanda'
        };
        console.log(order.order);
        const dialogRef = this.dialog.open(OrdersLibraryViewOrderComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if(typeof result !== 'undefined' && 'undefined' != typeof result.refresh){
                this.searchByDate();
            }
            else if (typeof result !== 'undefined' && result.returnOrderProducts !== false) {
                console.log(result);
                this.returnOrderProducts(result);
            }
        });
        // this.searchByDate();
    }
    editOrder(row: any): void {
        localStorage.setItem('editOrder', JSON.stringify(row.order));
        this.router.navigate(['order/define']);

    }
    returnOrderProducts(result): void {
        const orderProductsToUpdate = [];
        const resultOrderProductsIDs = [];
        let deleteFlag = false;
        if (result.status === 'Comandat') {
            deleteFlag = true;
        }

        if (result.returnOrderProducts === true) {
            result.orderProductsToReturn.forEach(orderProductID => {

                resultOrderProductsIDs.push(orderProductID._id);
                if (!deleteFlag || orderProductID.producedAt !== null) {
                    orderProductsToUpdate.push({
                        'id': orderProductID._id,
                        'order': null
                        // 'price': orderProductID.productObject.price
                    });
                }
                this.renderedOrders[result.order._id].orderProductObjects.splice(
                    this.renderedOrders[result.order._id].orderProductObjects.findIndex(op => op._id.toString() === orderProductID._id.toString()), 1);
                this.renderedOrders[result.order._id].orderProducts.splice(
                    this.renderedOrders[result.order._id].orderProducts.findIndex(op => op.toString() === orderProductID._id.toString()), 1);
            });
        }
        if (this.renderedOrders[result.order._id].orderProducts.length === 0) {
            this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.id === result.order._id), 1);
            this.dataSource._updateChangeSubscription();
        }

        if (deleteFlag && orderProductsToUpdate.length === 0) {
            this.api.hardDelete('OrderProduct', result.orderProductsToReturn).then(response => { });

        }
        if (orderProductsToUpdate.length > 0) {
            this.api.postArray('OrderProduct', orderProductsToUpdate).then(OrderProductUpdate => { });
        }
        this.api.post('Order', { 'id': result.order._id, 'orderProducts': this.renderedOrders[result.order._id].orderProducts }).then(orderUpdated => { });




        // const invoice = {
        //     invoiceType: 'Invoice_add',
        //     orderProducts: resultOrderProductsIDs
        // };
        // const currentOrder = result.order;
        // this.api.postFile('Invoice', { order: currentOrder, invoice }).then(result => {
        //     // const fileURL = URL.createObjectURL(result);
        //     // window.open(fileURL);
        // });
    }
    sentToCourier(order: object): void {
        this.apiCallInProgressFlag = true;
        const invoice = {
            invoiceType: 'Proform',
            transportFee: order['paymentMethod'],
            awb: true
        };
        this.api.postFile('Invoice', { order, invoice }).then(result => {
            const fileURL = URL.createObjectURL(result);
            window.open(fileURL);
            this.searchByDate();
            this.apiCallInProgressFlag = false;
            
        }).catch(err => {
            console.log(err);
            this.apiCallInProgressFlag = false;
            this.snackBar.open('Generarea AWB-ului nu a reusit. Reincercati!', '', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        });


    }
    printProform(order: object): void {

        const invoice = {
            invoiceType: 'Proform',
            transportFee: order['paymentMethod'],
            awb: false
        };
        this.api.postFile('Invoice', { order, invoice }).then(result => {
            const fileURL = URL.createObjectURL(result);
            window.open(fileURL);
            this.searchByDate();

        });


    }
    removeAwbFromOrder(row: any): void {
        this.apiCallInProgressFlag = true;
        this.api.obsPost('orders/removeAwbFromOrder', { orderId: row.order._id}).subscribe((order: any) => {
            console.log(order);
            this.snackBar.open('AWB sters cu succes!', '', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.apiCallInProgressFlag = false;
            this.searchByDate();
        }, err =>{
            this.snackBar.open('A aparut o erare in timpul stergerii', '', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.apiCallInProgressFlag = false;
        });
    }
    
    deleteOrder(orderBigObject: any): void {
        this.apiCallInProgressFlag = true;
        this.api.obsPost('orders/getOrderByIdComputedForInvoices', { orderId: orderBigObject.order.parentOrder}).subscribe((order: any) => {
            console.log(order);
            
        }, err =>{

        });
        console.log(orderBigObject);
        // return;
        const isAChangeOrder = orderBigObject.order.returnedOrderProducts.length != 0 && 'undefined' != typeof orderBigObject.order.parentOrder? true: false;
        
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '30%',
            data: {
                title: 'Confirmare',
                question: 'Sunteti sigur ca doriti sa efectuati aceasta operatiune?',
                isAChangeOrder: isAChangeOrder
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                const orderProductsToUpdate = [];
                const orderProductsToHardDelete = [];
                const promissesArray = [];
                orderBigObject.order.orderProductObjects.map(op => {
                    if (null == op.moldId && op.changes && !op.changes.addedInStock) {
                        orderProductsToHardDelete.push(op._id);
                    }
                    else {
                        orderProductsToUpdate.push({
                            id: op._id,
                            order: null
                        });
                    }
                })
                promissesArray.push(this.api.post('Order', { id: orderBigObject.order._id, orderProducts: [] }));
                if (0 < orderProductsToHardDelete.length) {
                    promissesArray.push(this.api.hardDelete('OrderProduct', orderProductsToHardDelete))
                }
                if (0 < orderProductsToUpdate.length) {
                    promissesArray.push( this.api.postArray('OrderProduct', orderProductsToUpdate));
                }
                if(isAChangeOrder){
                    const invoice = {
                        invoiceType: 'Invoice_add',
                        orderProducts: orderBigObject.order.returnedOrderProducts,
                        storno: true,
                        transportFeeFlag: this.transportFeeToggle.value,
                        returnDate: result.value
                    };
                    this.api.obsPost('orders/getOrderByIdComputedForInvoices', { orderId: orderBigObject.order.parentOrder}).subscribe((parentOrder: any) => {
                        console.log(parentOrder);
                        delete parentOrder[0].orderProducts;
                        parentOrder[0].clientObject = parentOrder[0].client;
                        promissesArray.push(this.api.postFile('Invoice', { order: parentOrder[0], invoice }));
                    }, err =>{
            
                    });
                }
                Promise.all(promissesArray).then(response =>{
                    console.log(response);
                    this.apiCallInProgressFlag = false;
                    this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.id === orderBigObject.order._id), 1);
                    this.dataSource._updateChangeSubscription();
                }, err =>{
                    console.log(err);
                })
            } else {
                this.apiCallInProgressFlag = false;
            }
        });
    }

    deleteOrder2(orderBigObject: any): void {
        const orderProductsToUpdate = [];
        const orderProductsToHardDelete = [];
        this.apiCallInProgressFlag = true;
        orderBigObject.order.orderProductObjects.map(op => {
            if (null == op.moldId && op.changes && !op.changes.addedInStock) {
                orderProductsToHardDelete.push(op._id);
            }
            else {
                orderProductsToUpdate.push({
                    id: op._id,
                    order: null
                });
            }
        })
        if (0 < orderProductsToHardDelete.length) {
            this.api.hardDelete('OrderProduct', orderProductsToHardDelete).then((resp: any) => {
                if (0 == orderProductsToUpdate.length) {
                    // this.api.hardDelete('Order', [orderBigObject.order._id]).then(resp => {
                    this.api.post('Order', { id: orderBigObject.order._id, orderProducts: [] }).then(resp => {
                        this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.id === orderBigObject.order._id), 1);
                        this.dataSource._updateChangeSubscription();
                    });
                }
            });
        }
        if (0 < orderProductsToUpdate.length) {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                width: '30%',
                data: {
                    title: 'Confirmare',
                    question: 'Sunteti sigur ca doriti sa efectuati aceasta operatiune?'
                }
            });
            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.api.postArray('OrderProduct', orderProductsToUpdate).then((resp: any) => {
                        this.api.post('Order', { id: orderBigObject.order._id, orderProducts: [] }).then(resp => {
                            this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.id === orderBigObject.order._id), 1);
                            this.dataSource._updateChangeSubscription();
                            this.apiCallInProgressFlag = false;
                        });
                    });
                } else {
                    this.apiCallInProgressFlag = false;
                }
            });
        }
    }
    deleteOrder3(orderBigObject: any): void {
        const orderProductsToUpdate = [];
        const orderProductsToHardDelete = [];
        let countOPS = 0;
        orderBigObject.order.orderProductObjects.forEach(orderProduct => {
            if (orderProduct.producedAt == null) { // hard delete
                orderProductsToHardDelete.push(orderProduct._id);
                countOPS++;
            } else {
                orderProductsToUpdate.push({
                    'id': orderProduct._id,
                    'order': null
                });
            }
        });
        const updateOrderProductsPromise = new Promise((resolve, reject) => {
            this.api.postArray('OrderProduct', orderProductsToUpdate).then((resp: any) => {
                resolve(resp);
            });
        });
        const deleteOrderPromise = new Promise((resolve, reject) => {
            const orderParams = {
                'id': orderBigObject.order._id,
                'orderProducts': []
            };
            this.api.post('Order', orderParams).then(resp => {
                resolve(resp);
            });
        });
        const hardDeleteOrderProductsPromise = new Promise((resolve, reject) => {
            this.api.hardDelete('OrderProduct', orderProductsToHardDelete).then(resp => {
                resolve(resp);
            });
        });
        if (countOPS == 0) { // no op to hardDelete
            Promise.all([updateOrderProductsPromise, deleteOrderPromise]).then(resp => {

                this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.id === orderBigObject.order._id), 1);
                this.dataSource._updateChangeSubscription();
            });

        }
        else if (countOPS == orderBigObject.order.orderProducts.length) { // hard delete all orderProducts
            Promise.all([hardDeleteOrderProductsPromise, deleteOrderPromise]).then(resp => {
                this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.id === orderBigObject.order._id), 1);
                this.dataSource._updateChangeSubscription();
            });
        }
        else if (countOPS > 0) {
            Promise.all([hardDeleteOrderProductsPromise, deleteOrderPromise, updateOrderProductsPromise]).then(resp => {
                this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.id === orderBigObject.order._id), 1);
                this.dataSource._updateChangeSubscription();
            });
        }



    }
    
}

@Component({
    selector: 'confirmDialog',
    templateUrl: 'confirmDialog.html',
})
export class ConfirmDialogComponent {
    returnedDate = new FormControl((new Date()).toISOString());
    constructor(public dialogRef: MatDialogRef<OrdersLibraryViewOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OrderDialogData) {
        console.log(data); 

    }

    confirm(): void {
        if(this.data['isAChangeOrder']){
            this.dialogRef.close(this.returnedDate);
        }
        else{
            this.dialogRef.close(true);
        }
    }
    decline(): void {
        this.dialogRef.close(false);
    }
}
@Component({
    selector: 'ordersLibraryViewOrder',
    templateUrl: 'ordersLibraryViewOrder.html',
})
export class OrdersLibraryViewOrderComponent {
    currentOrderDisplayedColumns = ['product', 'details', 'color', 'size', 'price', 'buttons'];
    optionsDisplayedColumns = ['source', 'buttons'];
    currentOrderDataSource: MatTableDataSource<UserData>;
    role = 'unk';
    localOrder;
    selectedOrderProduct = null;
    optionsForSelectedOrderProduct = null;
    displayedItemFromStock = null;
    constructor(
        public dialogRef: MatDialogRef<OrdersLibraryViewOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OrderDialogData,
        public api: ApiService,
        private router: Router,
        public snackBar: MatSnackBar,
        public auth: AuthService) {
        this.localOrder = data;
        this.currentOrderDataSource = new MatTableDataSource(data.order.orderProducts);
        if (auth.isAuthenticated()) {
            this.role = auth.getRoles().toString();
        }
    }

    close(): void {
        const returnedDataFromModal = {
            'returnOrderProducts': false,
        };
        this.dialogRef.close(returnedDataFromModal);
    }

    returnedItems(): void {
        const orderProductsToReturn = [];
        this.currentOrderDataSource.data.forEach(orderProduct => {
            if (orderProduct.checked === true) {
                orderProductsToReturn.push(orderProduct);
            }
        });
        const returnedDataFromModal = {
            'returnOrderProducts': true,
            'status': this.data.status,
            'orderProductsToReturn': orderProductsToReturn,
            'order': this.localOrder.order,

        };
        this.dialogRef.close(returnedDataFromModal);


    }
    viewOrder(): void {
        localStorage.removeItem('viewFullOrder');
        localStorage.setItem('viewFullOrder', JSON.stringify(this.localOrder.order.order));
        this.router.navigate(['order/view']);
        this.dialogRef.close();
    }
    displayOptions(row): void{
        console.log(row);
        this.api.rawPost('orderProducts/getSimilarNotSent', {id: row._id}).then(res =>{
            console.log(res);
            this.selectedOrderProduct = row;
            this.optionsForSelectedOrderProduct = res;
        }).catch(err =>{
            console.log(err);
        })
    }

    updatePrice(row): void{
        this.api.rawPost('orderProducts/changeOpPrice', {id: row._id, newPrice: row.price}).then(res =>{
            this.snackBar.open('Pret modificat cu success', '', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        }).catch(err =>{
            console.log(err);
            this.snackBar.open('Modificarea pretului nu a reusit. Reincercati!', '', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        })
    }


    switchOp(row): void{
        console.log(row);
        this.api.rawPost('orderProducts/switchOpInOrder', {first: row._id, second: this.selectedOrderProduct._id}).then(res =>{
            console.log(res);
            this.dialogRef.close({refresh:true});
        }).catch(err =>{
            console.log(err);
        })
    }

    returnedOrder(): void {
        const orderProductsToReturn = [];
        this.currentOrderDataSource.data.forEach(orderProduct => {
            orderProductsToReturn.push(orderProduct._id);
        });
        const returnedDataFromModal = {
            'returnOrderProducts': true,
            'status': this.data.status,
            'orderProductsToReturn': orderProductsToReturn,
            'orderID': this.currentOrderDataSource.data[0]._id
        };
        this.dialogRef.close(returnedDataFromModal);
    }
}
@Component({
    selector: 'selectCompanyViewOrder',
    templateUrl: './selectCompanyVIewOrder.html',
})
export class SelectCompanyViewOrderComponent {
    invoiceDetailsDisplayedColumns = ['checked', 'invoiceDetails'];
    currentOrderDataSource: MatTableDataSource<any>;
    constructor(
        public dialogRef: MatDialogRef<OrdersLibraryViewOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public api: ApiService) {
        // this.currentOrderDataSource = new MatTableDataSource(data.order);
    }

}
export interface UserData {
    _id: string;
    productId: string;
    quantity: string;
    dimension: string;
    checked: boolean;
    orderID: string;
    order: string;
    id: string;
}

export interface OrderDialogData {
    name: string;
    color: string;
    size: string;
    orderProducts: any;
    status: string;
    order: any;
}
