import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../authGuard/auth.service';
import { MatSnackBar } from '@angular/material';
import * as jwtDecode from 'jwt-decode';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private api: AuthService,
        private activatedRoute: ActivatedRoute,
        private _fuseNavigationService: FuseNavigationService,
        private router: Router,
        public snackBar: MatSnackBar,
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };
        // api.get(api.getBaseUrl()).subscribe((status, ret) => {
        // });
        // la post trimit numele entitatii si jsonul
        // in metoda de post din serviciu de api concatenez base url + numele entitatii(lower case) + s
        // daca jsnoul trimis are cheia id atunci este update, else create

        // this.activatedRoute.url.subscribe(url => {
        //     if( url[0].path === 'auth')
        //     {
        //         localStorage.setItem('userInfo', 'true');
        //     }
        //     //  if (localStorage){
        //     //      // this.router.navigate(['/dashboard']);
        //     //  }
        //
        //
        //
        // });

    }
    logIn(): void {

        const verifiedUser = {
            'email': this.loginForm.controls['email'].value,
            'password': this.loginForm.controls['password'].value
        };
        this.api.login(verifiedUser).then(authToken => {
            if (typeof authToken['token'] === 'undefined') {
            }
            else {
                localStorage.setItem('authToken', authToken['token']);
                this._fuseNavigationService.setCurrentNavigation(jwtDecode(authToken['token'])['role']);
                this.router.navigate(['/dashboard']);
            }

        }, rejected =>
                this.snackBar.open('Credentiale incorecte!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                }));

    }




    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });


    }
}
