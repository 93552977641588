import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';
import { Router } from '@angular/router';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

//json local db
declare function require(url: string);

let jsonDB = require('../../../db.json');
let db = jsonDB.userTypes;
let employees = jsonDB.employees;
// import * as data from '../../../../db.json';
// const word = data.name;
@Component({
    selector: 'EmployeesDefine',
    templateUrl: './employees.define.component.html',
    styleUrls: ['./employees.define.component.scss']
})

export class EmployeesDefineComponent {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    employeesForm: FormGroup;
    editFields: any;
    permissionsOptions: any;
    defaultOption: number;
    permissionContr: FormGroup;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        this.activeRoute.params.subscribe(params => {
            this.editFields = params;
        });

        this.employeesForm = fb.group({
            'name': null,
            'phoneNumber': null,
            'permissions': null,
            'permissionsOptions': null,
            'defaultOption': null,
            'defaultOptionID': 0,
        });
        this.permissionsOptions = db;
        this.defaultOption = this.permissionsOptions[0];

        if (this.editFields.id) {
            this.api.getOne('employee', { 'id': this.editFields.id }).then(param => {
                this.employeesForm = fb.group({
                    'id': param['_id'],
                    'name': param['name'],
                    'phoneNumber': param['phoneNumber']
                });
            }, error => {

            });
        }

    }

    onClick(): void {
        this.api.get(this.api.getBaseUrl()).subscribe((data) => {
        });
    }

    onSubmit(value): void {
        this.api.post('Employee', this.employeesForm.value).then(param => {
            this.router.navigate(['employee/library']);
        });

    }

    disableSubmit(): boolean {
        if (this.employeesForm.value.name && this.employeesForm.value.phoneNumber !== null
            && this.employeesForm.value.phoneNumber.length >= 10 && this.employeesForm.value.phoneNumber.length <= 10) {

            return false;
        }
        return true;
    }

    ngOnInit(): void {
        this.activeRoute.params.subscribe(params => console.log(params));
        this.permissionContr = this.fb.group({
            permisionControl: this.defaultOption['name']
        });
        // this.defaultOption=db[0];


    }
}
