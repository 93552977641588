import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MatTableModule, MatDialogConfig } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ApiService } from '../../../services/api/api.service';
import { MergeOPsService } from '../../../services/mergeOPs/mergeOPs.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { AuthService } from '../../authGuard/auth.service';
import { ClientOrdersModalComponent } from '../client-orders-modal/client-orders-modal.component';



@Component({
    selector: 'OrdersHistoryComponent',
    templateUrl: './orders.history.component.html',
    styleUrls: ['./orders.history.component.scss']
})

export class OrdersHistoryComponent implements OnInit {
    displayedColumns = ['client', 'productNames', 'deliveryDate', 'orderDate', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    dateFrom = new FormControl((moment(new Date())).subtract(7, 'd').toISOString());
    dateTo = new FormControl((new Date()).toISOString());
    
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    orderForm: FormGroup;
    public clients: any = [];
    public filterControl = new FormControl();
    public orderProducts: any;
    public currentTimeOrders: any = [];
    public ordersToAdd: any = [];
    public apiOrderProducts: any = [];
    public apiProduct: any = [];
    public currentOrderProducts: any = [];
    public characteristicTypesList: any = [];
    public characteristicsList: any = [];
    public renderedOrders: any = [];
    public isLoaded = false;
    role = '';

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        public api: ApiService,
        public dialog: MatDialog,
        public mergeOP: MergeOPsService,
        public snackBar: MatSnackBar,
        private router: Router,
        public auth: AuthService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        // this.filterControl.disable();
        this.searchByDate();
        if (auth.isAuthenticated()) {
            this.role = auth.getRoles().toString();
        }
        window.addEventListener('beforeunload', (event) => {
            if(!this.isLoaded){
                this.api.obsPost('logs/create', {
                    action: 'force_refresh_finish_order',
                }).subscribe(res => {
                    console.log(res);
                }, err => {
                    console.log(err);
                })
                event.returnValue = `Reload page?`;
            }
        });
    }

    // ngAfterViewInit() {
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    // }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }
    viewFullOrder(row): void {
        localStorage.setItem('viewFullOrder', JSON.stringify(row.order));
        this.router.navigate(['order/view']);
    }
    viewClientOrders(row){
        const dialogRef = this.dialog.open(ClientOrdersModalComponent, {
            width: '60%',
            height: '90%',
            data: {
                client: row.client
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);
        });
    }
    searchByDate(): void {
        this.isLoaded = false;
        this.ordersToAdd = [];
        const ordersFrom = moment(this.dateFrom.value).format('YYYY-MM-DD') + 'T00:00';
        const ordersTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59';
        this.api.getHistoryOrders2('Order', { 'from': ordersFrom, 'to': ordersTo }).then((orders: any) => {
            orders.forEach(order =>{
                if ('undefined' != typeof  order.initialOrderProducts && 0 != order.initialOrderProducts.length){
                    order.productNames = this.mergeOP.mergeNamesByOpPaul(order.initialOrderProducts);
                }
                else{
                    order.productNames = this.mergeOP.mergeNamesByOpPaul(order.orderProducts);
                }
                order.clientName = `${order.client.firstName} ${order.client.surName}`
            })
            this.dataSource = new MatTableDataSource(orders);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.dataSource._updateChangeSubscription();
            this.isLoaded = true;
        }).catch(err => { this.isLoaded = true; });
    }

    ngOnInit(): void {
    }

    edit(order: any): void {
        // const orderProductsToModal = this.getCurrentOrderData(order['id']);
        // this.apiOrderProducts.forEach( op => {
        //     op.name = this.apiProduct.filter(product => product._id === op.product)[0].name;
        // });

        // orderProductsToModal = this.MergeOp.mergeOrderProducts(orderProductsToModal);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.width = '60%';
        dialogConfig.disableClose = false;
        dialogConfig.minHeight = '80%';
        dialogConfig.data = {
            orderProducts: order,
            status: order.status,
            title: 'Vizualizare comanda'
        };
        const dialogRef = this.dialog.open(OrdersHistoryViewOrderComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.searchByDate();
                // this.returnOrderProducts(result);
            }
        });
    }

    // returnOrderProducts(result): void {
    //     const orderProductsToUpdate = [];
    //     const resultOrderProductsIDs = [];
    //     let deleteFlag = false;
    //     if (result.status === 'Comandat') {
    //         deleteFlag = true;
    //     }
    //     if (result.returnOrderProducts === true) {
    //         result.orderProductsToReturn.forEach(orderProductID => {
    //             resultOrderProductsIDs.push(orderProductID._id);
    //             if (!deleteFlag || orderProductID.producedAt !== null) {
    //                 orderProductsToUpdate.push({
    //                     'id': orderProductID._id,
    //                     'order': null,
    //                 });
    //             }
    //             this.renderedOrders[result.order._id].orderProductObjects.splice(
    //                 this.renderedOrders[result.order._id].orderProductObjects.findIndex(op => op._id.toString() === orderProductID._id.toString()), 1);
    //             this.renderedOrders[result.order._id].orderProducts.splice(
    //                 this.renderedOrders[result.order._id].orderProducts.findIndex(op => op.toString() === orderProductID._id.toString()), 1);
    //         });

    //         if (this.renderedOrders[result.order._id].orderProducts.length === 0) {
    //             this.dataSource.data.splice(this.dataSource.data.findIndex(order => order.orderID === result.order._id), 1);
    //             this.dataSource._updateChangeSubscription();

    //         }

    //         // if (deleteFlag && orderProductsToUpdate.length === 0) {
    //         //     this.api.hardDelete('OrderProduct', result.orderProductsToReturn).then(response => { });

    //         // }
    //         if (orderProductsToUpdate.length > 0) {
    //             this.api.postArray('OrderProduct', orderProductsToUpdate).then(OrderProductUpdate => { });
    //         }
    //         this.api.post('Order', { 'id': result.order._id, 'orderProducts': this.renderedOrders[result.order._id].orderProducts }).then(orderUpdated => { });
    //         const invoice = {
    //             invoiceType: 'Invoice_add',
    //             orderProducts: resultOrderProductsIDs,
    //             storno: true
    //         };
    //         const currentOrder = result.order;
    //         this.api.postFile('Invoice', { order: currentOrder, invoice }).then(result => {
    //             // const fileURL = URL.createObjectURL(result);
    //             // window.open(fileURL);
    //         });
    //         let saveDataSource = [];
    //         this.searchByDate();
    //         saveDataSource = this.dataSource.data;
    //         saveDataSource.forEach(order => {
    //             order.productNames = this.mergeOP.mergeNames(order.order.orderProductObjects);
    //         });

    //         this.dataSource = new MatTableDataSource(saveDataSource);
    //         this.dataSource._updateChangeSubscription();
    //         console.log(this.dataSource);
    //     }
    // }
    deleteOrder(order: any): void {
        const orderProductsToUpdate = [];
        order.orderProducts.forEach(orderProduct => {
            orderProductsToUpdate.push(
                orderProduct._id
            );
        });
        const orderToDelete = {
            'returnOrderProducts': true,
            'orderProductsToReturn': orderProductsToUpdate,
            'orderID': order['id']
        };
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '30%',
            data: {
                title: 'Confirmare',
                question: 'Sunteti sigur ca doriti sa efectuati aceasta operatiune?'
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                // this.returnOrderProducts(orderToDelete);
            }
        });
    }
    
    
    onFileSelected(evt): void {
        const fileName = evt.target.files[0].name;
        if(fileName.includes('panda-rasco-srl')){
            this.onFileSelectedSameDay(evt);
        }
        else if (fileName.includes('_RON_')){
            this.onFileSelectedGlsNew(evt);
        }
    }

    onFileSelectedGlsNew(evt): void {
        console.log("gls new");
        this.isLoaded = false;
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) {
            this.snackBar.open('Maxim un fisier permis!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.isLoaded = true;
            return;
        }
        if ('xls' !== evt.target.files[0].name.split('.').pop() && 'xlsx' !== evt.target.files[0].name.split('.').pop()) {
            this.snackBar.open('Tipul fisierului este gresit!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.isLoaded = true;
            return;
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            console.log(ws)
            let startKey = 9;


            const day = parseInt(ws['A6']['h'].substr(26, 2));
            const month = parseInt(ws['A6']['h'].substr(29, 2));
            const year = parseInt(ws['A6']['h'].substr(32, 4));
            const transferDate = new Date(year, month - 1, day);
            console.log(transferDate)

            const xlsArr = [];
            while (true) {
                if (undefined === ws[`B${startKey}`] || '' === ws[`B${startKey}`]['v']) {
                    break;
                }
                else if (ws[`B${startKey}`]['v'] != null) {
                    xlsArr.push(ws[`B${startKey}`]['v']);
                }
                startKey++;
            }
            // console.log(xlsArr)

            this.api.post('markOrderAsDelivered', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                this.api.post('checkForDuplicatesSerialNumbersAndFix', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                    this.searchByDate();
                }).catch(err => {
                    this.isLoaded = true;
                });
            }).catch(err => {
                // this.isLoaded = true;
                this.api.post('checkForDuplicatesSerialNumbersAndFix', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                    this.searchByDate();
                }).catch(err => {
                    this.isLoaded = true;
                });
            });
            //
        };
        evt.target.value = null;
        return;
    }

    onFileSelectedGls(evt): void {
        console.log("gls");
        this.isLoaded = false;
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) {
            this.snackBar.open('Maxim un fisier permis!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.isLoaded = true;
            return;
        }
        if ('xls' !== evt.target.files[0].name.split('.').pop() && 'xlsx' !== evt.target.files[0].name.split('.').pop()) {
            this.snackBar.open('Tipul fisierului este gresit!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.isLoaded = true;
            return;
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            let startKey = 8;
            const xlsObj = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const day = parseInt(xlsObj[5][0].substr(26, 2));
            const month = parseInt(xlsObj[5][0].substr(29, 2));
            const year = parseInt(xlsObj[5][0].substr(32, 4));
            const transferDate = new Date(year, month - 1, day);
            const xlsArr = [];
            while (true) {
                if (undefined === xlsObj[startKey][0]) {
                    break;
                }
                else if (xlsObj[startKey][1] != null) {
                    xlsArr.push(xlsObj[startKey][1]);
                }
                startKey++;
            }

            this.api.post('markOrderAsDelivered', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                this.api.post('checkForDuplicatesSerialNumbersAndFix', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                    this.searchByDate();
                }).catch(err => {
                    this.isLoaded = true;
                });
            }).catch(err => {
                // this.isLoaded = true;
                this.api.post('checkForDuplicatesSerialNumbersAndFix', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                    this.searchByDate();
                }).catch(err => {
                    this.isLoaded = true;
                });
            });
            //
        };
        evt.target.value = null;
        return;
    }

    onFileSelectedSameDay(evt): void {
        console.log("sameday");
        this.isLoaded = false;
        const target: DataTransfer = <DataTransfer>(evt.target);
        if (target.files.length !== 1) {
            this.snackBar.open('Maxim un fisier permis!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.isLoaded = true;
            return;
        }
        if ('xls' !== evt.target.files[0].name.split('.').pop() && 'xlsx' !== evt.target.files[0].name.split('.').pop()) {
            this.snackBar.open('Tipul fisierului este gresit!', 'Inchide', {
                duration: 3000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
            this.isLoaded = true;
            return;
        }
        const reader: FileReader = new FileReader();
        const fileName = evt.target.files[0].name;
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[1];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            let startKey = 1;
            const xlsObj = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const day = parseInt(fileName.split('-')[5].split(".")[0]);
            const month = parseInt(fileName.split('-')[4]) - 1;
            const year = parseInt(fileName.split('-')[3]);
            const transferDate = new Date(year, month, day);
            const xlsArr = [];
            while (true) {
                if (undefined === xlsObj[startKey]) {
                    break;
                }
                else if (xlsObj[startKey][0] != null) {
                    xlsArr.push(xlsObj[startKey][2]);
                }
                startKey++;
            }
            console.log(transferDate)
            console.log(xlsArr)
            if(1200 < xlsArr.length){
                this.snackBar.open('Exportul Sameday are prea multe date!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                this.isLoaded = true;
                return;
            }


            this.api.post('markOrderAsDelivered', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                this.api.post('checkForDuplicatesSerialNumbersAndFix', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                    this.searchByDate();
                }).catch(err => {
                    this.isLoaded = true;
                });
            }).catch(err => {
                // this.isLoaded = true;
                this.api.post('checkForDuplicatesSerialNumbersAndFix', { parcelNumber: xlsArr, transferDate: transferDate }).then(param => {
                    this.searchByDate();
                }).catch(err => {
                    this.isLoaded = true;
                });
            });
            
        };
        evt.target.value = null;
        return;
    }
}

@Component({
    selector: 'confirmDialog',
    templateUrl: 'confirmDialog.html',
})
export class ConfirmDialogComponent {

    constructor(public dialogRef: MatDialogRef<OrdersHistoryViewOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OrderDialogData) {

    }

    confirm(): void {
        this.dialogRef.close(true);
    }
    decline(): void {
        this.dialogRef.close(false);
    }
}

@Component({
    selector: 'ordersHistoryViewOrder',
    templateUrl: 'ordersHistoryViewOrder.html',
    styleUrls: ['./orders.history.component.scss']
})
export class OrdersHistoryViewOrderComponent {
    currentOrderDisplayedColumns = ['checked', 'product', 'color', 'size', 'price'];
    currentOrderDataSource: MatTableDataSource<any>;
    invoicesDataSource: MatTableDataSource<Invoice>;
    invoiceDisplayedColumns = ['serialKey', 'serialNumber', 'date', 'buttons'];
    appendToModal = false;
    localOrder;
    returnState = false;
    transportFeeToggle = new FormControl();
    returnedDate = new FormControl((new Date()).toISOString());
    data
    constructor(
        public dialogRef: MatDialogRef<OrdersHistoryViewOrderComponent>,
        @Inject(MAT_DIALOG_DATA) public info: OrderDialogData,
        public api: ApiService,
        public snackBar: MatSnackBar,
        private router: Router) {
        this.data = info;
        this.localOrder = info.orderProducts;
        console.log(this.localOrder);
        this.initTable();
        this.transportFeeToggle.setValue(true);
    }
    initTable(){
        let tableData = [];
        if (0 == this.localOrder.initialOrderProducts.length){
            console.log(1, this.localOrder.orderProducts);
            tableData = this.localOrder.orderProducts.map(element => {
                element.isOnCurrentOrder = true;
                return element;
            });
        }
        else{
            tableData = this.localOrder.initialOrderProducts.map(element =>{
                const existInBoth = this.localOrder.orderProducts.find(e => {
                    return e._id == element._id
                })
                console.log(existInBoth);
                if (existInBoth){
                    element.isOnCurrentOrder = true;
                }
                else{
                    element.isOnCurrentOrder = false;
                }
                return element;
            });
            console.log(tableData);
        }
        this.currentOrderDataSource = new MatTableDataSource(tableData);

    }
    close(): void {
        const returnedDataFromModal = {
            'returnOrderProducts': false,
        };
        this.dialogRef.close(returnedDataFromModal);
    }
    changeReturnState() {
        this.returnState = !this.returnState;
        this.appendToModal = false;
    }
    returnedItems(): void {
        const orderProductsToReturn = [];
        const allOrderProducts = [];
        const orderProductsToUpdate = [];
        this.currentOrderDataSource.data.forEach(orderProduct => {
            if(orderProduct.isOnCurrentOrder){
                allOrderProducts.push(orderProduct._id);
                if (orderProduct.checked === true) {
                    orderProductsToReturn.push(orderProduct._id);
                    orderProductsToUpdate.push({
                        'id': orderProduct._id,
                        'order': null,
                        'price': orderProduct.product.price
                    });
                }
            }
        });
        // if (typeof this.localOrder.order.clientObject.iBan === 'undefined' || this.localOrder.order.clientObject.iBan.length !== 24) {
        //     this.snackBar.open('Clientul nu are Iban!', 'Inchide', {
        //         duration: 10000,
        //         horizontalPosition: 'end',
        //         verticalPosition: 'top',
        //     });
        //     return;
        // }
        if (orderProductsToReturn.length === 0) {
            this.snackBar.open('Niciun produs selectat!', 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        } else {
            const invoice = {
                invoiceType: 'Invoice_add',
                orderProducts: orderProductsToReturn,
                storno: true,
                transportFeeFlag: this.transportFeeToggle.value,
                returnDate: this.returnedDate.value
            };
            const remainedOrderProducts = allOrderProducts.filter(x => !orderProductsToReturn.includes(x));
            const promissesArray = [];
            promissesArray.push(this.api.post('Order', { 'id': this.localOrder._id, 'orderProducts': remainedOrderProducts }));
            promissesArray.push(this.api.postArray('OrderProduct', orderProductsToUpdate));
            this.localOrder.clientObject = this.localOrder.client;
            delete this.localOrder.orderProducts;
            promissesArray.push(this.api.postFile('Invoice', { order: this.localOrder, invoice }));
            Promise.all(promissesArray).then(response =>{
                console.log(response);
                this.close()
            }, err =>{
                console.log(err);
            })
            // this.api.post('Order', { 'id': this.localOrder.order._id, 'orderProducts': remainedOrderProducts }).then(orderUpdated => {
            //     this.dialogRef.close(true);
            // });

            // const fileURL = URL.createObjectURL(result);
            // window.open(fileURL);


        }


    }

    returnedOrder(): void {
        const orderProductsToReturn = [];
        this.currentOrderDataSource.data.forEach(orderProduct => {
            orderProductsToReturn.push(orderProduct._id);
        });
        const returnedDataFromModal = {
            'returnOrderProducts': true,
            'status': this.data.status,
            'orderProductsToReturn': orderProductsToReturn,
            'orderID': this.currentOrderDataSource.data[0]._id
        };
        this.dialogRef.close(returnedDataFromModal);
    }
    viewProform(): void {
        const invoice = {
            invoiceType: 'Proform',
            awb: false
        };
        this.localOrder.clientObject = this.localOrder.client
        this.api.postFile('Invoice', { order: this.localOrder, invoice }).then(result => {
            const fileURL = URL.createObjectURL(result);
            window.open(fileURL);
        });
    }
    viewInvoice(): void {
        this.appendToModal = true;
        this.returnState = false;
        console.log(this.localOrder.order);
        this.invoicesDataSource = new MatTableDataSource(this.localOrder.invoiceInfo);
        this.invoicesDataSource._updateChangeSubscription();
    }
    downloadInvoice(order: any): void {
        console.log(this.localOrder);
        const invoice = {
            invoiceType: 'Invoice_show',
            orderProducts: order.orderProducts,
            storno: false,
            serialKey: order.serialKey,
            serialNumber: order.serialNumber
        };
        this.localOrder.clientObject = this.localOrder.client
        this.api.postFile('Invoice', { order: this.localOrder, invoice }).then(result => {
            const fileURL = URL.createObjectURL(result);
            window.open(fileURL);
        });
    }
    changeProducts(): void {
        // if (typeof this.localOrder.order.clientObject.iBan === 'undefined' || this.localOrder.order.clientObject.iBan.length !== 24) {
        //     this.snackBar.open('Clientul nu are Iban!', 'Inchide', {
        //         duration: 10000,
        //         horizontalPosition: 'end',
        //         verticalPosition: 'top',
        //     });
        // } else {
        // let noProductsFlag = true;
        console.log(this.localOrder);
        const obj = {
            'orderProducts': [],
            'clientID': this.localOrder.client._id,
            'transportFeeFlag': this.transportFeeToggle.value
        };
        this.currentOrderDataSource.data.forEach(elem => {
            if (typeof elem.checked !== 'undefined') {
                if (elem.checked === true) {
                    obj.orderProducts.push(elem);
                }
            }
        });
        if (0 === obj.orderProducts.length) {
            this.snackBar.open('Niciun produs selectat!', 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        }
        else {
            this.dialogRef.close();
            localStorage.setItem('changeProducts', JSON.stringify(obj));
            this.router.navigate(['/order/define']);
        }
        // this.currentOrderDataSource.data.forEach(elem => {
        //     if (typeof elem.checked !== 'undefined') {
        //         if (elem.checked === true) {

        //             noProductsFlag = false;
        //         }
        //     }
        // });
        // if (noProductsFlag) {
        //     this.snackBar.open('Niciun produs selectat!', 'Inchide', {
        //         duration: 10000,
        //         horizontalPosition: 'end',
        //         verticalPosition: 'top',
        //     });
        // } else {
        //     const obj = {
        //         'orderProducts': this.currentOrderDataSource.data,
        //         'clientID': this.localOrder.order.client,
        //         'transportFeeFlag': this.transportFeeToggle.value
        //     };
        //     console.log(obj);
        //     this.dialogRef.close();
        //     localStorage.setItem('changeProducts', JSON.stringify(obj));
        //     this.router.navigate(['/order/define']);
        // }


    }
}


export interface UserData {
    id: string;
    productId: string;
    quantity: string;
    dimension: string;
    checked: boolean;
    orderID: boolean;
    _id: string;
    order: string;
}
export interface Invoice {
    serialKey: string;
    serialNumber: string;
    date: string;
}
export interface OrderDialogData {
    name: string;
    color: string;
    size: string;
    orderProducts: any;
    status: string;
    order: any;
}