import { Component, Inject, ViewChild, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MatTableModule } from '@angular/material';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
    selector: 'OrdersViewComponent',
    templateUrl: './orders.view.component.html',
    styleUrls: ['./orders.view.component.scss']
})

export class OrdersViewComponent implements OnInit {
    orderObject;
    orderDetails = [];
    orderProductsDetails = [];
    allUsers = {};
    theOrder: any = undefined;
    isAvailable: Boolean = false;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private activeRoute: ActivatedRoute,
        public api: ApiService,
        public dialog: MatDialog,
        private router: Router,
    ) {
        this.theOrder = JSON.parse(localStorage.getItem('viewFullOrder'));
        console.log(this.theOrder);

    }
    objectKeys(object): Array<String> {
        return Object.keys(object);
    }
    ngOnInit(): void {
        if (typeof localStorage.viewFullOrder !== 'undefined') {
            this.api.getAll('User').then((allUsers: any) => {
                allUsers.forEach(user => {
                    this.allUsers[user._id] = user;
                });
                this.isAvailable = true;
            });
        }
    }
}



