import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatTableModule, MatDialog, MatDialogConfig } from '@angular/material';
// import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api/api.service';
import { DialogDataComponent } from '../../../GenericDialog/dialogData.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { EmagOfferComponent } from '../emag-offer/emag-offer.component';
import { EanModalComponent } from '../ean-modal/ean-modal.component';

import { AuthService } from '../../../authGuard/auth.service';

@Component({
    selector: 'ProductsLibraryComponent',
    templateUrl: './products.library.component.html',
    styleUrls: ['./products.library.component.scss']
})
export class ProductsLibraryComponent {

    displayedColumns = ['name', 'color', 'size', 'length', 'location', 'productionPoint', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public ProductionPointList: any = [];
    characteristicTypesList: any;
    public characteristicsList: any = [];
    enableDataSource = false;
    authEmail = null;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */


    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
        public api: ApiService,
        public dialog: MatDialog,
        public auth: AuthService,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        if (auth.isAuthenticated()) {
            this.authEmail = auth.getuser()['email'];
        }
        this.api.getAll('ProductionPoint').then(params => {

            for (const ProductionPoint of <any>params) {
                this.ProductionPointList[ProductionPoint._id] = {
                    'name': ProductionPoint.name
                };
            }
        });
        this.api.getAll('CharacteristicType').then(param2 => {
            this.characteristicTypesList = param2;
            this.api.getAll('Characteristic').then(param => {
                for (const characteristic of <any>param) {
                    this.characteristicsList[characteristic._id] = {
                        'value': characteristic.value,
                        'type': this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name
                    };
                }
                this.api.getAll('Product').then((param: any) => {
                    const productsToModal = [];
                    param.forEach(element => {
                        productsToModal.push({
                            'name': element.name,
                            'characteristics': element.characteristics,
                            'location': element.productRow + ', ' + element.productColumn,
                            'productionPoint': element.productionPoint,
                            'id': element._id,
                            'imagesUrl': element.imagesUrl,
                            'description': element.description
                        });
                    });
                    const products: UserData[] = productsToModal;
                    this.dataSource = new MatTableDataSource(products);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;

                });
            });
        });
    }

    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    edit(id: number): void {
        this.router.navigate(['product/define', id]);
    }

    offers(row){
        const dialogRef = this.dialog.open(EmagOfferComponent, {
          width: '90%',
          height: 'auto',
          data: row
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          
        });
    }
    ean(row){
        const dialogRef = this.dialog.open(EanModalComponent, {
            width: '90%',
            height: '90%',
            data: row
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log(result);

        });
    }

    view(id: number): void {
        this.api.getOne('Product', { 'id': id }).then(param => {

            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.ariaLabel = 'Produs';
            dialogConfig.width = '50%';
            let productSizes: any = [];
            let productColors: any = [];
            let productStyle: any = [];
            let productLength: any = {}
            let productModel: any = {}
            // for (const characteristic of this.colors){
            //     productSizes.push(characteristic.name);
            // }
            // for (const characteristic of this.sizes){
            //     productColors.push(characteristic.name);
            // }
            for (const characteristic of param['characteristics']) {
                if (this.characteristicsList[characteristic].type === 'color') {
                    productColors.push(this.characteristicsList[characteristic].value);
                }
                else if (this.characteristicsList[characteristic].type === 'size') {
                    productSizes.push(this.characteristicsList[characteristic].value);
                }
                else if (this.characteristicsList[characteristic].type === 'length') {
                    productLength = this.characteristicsList[characteristic];
                }
                else if (this.characteristicsList[characteristic].type === 'model') {
                    productModel = this.characteristicsList[characteristic];
                }
                else if (this.characteristicsList[characteristic].type === 'style') {
                    productStyle.push(this.characteristicsList[characteristic].value);
                }
                
            }
            dialogConfig.data = {
                'id': id,
                'Nume': param['name'],
                'Culori': productColors.join(', '),
                'Marimi': productSizes.join(', '),
                'Lungime': productLength.value? productLength.value : '-',
                'Model': productModel.value? productModel.value : '-',
                'Stil': 0 != productStyle.length ? productStyle.join(', '): '-',
                'Punct de vanzare': this.ProductionPointList[param['productionPoint']].name,
                'Pret': param['price'] + ' lei',
                'title': 'Produs'
            };

            this.dialog.open(DialogDataComponent, dialogConfig);

        });
    }
    delete(row: UserData) {
        this.api.delete('Product', row['_id'].toString()).then(() => {
            this.dataSource.data.splice(this.dataSource.data.indexOf(row), 1);
            this.dataSource._updateChangeSubscription();
        }).catch(() => {
        });
    }
}

export interface UserData {
    name: string;
    size: string;
    color: string;
    location: string;
    defaultOption: string;
}
