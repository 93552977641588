import { Component, Inject, OnInit } from '@angular/core';
// import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { ApiService } from '../../../services/api/api.service';
// import * as moment from 'moment'
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';



@Component({
  selector: 'app-single-order-modal.component',
  templateUrl: './single-order-modal.component.html',
  styleUrls: ['./single-order-modal.component.scss']
})



export class SinglePrderModalComponent implements OnInit {
  public offerForm: FormGroup;
  displayedColumns = [];
  dataSource = new MatTableDataSource([]);
  similarProductsFromStock = null;
  displayLoader = false;
  data = null
  selectedOrderProduct = null;
  constructor(
    public dialogRef: MatDialogRef<SinglePrderModalComponent>,
    public fb: FormBuilder,
    public api: ApiService,
    public snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.data = info;
    console.log(this.data._id);
    this.prepareTable();
  }
  
  private prepareTable() {
    this.displayedColumns = ['name', 'size', 'color', 'actions'];
  }
  public submit() {
    this.displayLoader = true;
    console.log(this.dataSource.data);
    this.api.obsGet(this.api.obsPost(`product_instance?productId=${this.data.id}`, { productInstances: this.dataSource.data}).subscribe((res: any) => {
      console.log(res);
      this.displayLoader = false;
    }))
  }

  public closeDialog() {
    this.dialogRef.close();
  }
  
  public logger(e) {
    console.log(e);
  }
  public searchExistingInStockOrOtherOrders(row): void {
    this.api.rawPost('orderProducts/getSimilarNotSent', { id: row._id }).then(res => {
      console.log(this.data._id);
      console.log(res);
      this.selectedOrderProduct = row;
      this.similarProductsFromStock = res;
    }).catch(err => {
      console.log(err);
    })
  }
  switchOp(row): void {
    console.log(row);
    this.api.rawPost('orderProducts/switchOpInOrder', { first: row._id, second: this.selectedOrderProduct._id }).then(res => {
      console.log(res);
      this.dialogRef.close({ refresh: true });
    }).catch(err => {
      console.log(err);
    })
  }
  seeFullOrder(): void {
    localStorage.removeItem('viewFullOrder');
    localStorage.setItem('viewFullOrder', JSON.stringify(this.data));
    this.router.navigate(['order/view']);
    this.dialogRef.close();
  }
  close(): void {
    this.dialogRef.close({ refresh: false });
  }

  public valueChange(event, row){
    row.ean = event.srcElement.value;
  }
  private populateTable() {
      this.dataSource.data = this.data.orderProducts;
      this.dataSource._updateChangeSubscription();
  }
  ngOnInit(): void {
    this.populateTable();
  }
}