import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from '../../../services/api/api.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';



@Component({
  selector: 'app-order-files-modal.component',
  templateUrl: './order-files-modal.component.html',
  styleUrls: ['./order-files-modal.component.scss']
})



export class OrderFilesModalComponent implements OnInit {
  data = null
  selectedOrderProduct = null;
  public buttons = [];
  constructor(
    public dialogRef: MatDialogRef<OrderFilesModalComponent>,
    public fb: FormBuilder,
    public api: ApiService,
    public snackBar: MatSnackBar,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.data = info;
    console.log(this.data);
  }
  
  public submit() {
    
  }

  public closeDialog() {
    this.dialogRef.close();
  }
  
  public logger(e) {
    console.log(e);
  }
  
  close(): void {
    this.dialogRef.close({ refresh: false });
  }
  

  private prepareButtons(){
    const baseUrl = `${this.api.getBaseUrl()}/public`;
    if (this.data.awbUrl){
      this.buttons.push({
        name: `AWB`,
        url: `${baseUrl}/${this.data.awbUrl}`,
      })
    }
    if (this.data.proformInfo && this.data.proformInfo.url) {
      this.buttons.push({
        name: `PROFORMA ${this.data.proformInfo.serialKey}${this.data.proformInfo.serialNumber}`,
        url: `${this.data.proformInfo.url}`,
      })
    }
    this.data.invoiceInfo.forEach(element => {
      if (element.url){
        this.buttons.push({
          name: `FACTURA ${element.serialKey}${element.serialNumber}`,
          url: `${element.url}`,
        })
      }
    });
  }
  
  ngOnInit(): void {
    this.prepareButtons()
  }
}