import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { InvoiceDetailsDefineComponent } from './define/invoiceDetails.define.component';
import { InvoiceDetailsLibraryComponent } from './library/invoiceDetails.library.component';

import { NgMatModule }  from 'app/angular-material-module/angular-material-module.module';
const routes = [
    {
        path     : 'invoiceDetails/define',
        component: InvoiceDetailsDefineComponent
    },
    {
        path     : 'invoiceDetails/define/:id',
        component: InvoiceDetailsDefineComponent
    },
    {
        path     : 'invoiceDetails/library',
        component: InvoiceDetailsLibraryComponent
    }
];

@NgModule({
    declarations: [
        InvoiceDetailsDefineComponent,
        InvoiceDetailsLibraryComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports     : [
        InvoiceDetailsDefineComponent,
        InvoiceDetailsLibraryComponent
    ]
})

export class InvoiceDetailsModule
{
}
