import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, Form, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';

@Component({
    selector: 'InternalOrderComponent',
    templateUrl: './orders.internal.component.html',
    styleUrls: ['./orders.internal.component.scss']
})

export class InternalOrderComponent implements OnInit {
    dataSource: MatTableDataSource<Products>;
    displayedColumns = ['name', 'color', 'size', 'details', 'quantity', 'actions'];
    productForm: FormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public filterControlProducts = new FormControl();
    public filteredProducts: any;
    selectedProductSizes: any = [];
    selectedProductColors: any = [];
    colorForm = new FormControl();
    sizeForm = new FormControl();
    colorOptions: any = [];
    sizeOptions: any = [];
    characteristicTypesList: any;
    public products: any = [];
    public characteristicsList: any = [];
    addedProducts = [];
    constructor(private fb: FormBuilder,
        private api: ApiService,
        public snackBar: MatSnackBar) {
        this.productForm = fb.group({
            'details': null,
        });
    }
    ngOnInit(): void {
        this.api.getAll('CharacteristicType').then(characteristicTypes => {
            this.characteristicTypesList = characteristicTypes;
            this.api.getAll('Characteristic').then(param => {
                for (const characteristic of <any>param) {
                    this.characteristicsList[characteristic._id] = {
                        'value': characteristic.value,
                        'type': this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name
                    };
                    if (this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name === 'color') {
                        this.colorOptions[characteristic._id] = {
                            'value': characteristic.value
                        };
                    }
                    else {
                        this.sizeOptions[characteristic._id] = {
                            'value': characteristic.value
                        };
                    }
                }
                this.api.getAll('Product').then(products => {
                    this.products = products;
                });
            });
        });
        this.filterControlProducts.valueChanges
            .subscribe(query => {
                console.log(query);
                if (query != null && typeof query == 'object') {
                    this.selectedProductSizes = [];
                    this.selectedProductColors = [];
                    for (const characteristic of query.characteristics) {
                        if (this.sizeOptions[characteristic]) {
                            this.selectedProductSizes.push({
                                'value': this.sizeOptions[characteristic].value,
                                'id': characteristic
                            });
                        }
                        if (this.colorOptions[characteristic]) {
                            this.selectedProductColors.push({
                                'value': this.colorOptions[characteristic].value,
                                'id': characteristic
                            });
                        }
                    }
                }
                if (query !== null && query.length >= 0) {
                    this.filteredProducts = this.products.filter((product) => {
                        return product.name.toLowerCase().search(query.toLowerCase()) !== -1;
                    });
                }
            });
    }
    displayFn(val: any) {
        return val ? val.name : val;
    }
    separateOrderProductsQty1(product): any {
        const orderProductsToCreateWithQuantity = [];
        let counter = product.quantity;
        while (counter >= 1) {
            orderProductsToCreateWithQuantity.push({
                'product': product.product,
                'details': product.details,
                'characteristics': [product.characteristics[0], product.characteristics[1]],
                'price': product.price,
            });
            counter--;
        }
        return orderProductsToCreateWithQuantity;
    }
    addProduct(): void {
        const objectToAdd = {
            'product': this.filterControlProducts.value._id,
            'name': this.filterControlProducts.value.name,
            'details': this.productForm.controls['details'].value,
            'characteristics': [this.selectedProductColors.find(elem => elem.value == this.colorForm.value).id,
            this.selectedProductSizes.find(elem => elem.value == this.sizeForm.value).id],
            'price': this.filterControlProducts.value.price,
            'quantity': 1
        };
        this.addedProducts.push(objectToAdd);
        this.dataSource = new MatTableDataSource(this.addedProducts);
        this.sizeForm.reset();
        this.colorForm.reset();
        this.selectedProductSizes = [];
        this.selectedProductColors = [];
        this.productForm.reset();
        this.filterControlProducts.reset();
        this.filteredProducts = [];
    }
    deleteProduct(row): void {
        this.addedProducts.splice(this.addedProducts.indexOf(row), 1);
        this.dataSource.data = this.addedProducts;
        this.dataSource._updateChangeSubscription();
    }
    submitOrder(): void {
        let orderProductsToAdd = [];
        let quantityFlag = false;
        this.dataSource.data.forEach(product => {
            if (product.quantity <= 0) {
                this.snackBar.open('Cantitati obligatorii mai mari ca 0!', 'Inchide', {
                    duration: 10000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                quantityFlag = true;
            }
            if (product.quantity > 1) {
                orderProductsToAdd = orderProductsToAdd.concat(this.separateOrderProductsQty1(product));
            }
            else {
                orderProductsToAdd.push(product);
            }
        });
        if (!quantityFlag) {
            console.log(orderProductsToAdd);
            this.api.post('orderInternTransaction', { 'orderProducts': orderProductsToAdd }).then(resp => {
                console.log(resp);
                this.dataSource = new MatTableDataSource([]);
                this.addedProducts = [];
            }).catch(err => { console.log(err); });
        }
    }
    disableSubmit(): boolean {
        if (this.sizeForm.value && this.colorForm.value) {
            return false;
        }
        return true;
    }
}
export interface Products {
    product: string;
    name: string;
    color: string;
    size: string;
    quantity: number;
    dimension: string;
    // defaultOption: string;
}
