import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';


import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';


@Component({
    selector: 'InvoicesDefineComponent',
    templateUrl: './invoices.define.component.html',
    styleUrls: ['./invoices.define.component.scss']
})
export class InvoicesDefineComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    invoiceForm: FormGroup;
    editFields: any;
    activeFalse: boolean;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.activeRoute.params.subscribe(params => {
            this.editFields = params;
        });
        this.invoiceForm = fb.group({
            'serialKey': null,
            'serialNumber': 1,
            'reservedNumbers': null,
            'isActive': null
        });
        if (this.editFields.id) {
            this.api.getOne('InvoiceDetailSet', { 'id': this.editFields.id }).then(param => {

                this.invoiceForm = fb.group({
                    'serialKey': param['serialKey'],
                    'serialNumber': param['serialNumber'],
                    'reservedNumbers': param['reservedNumbers'] ? param['reservedNumbers'].join() : param['reservedNumbers'],
                    'id': param['_id']
                });
            });
        }
    }
    onSubmit(): void {
        const strReservedNumbers = this.invoiceForm.value.reservedNumbers;
        this.invoiceForm.value.serialNumber = this.invoiceForm.value.serialNumber - 1;
        if (this.invoiceForm.value.reservedNumbers) {

            this.invoiceForm.value.reservedNumbers = strReservedNumbers.split(',');
        }
        this.api.post('InvoiceDetailSet', this.invoiceForm.value).then(param => {
            this.router.navigate(['invoice/library']);
        });

    }

    disableSubmit(): boolean {
        if (this.invoiceForm.value.serialNumber && this.invoiceForm.value.serialKey) {

            return false;
        }
        return true;
    }

    ngOnInit(): void {


    }

}
