import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiService } from '../../services/api/api.service'


import * as jwtDecode from 'jwt-decode';


@Injectable()
export class AuthService {

    constructor(
        private http: HttpClient,
        private api: ApiService
    ) { }
    login(params: object) {
        let path = '/users/autenticate';
        path = this.api.getBaseUrl() + path;
        const promise = new Promise((resolve, reject) => {
            this.http.post(path, params).subscribe(result => {
                resolve(result);
            }, err => {
                reject();
            });
        });
        return promise;
    }
    public isAuthenticated(): boolean {
        return localStorage.getItem('authToken') ? true : false;
    }

    public getToken() {
        return localStorage.getItem('authToken');
    }

    public getuser() {
        const result = jwtDecode(localStorage.getItem('authToken'));
        return result;
    }

    public getRoles(): Array<String> {
        if (localStorage.getItem('authToken')) {
            if (null != this.getJWTToken()) {
                return this.getJWTToken()['role'];
            }
        }
        else {
            return [];
        }
    }
    public getJWTToken(): Object {
        if (this.isValid()) {
            const token = jwtDecode(localStorage.getItem('authToken'));
            return token;
        }
        else {
            return null;
        }
    }

    public isValid(): boolean {
        if (null === localStorage.getItem('authToken')) return false;
        const token = jwtDecode(localStorage.getItem('authToken'));
        //console.log(token.exp, Math.floor(Date.now() / 1000));
        if (token.exp < Math.floor(Date.now() / 1000)) {
            localStorage.removeItem('authToken');
            return false
        }
        return true;
    }

}