import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ApiService } from '../../../services/api/api.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';



@Component({
  selector: 'app-client-orders-modal.component',
  templateUrl: './client-orders-modal.component.html',
  styleUrls: ['./client-orders-modal.component.scss']
})



export class ClientOrdersModalComponent implements OnInit {
  client = null;
  orders = [];
  displayLoader = true;
  constructor(
    public dialogRef: MatDialogRef<ClientOrdersModalComponent>,
    public api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.client = data.client;
    console.log(this.client);
  }
  
  public submit() {
    
  }

  public closeDialog() {
    this.dialogRef.close();
  }
  
  
  close(): void {
    this.dialogRef.close();
  }
  

  private getOrdersByClient(){
    this.api.obsGet(`orders/getByUser/${this.client._id}`).subscribe((response: any) =>{
      console.log(response)
      this.displayLoader = false;
      this.orders = response;
    }, err =>{
      console.log(err);
    })
  }
  
  ngOnInit(): void {
    this.getOrdersByClient()
  }
}