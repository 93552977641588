import { Injectable } from '@angular/core';
import { Router, NavigationEnd, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
const usersModulesAccess = {
  'operator': ['/order/library', '/order/define', '/product/define', '/product/library', '/client/define', '/client/library'],
  'production': ['/production/daily', '/production/history'],
  'sender': ['/order/library', '/stocks/define', '/stocks/library'],
  'operatorCallCenter': ['/order/library', '/order/define', '/order/history', '/product/define', '/product/library', '/client/define', '/client/library', '/reportsTable']
}

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(): boolean {
    const userRole = this.auth.getRoles();
    const subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ('admin' == userRole.toString()) {
          subscription.unsubscribe();
          return true
        }
        else if ('/dashboard' == event.url) {
          subscription.unsubscribe();
          return true;
        }
        else if ('/logout' == event.url) {
          subscription.unsubscribe();
          this.router.navigate(['auth/login']);
          return false;
        }
        else if (!usersModulesAccess[userRole.toString()].includes(event.url)) {
          subscription.unsubscribe();
          this.router.navigate(['auth/login']);
          return false;
        }
        subscription.unsubscribe();
      }
    });
    if (!this.auth.isAuthenticated() || userRole == []) {
      subscription.unsubscribe();
      this.router.navigate(['auth/login']);
      return false;
    } else
      return true;
  }
}