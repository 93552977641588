import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource, MatTableModule } from '@angular/material';

import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api/api.service';
import { DialogDataComponent } from '../../../GenericDialog/dialogData.component';
// import {MatTableModule} from '@angular/material';

declare function require(url: string);


@Component({
    selector: 'ProductionPointsLibraryComponent',
    templateUrl: './productionPoints.library.component.html',
    styleUrls: ['./productionPoints.library.component.scss']
})
export class ProductionPointsLibraryComponent {
    displayedColumns = ['name', 'address', 'invoiceDetails', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public invoiceDetails: any = [];

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
        public api: ApiService,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.api.getAll('ProductionPoint').then(param => {
            this.api.getAll('invoiceDetail').then(params => {

                for (const invoiceDetail of <any>params) {
                    this.invoiceDetails[invoiceDetail._id] = {
                        'name': invoiceDetail.name
                    }
                };
                const productionPointsDummy: UserData[] = <any>param;
                this.dataSource = new MatTableDataSource(productionPointsDummy);

            });
        });
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    edit(id: number) {
        this.router.navigate(['productionPoint/define', id]);
    }

    delete(row: UserData) {
        this.api.delete('ProductionPoint', row['_id'].toString()).then(() => {
            this.dataSource.data.splice(this.dataSource.data.indexOf(row), 1);
            this.dataSource._updateChangeSubscription();
        }).catch(() => {
        });
    }

    view(id: object): void {

        this.api.getOne('productionPoint', { 'id': id }).then(param => {
            this.api.getOne('invoiceDetail', { 'id': param['invoiceDetail'] }).then(invoiceDetail => {
                const dialogConfig = new MatDialogConfig();

                dialogConfig.disableClose = false;
                dialogConfig.autoFocus = true;
                dialogConfig.ariaLabel = 'Punct de vanzare';
                dialogConfig.width = '50%';

                dialogConfig.data = {
                    'id': id,
                    'Nume': param['name'],
                    'Adresa': param['address'],
                    'title': 'Punct de vanzare',
                    'Informatii facturare': invoiceDetail['name'],
                };

                this.dialog.open(DialogDataComponent, dialogConfig);
            });


        });

    }
}

export interface UserData {
    name: string;
    address: string;
}
