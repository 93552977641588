import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { UsersLibraryComponent } from './library/users.library.component';
import { UserDefineComponent } from './define/user.define.component';
import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { AuthGuardService as AuthGuard } from '../../authGuard/auth-guard.services';
const routes = [
    {
        path: 'user/define',
        component: UserDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'user/define/:id',
        component: UserDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'user/library',
        component: UsersLibraryComponent, canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        UsersLibraryComponent,
        UserDefineComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports: [
        UsersLibraryComponent,
        UserDefineComponent
    ]
})

export class UsersModule {
}
