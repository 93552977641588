import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ApiService } from 'app/main/services/api/api.service';
import { MatSort, MatPaginator, MatTableDataSource } from '@angular/material';
import { MergeOPsService } from 'app/main/services/mergeOPs/mergeOPs.service';
import { PdfMakeService } from 'app/main/services/pdfMakeService/pdfmake.service';

// let pdfmake = require('pdfmake');
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-reports-component',
  templateUrl: './reports-component.component.html',
  styleUrls: ['./reports-component.component.scss']
})
export class ReportsComponentComponent implements OnInit {
  dateFrom = new FormControl((moment(new Date())).subtract(7, 'd').toISOString());
  dateTo = new FormControl((new Date()).toISOString());
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  displayedColumns;
  tableHeaderTitles = {
    client: 'Client',
    products: 'Produse',
    name: 'Denumire',
    color: 'Culoare',
    size: 'Marime',
    price: 'Pret total',
    count: 'Numar produse',
    date: 'Data',
    totalValue: 'Valoare totala',
    transport: 'Pret transport',
    fTransportTotalValue: 'Pret fara transport', 
    company: 'Companie',
    total: 'Total'
  };
  deletedOrders;
  totalOrders;
  totalProducts;
  currentSelection;
  positiveInv;
  negativeInv;
  counterFinancial;
  renderedData;
  public isLoaded = true;
 
  objectData;
  constructor(
    private api: ApiService,
    private changeDetector: ChangeDetectorRef,
    public mergeOP: MergeOPsService,
    public pdfMakeService: PdfMakeService) { }

  ngOnInit() {
  }
  downloadReport(entity: string){
    const orderFrom = moment(this.dateFrom.value).format('YYYY-MM-DD') + 'T00:00';
    const orderTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59';
    this.api.getReportstable( entity, {from: orderFrom, to: orderTo, type: entity}).then( resp => {
        const fileURL = URL.createObjectURL(resp);
        // this.downloadXml('F_' + moment(new Date()).format('DD/MM/YYYY-hh:mm:ss'), resp);
        // const fileURL = URL.createObjectURL(resp);
        // window.open(fileURL);
        // console.log( resp);
    });
}
   searchReport(entity: string): void{
     const orderFrom = moment(this.dateFrom.value).format('YYYY-MM-DD') + 'T00:00';
     const orderTo = moment(this.dateTo.value).format('YYYY-MM-DD') + 'T23:59';
     this.isLoaded = false;
     this.api.getReportstable( entity, {from: orderFrom, to: orderTo, type: entity}).then( (resp: any) => {
       let  modifiedData;
       if ( entity === 'orders'){
        modifiedData = this.renderOrderData(resp);
        this.currentSelection = 'orders';
      } else if ( entity === 'products'){
        this.currentSelection = 'products';
        modifiedData = this.renderProductsData(resp);
      } else if ( entity === 'financial'){
        this.currentSelection = 'financial';
        modifiedData = this.renderFinancialData(resp);
       }
       this.dataSource = new MatTableDataSource(modifiedData);
       this.changeDetector.detectChanges();
       this.dataSource.paginator = this.paginator;
       this.dataSource.sort = this.sort;
       this.dataSource.connect().subscribe(d => this.renderedData = d);
       this.isLoaded = true;
        });

     }
    renderOrderData(populatedData: any): any{
      const returnedOrders = [];
      this.displayedColumns = ['date', 'client', 'products'];
      let deletedOrders = 0;
      populatedData.forEach( order => {
        if (order.deletedAt != null || order.orderProducts.length == 0){
          deletedOrders++;
        }
        // order.orderProducts = order
        const mergedNames = this.mergeOP.reportTableMerge(order.orderProducts);
        returnedOrders.push({
          client: order.client.firstName + ' ' + order.client.surName,
          date: moment(order.createdAt).format('DD-MM-YYYY'),
          products: mergedNames
        });
      });
      this.deletedOrders = deletedOrders;
      this.totalOrders = populatedData.length;
      return returnedOrders;
    }
    renderProductsData(populatedData: any): any{
      const returnedProducts = [];
      this.displayedColumns = ['name', 'color', 'size', 'price', 'count'];
      let countProducts = 0;
      populatedData.forEach( product => {
        returnedProducts.push({
          name: product._id.product.name,
          color: product._id.characteristics[0].value,
          size: product._id.characteristics[1].value,
          price: product._id.price * product.count,
          count: product.count
        });
        countProducts += product.count;
      });
      this.totalProducts = countProducts;
      return returnedProducts;
    }
    renderFinancialData(populatedData: any): any{
      const returnedFinancial = [];
      this.displayedColumns = ['date', 'products', 'totalValue', 'transport', 'fTransportTotalValue', 'company'];
      let negativeInv = 0;
      let positiveInv = 0;
      let counterFinancial = 0;
      for (const order of populatedData){
        // console.log(order);
        order.invoiceInfo.forEach( invInfo => {
          returnedFinancial.push({
            date: moment(invInfo.invoiceDate).format('DD-MM-YYYY'), 
            products: this.mergeOP.reportTableMerge(invInfo.orderProducts),
            totalValue: invInfo.totalPrice,
            transport: invInfo.transportFee,
            fTransportTotalValue: invInfo.totalPrice + invInfo.transportFee,
            company: order.invoiceDetails.name 
          });
          if (invInfo.totalPrice > 0){
            positiveInv += invInfo.totalPrice;
          } else {
            negativeInv += invInfo.totalPrice;
          }
          counterFinancial++;
        });
        this.positiveInv = positiveInv;
        this.negativeInv = negativeInv;
        this.counterFinancial = counterFinancial;
      }
      return returnedFinancial;
    }
    generatePDFMake(): any{
      const headersText = [];
      let title;
      let subTitle;
      switch (this.currentSelection){
        case 'orders':
            title = 'Raport comenzi';
            subTitle =  'Total comenzi: ' + this.totalOrders + ' din care ' + this.deletedOrders + '  sunt sterse.';
            break;
        case 'products':
            title = 'Raport produse';
            subTitle = 'Total produse: ' + this.totalProducts;
            break;
        case 'financial' :
            title = 'Raport financiar';
            subTitle = 'Total colete: ' + this.counterFinancial + ', TOTAL valori positive: ' + this.positiveInv + 'TOTAL valori negative: ' + this.negativeInv + ', total: ' + (this.positiveInv + this.negativeInv) ;
            break;
      }
      const objectData = {
        reportTitle: title,
        subTitle: subTitle,
        // body: this.renderedData,
        body: this.dataSource.sortData(this.dataSource.filteredData, this.dataSource.sort),
        headers: this.displayedColumns,
        headersText: headersText,
        from: moment(this.dateFrom.value).format('DD-MM-YYYY'),
        to: moment(this.dateTo.value).format('DD-MM-YYYY'),
      };
      this.displayedColumns.forEach( elem => {
        headersText.push(this.tableHeaderTitles[elem]);
      });
      
      try {
        pdfMake.createPdf(this.pdfMakeService.genericPdfmake(objectData)).open();
      } catch (e) {
        console.log(e);
      }
      
    }
}
