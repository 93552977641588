import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProductionPointsDefineComponent } from './define/productionPoints.define.component';
import { ProductionPointsLibraryComponent } from './library/productionPoints.library.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import {AuthGuardService as AuthGuard} from '../../authGuard/auth-guard.services';

const routes = [
    {
        path     : 'productionPoint/define',
        component: ProductionPointsDefineComponent, canActivate: [AuthGuard]
    },
    {
        path     : 'productionPoint/define/:id',
        component: ProductionPointsDefineComponent, canActivate: [AuthGuard]
    },
    {
        path     : 'productionPoint/library',
        component: ProductionPointsLibraryComponent, canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ProductionPointsDefineComponent,
        ProductionPointsLibraryComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports     : [
        ProductionPointsDefineComponent,
        ProductionPointsLibraryComponent
    ]
})

export class ProductionPointsModule
{
}
