import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';

import { ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, MatTableModule, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api/api.service';
import { DialogDataComponent } from '../../../GenericDialog/dialogData.component';
// import {MatTableModule} from '@angular/material';
//json local db
declare function require(url: string);

let jsonDB = require('../../../db.json');
const db = jsonDB.invoice;

@Component({
    selector: 'InvoiceDetailsLibraryComponent',
    templateUrl: './invoiceDetails.library.component.html',
    styleUrls: ['./invoiceDetails.library.component.scss']
})
export class InvoiceDetailsLibraryComponent {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */

    displayedColumns = ['name', 'detaliiSeriiProforma', 'detaliiSeriiFacturi', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    public proformDetailSetList: {} = {};
    public invoiceDetailSetList: {} = {};

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private router: Router,
        public api: ApiService,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);

        this.api.getAll('proformDetailSet').then(proformDetailSet => {


            // this.proformDetailSet = proformDetailSet;
            for (const item of <any>proformDetailSet) {

                this.proformDetailSetList[item._id] = {
                    'id': item._id,
                    'serialKey': item.serialKey,
                    'serialNumber': item.serialNumber
                };
            }


        }).then(() => {
            this.api.getAll('invoiceDetailSet').then(invoiceDetailSet => {

                for (const item of <any>invoiceDetailSet) {

                    this.invoiceDetailSetList[item._id] = {
                        'id': item._id,
                        'serialKey': item.serialKey,
                        'serialNumber': item.serialNumber
                    };
                }

            });

        }).then(() => {
            this.api.getAll('InvoiceDetail').then(InvoiceDetails => {

                // for (let invoiceDetail in InvoiceDetails) {
                //     // invoiceDetail.detaliiSeriiFacturi = invoiceDetailSet[invoiceDetail.detaliiSeriiFacturi].serialKey;
                //
                // }
                const invoiceDetailsDummy: UserData[] = <any>InvoiceDetails;
                this.dataSource = new MatTableDataSource(invoiceDetailsDummy);
            });
        });
    }

    // ngAfterViewInit() {
    //     this.dataSource.paginator = this.paginator;
    //     this.dataSource.sort = this.sort;
    // }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    edit(id: number): void {
        this.router.navigate(['invoiceDetails/define', id]);
    }
    view(id: number): void {
        this.api.getOne('InvoiceDetail', { 'id': id }).then(param => {

            const dialogConfig = new MatDialogConfig();

            dialogConfig.disableClose = false;
            dialogConfig.autoFocus = true;
            dialogConfig.ariaLabel = 'Detalii factura';
            dialogConfig.width = '50%';

            dialogConfig.data = {
                'id': id,
                'Nume:': param['name'],
                'Iban:': param['bankIban'],
                'Denumire Banca:': param['bankName'],
                'CIF': param['cif'],
                'TVA': param['vat'] + '%',
                'Detalii serii facturi': this.invoiceDetailSetList[param['invoiceDetailSet']].serialKey,
                'Detalii serii proforma': this.proformDetailSetList[param['proformDetailSet']].serialKey,
                'Numar registru comertului': param['registerNumber'],
                'Adresa': param['address'],
                'Cod postal': param['postalCode'],
            };

            this.dialog.open(DialogDataComponent, dialogConfig);

        });
    }
}

export interface UserData {
    name: string;
    registerNumber: string;
    cif: string;
    address: string;
    bankIban: string;
    bankName: string;
    detaliiSeriiFacturi: string;
    detaliiSeriiProforma: string;


}
