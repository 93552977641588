import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { InvoicesDefineComponent } from './define/invoices.define.component';
import { InvoicesLibraryComponent } from './library/invoices.library.component';

import { NgMatModule }  from 'app/angular-material-module/angular-material-module.module';
const routes = [
    {
        path     : 'invoice/define',
        component: InvoicesDefineComponent
    },
    {
        path     : 'invoice/define/:id',
        component: InvoicesDefineComponent
    },
    {
        path     : 'invoice/library',
        component: InvoicesLibraryComponent
    }
];

@NgModule({
    declarations: [
        InvoicesDefineComponent,
        InvoicesLibraryComponent
    ],
    imports     : [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports     : [
        InvoicesDefineComponent,
        InvoicesLibraryComponent
    ]
})

export class InvoicesModule
{
}
