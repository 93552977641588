import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { InvoicesPDefineComponent } from './define/invoicesP.define.component';
import { InvoicesPLibraryComponent } from './library/invoicesP.library.component';

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
const routes = [
    {
        path: 'invoiceP/define',
        component: InvoicesPDefineComponent
    },
    {
        path: 'invoiceP/define/:id',
        component: InvoicesPDefineComponent
    },
    {
        path: 'invoiceP/library',
        component: InvoicesPLibraryComponent
    }
];

@NgModule({
    declarations: [
        InvoicesPDefineComponent,
        InvoicesPLibraryComponent
    ],
    imports: [
        RouterModule.forChild(routes),

        TranslateModule,

        FuseSharedModule,
        NgMatModule
    ],
    exports: [
        InvoicesPDefineComponent,
        InvoicesPLibraryComponent
    ]
})

export class InvoicesPModule {
}
