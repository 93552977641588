import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';
import { MatChipInputEvent, MatDialogConfig, MatDialogRef, MatAutocompleteSelectedEvent, MatSelect, MatOptionSelectionChange } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { UserData } from '../../../business-intelligence/invoices-p/library/invoicesP.library.component';


@Component({
    selector: 'ProductsDefineComponent',
    templateUrl: './products.define.component.html',
    styleUrls: ['./products.define.component.scss']
})
export class ProductsDefineComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    editFields: any;
    productsForm: FormGroup;
    productionPointsList: any = [];
    defaultOption: any;
    productionPointCtrl: FormGroup;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    colorForm: FormGroup;
    sizeForm: FormGroup;
    colors: any;
    sizes: any;
    characteristicsList: any = [];
    characteristicTypesList: any;
    productCharacteristics: any = [];
    colorOptions: any = [];
    sizeCtrl = new FormControl();
    colorCtrl = new FormControl();
    sizeOptions: any = [];
    filteredSizes: Observable<string[]>;
    filteredColors: Observable<string[]>;
    lengthOptions: any = [];
    styleOptions: any = [];
    modelOptions: any = [];
    autocompleteSizes: string[] = [];
    addOnBlur = false;
    productRow: any = [];
    productColumn: any = [];
    productRowLimit: any;
    productColumnLimit: any;
    @ViewChild('sizeInput') sizeInput: ElementRef;
    @ViewChild('colorInput') colorInput: ElementRef;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router,
        public dialog: MatDialog
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.productRow = Array.from(Array(15)).map((e, i) => i + 1);
        this.productColumn = Array.from(Array(15)).map((e, i) => i + 1);

        this.activeRoute.params.subscribe(params => {
            this.editFields = params;
        });

        this.api.getAll('ProductionPoint').then(param => {
            // for (const productionPoint of <any>param){
            //     this.productionPointsList[productionPoint._id] = {
            //         'name': productionPoint.name
            //     };
            // }
            this.productionPointsList = param;
            this.productRowLimit = this.productionPointsList[0].rowLimit;
            this.productColumnLimit = this.productionPointsList[0].columnLimit;
            this.productsForm.controls['productionPoint'].setValue(this.productionPointsList[0].name);
            this.productRow = Array.from(Array(this.productionPointsList[0].rowLimit)).map((e, i) => i + 1);
            this.productColumn = Array.from(Array(this.productionPointsList[0].columnLimit)).map((e, i) => i + 1);
        });
        const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        this.productsForm = fb.group({
            'name': null,
            'size': null,
            'color': null,
            'length': null,
            'style': null,
            'model': null,
            'details': null,
            'productionPoint': null,
            'price': null,
            'productRow': null,
            'productColumn': null,
            'wordpressID': null,
            'description': null,
            'photo1': null,
            'photo2': null,
            'photo3': null,
            'photo4': null,
            'photo5': null,
            'photo6': null,
            'photo7': null,
            'photo8': null,
            'photo9': null,
            'photo10': null,
            'photo11': null,
            'photo12': null,
            'photo13': null,
            'photo14': null,
            'photo15': null,
            'photo16': null,
            'photo17': null,
            'photo18': null,
            'photo19': null,
            'photo20': null,
            'photo21': null,
            'photo22': null,
            'photo23': null,
            'photo24': null,
        });

        this.productsForm.controls['productRow'].setValue(this.productRow[0]);
        this.productsForm.controls['productColumn'].setValue(this.productColumn[0]);

        this.api.getAll('CharacteristicType').then(param2 => {
            this.characteristicTypesList = param2;
            this.api.getAll('Characteristic').then(param => {
                for (const characteristic of <any>param) {
                    this.characteristicsList[characteristic._id] = {
                        'value': characteristic.value,
                        'type': this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name,
                        'id': characteristic._id,
                        '_id': characteristic._id
                    };
                    if (this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name === 'color') {

                        this.colorOptions.push({
                            'value': characteristic.value,
                            'id': characteristic._id
                        });
                    }
                    else if (this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name === 'size') {
                        this.sizeOptions.push({
                            'value': characteristic.value,
                            'id': characteristic._id
                        });
                    }
                    else if(this.characteristicTypesList.find(x => x._id === characteristic.characteristicType).name === 'length'){
                        this.lengthOptions.push(characteristic)
                    }
                    else if(this.characteristicTypesList.find(x => x._id === characteristic.characteristicType).name === 'style'){
                        this.styleOptions.push(characteristic)
                    }
                    else if(this.characteristicTypesList.find(x => x._id === characteristic.characteristicType).name === 'model'){
                        this.modelOptions.push(characteristic)
                    }
                }
                this.productsForm.get('length').setValue(this.lengthOptions[0]);
                
                if (this.editFields.id) {
                    this.api.getOne('Product', { 'id': this.editFields.id }).then(params => {
                        const currentProductionPoint = this.productionPointsList.filter(x => x._id === params['productionPoint'])[0];
                        this.productRow = Array.from(Array(currentProductionPoint.rowLimit)).map((e, i) => i + 1);
                        this.productColumn = Array.from(Array(currentProductionPoint.columnLimit)).map((e, i) => i + 1);

                        this.productsForm = fb.group({
                            'id': params['_id'],
                            'name': params['name'],
                            'productionPoint': currentProductionPoint.name,
                            'price': params['price'],
                            'color': null,
                            'size': null,
                            'length': null,
                            'style': null,
                            'model': null,
                            'productRow': Number(params['productRow']),
                            'productColumn': Number(params['productColumn']),
                            'wordpressID': params['wordpressID'] ? params['wordpressID'] : null,
                            'description': params['description'] ? params['description'] : null,
                            'photo1': params['imagesUrl'] && params['imagesUrl'][0] ? params['imagesUrl'][0] : null,
                            'photo2': params['imagesUrl'] && params['imagesUrl'][1] ? params['imagesUrl'][1] : null,
                            'photo3': params['imagesUrl'] && params['imagesUrl'][2] ? params['imagesUrl'][2] : null,
                            'photo4': params['imagesUrl'] && params['imagesUrl'][3] ? params['imagesUrl'][3] : null,
                            'photo5': params['imagesUrl'] && params['imagesUrl'][4] ? params['imagesUrl'][4] : null,
                            'photo6': params['imagesUrl'] && params['imagesUrl'][5] ? params['imagesUrl'][5] : null,
                            'photo7': params['imagesUrl'] && params['imagesUrl'][6] ? params['imagesUrl'][6] : null,
                            'photo8': params['imagesUrl'] && params['imagesUrl'][7] ? params['imagesUrl'][7] : null,
                            'photo9': params['imagesUrl'] && params['imagesUrl'][8] ? params['imagesUrl'][8] : null,
                            'photo10': params['imagesUrl'] && params['imagesUrl'][9] ? params['imagesUrl'][9] : null,
                            'photo11': params['imagesUrl'] && params['imagesUrl'][10] ? params['imagesUrl'][10] : null,
                            'photo12': params['imagesUrl'] && params['imagesUrl'][11] ? params['imagesUrl'][11] : null,
                            'photo13': params['imagesUrl'] && params['imagesUrl'][12] ? params['imagesUrl'][12] : null,
                            'photo14': params['imagesUrl'] && params['imagesUrl'][13] ? params['imagesUrl'][13] : null,
                            'photo15': params['imagesUrl'] && params['imagesUrl'][14] ? params['imagesUrl'][14] : null,
                            'photo16': params['imagesUrl'] && params['imagesUrl'][15] ? params['imagesUrl'][15] : null,
                            'photo17': params['imagesUrl'] && params['imagesUrl'][16] ? params['imagesUrl'][16] : null,
                            'photo18': params['imagesUrl'] && params['imagesUrl'][17] ? params['imagesUrl'][17] : null,
                            'photo19': params['imagesUrl'] && params['imagesUrl'][18] ? params['imagesUrl'][18] : null,
                            'photo20': params['imagesUrl'] && params['imagesUrl'][19] ? params['imagesUrl'][19] : null,
                            'photo21': params['imagesUrl'] && params['imagesUrl'][20] ? params['imagesUrl'][20] : null,
                            'photo22': params['imagesUrl'] && params['imagesUrl'][21] ? params['imagesUrl'][21] : null,
                            'photo23': params['imagesUrl'] && params['imagesUrl'][22] ? params['imagesUrl'][22] : null,
                            'photo24': params['imagesUrl'] && params['imagesUrl'][23] ? params['imagesUrl'][23] : null,
                        });

                        this.productCharacteristics = params;
                        for (const characteristic of this.productCharacteristics.characteristics) {
                            if (this.characteristicsList[characteristic].type === 'color') {
                                this.colors.push({
                                    'value': this.characteristicsList[characteristic].value,
                                    'id': characteristic,
                                });
                            }
                            else if (this.characteristicsList[characteristic].type === 'size'){
                                this.sizes.push({
                                    'value': this.characteristicsList[characteristic].value,
                                    'id': characteristic,
                                });
                            }
                            else if (this.characteristicsList[characteristic].type === 'length'){
                                this.productsForm.get('length').setValue(this.lengthOptions.find(e => e._id === this.characteristicsList[characteristic].id));
                            }
                            else if (this.characteristicsList[characteristic].type === 'model'){
                                this.productsForm.get('model').setValue(this.modelOptions.find(e => e._id === this.characteristicsList[characteristic].id));
                            }
                            else if (this.characteristicsList[characteristic].type === 'style'){
                                let util = [this.characteristicsList[characteristic]];
                                if(this.productsForm.get('style').value){
                                    util = util.concat(this.productsForm.get('style').value);
                                }
                                this.productsForm.get('style').setValue(util);
                            }
                        }                        
                    });
                }
            });
        });

    }
    logger(e){
        console.log('///////////////////')
        console.log(e);
    }
    limitStocks(event: MatOptionSelectionChange) {
        this.productRow = Array.from(Array(this.productionPointsList.filter(pp => pp.name === event.toString())[0].rowLimit)).map((e, i) => i + 1);
        this.productColumn = Array.from(Array(this.productionPointsList.filter(pp => pp.name === event.toString())[0].columnLimit)).map((e, i) => i + 1);
    }
    compareObjects(o1: any, o2: any) {
        if (o1 && o2 && o1._id == o2._id)
          return true;
        else return false
      }
    compareObjectsArray(o1: any, o2: any){
        if (o1 && o2 && o1._id == o2.id)
          return true;
        else return false
    }
    selected(event: MatAutocompleteSelectedEvent, type: string): void {
        if (type === 'size') {
            this.sizes.push({
                'value': event.option.value.value,
                'id': event.option.value.id
            });
            this.sizeInput.nativeElement.value = '';
            this.sizeCtrl.setValue(null);
        }
        else {
            this.colors.push({
                'value': event.option.value.value,
                'id': event.option.value.id
            });
            this.colorInput.nativeElement.value = '';
            this.colorCtrl.setValue(null);
        }
    }

    private _filter(value: string, type: string): string[] {
        const filterValue = value.toString().toLowerCase();
        if (type === 'size') {
            return this.sizeOptions.filter(size => size.value.toLowerCase().indexOf(filterValue) === 0);

        }
        else {
            return this.colorOptions.filter(size => size.value.toLowerCase().indexOf(filterValue) === 0);

        }
    }

    getArrUrlImages(){
        const arr = [];
        if(this.productsForm.get('photo1').value){
            arr.push(this.productsForm.get('photo1').value);
        }
        if(this.productsForm.get('photo2').value){
            arr.push(this.productsForm.get('photo2').value);
        }
        if(this.productsForm.get('photo3').value){
            arr.push(this.productsForm.get('photo3').value);
        }
        if(this.productsForm.get('photo4').value){
            arr.push(this.productsForm.get('photo4').value);
        }
        if(this.productsForm.get('photo5').value){
            arr.push(this.productsForm.get('photo5').value);
        }
        if (this.productsForm.get('photo6').value) {
            arr.push(this.productsForm.get('photo6').value);
        } 
        if (this.productsForm.get('photo7').value) {
            arr.push(this.productsForm.get('photo7').value);
        } 
        if (this.productsForm.get('photo8').value) {
            arr.push(this.productsForm.get('photo8').value);
        } 
        if (this.productsForm.get('photo9').value) {
            arr.push(this.productsForm.get('photo9').value);
        } 
        if (this.productsForm.get('photo10').value) {
            arr.push(this.productsForm.get('photo10').value);
        } 
        if (this.productsForm.get('photo11').value) {
            arr.push(this.productsForm.get('photo11').value);
        } 
        if (this.productsForm.get('photo12').value) {
            arr.push(this.productsForm.get('photo12').value);
        } 
        if (this.productsForm.get('photo13').value) {
            arr.push(this.productsForm.get('photo13').value);
        } 
        if (this.productsForm.get('photo14').value) {
            arr.push(this.productsForm.get('photo14').value);
        } 
        if (this.productsForm.get('photo15').value) {
            arr.push(this.productsForm.get('photo15').value);
        } 
        if (this.productsForm.get('photo16').value) {
            arr.push(this.productsForm.get('photo16').value);
        } 
        if (this.productsForm.get('photo17').value) {
            arr.push(this.productsForm.get('photo17').value);
        } 
        if (this.productsForm.get('photo18').value) {
            arr.push(this.productsForm.get('photo18').value);
        } 
        if (this.productsForm.get('photo19').value) {
            arr.push(this.productsForm.get('photo19').value);
        } 
        if (this.productsForm.get('photo20').value) {
            arr.push(this.productsForm.get('photo20').value);
        } 
        if (this.productsForm.get('photo21').value) {
            arr.push(this.productsForm.get('photo21').value);
        } 
        if (this.productsForm.get('photo22').value) {
            arr.push(this.productsForm.get('photo22').value);
        } 
        if (this.productsForm.get('photo23').value) {
            arr.push(this.productsForm.get('photo23').value);
        } 
        if (this.productsForm.get('photo24').value) {
            arr.push(this.productsForm.get('photo24').value);
        }
        return arr;
    }

    onSubmit(): void {
        const productionEntity = this.productionPointsList.filter(x => x.name === this.productsForm.controls['productionPoint'].value)[0];
        let characteristicsArray = [];
        for (const characteristic of this.colors) {
            characteristicsArray.push(characteristic.id);
        }
        for (const characteristic of this.sizes) {
            characteristicsArray.push(characteristic.id);
        }
        if (this.productsForm.get('length').value){
            characteristicsArray.push(this.productsForm.get('length').value._id);
        }
        if (this.productsForm.get('model').value){
            characteristicsArray.push(this.productsForm.get('model').value._id);
        }
        characteristicsArray = characteristicsArray.concat(this.productsForm.get('style').value.map(e => e._id))
        
        let Entity:any = {
            'name': this.productsForm.value.name,
            'productionPoint': productionEntity._id,
            'characteristics': characteristicsArray,
            'price': this.productsForm.value.price,
            'productRow': this.productsForm.value.productRow,
            'productColumn': this.productsForm.value.productColumn,
            'wordpressID': this.productsForm.value.wordpressID.trim(),
            'description': this.productsForm.value.description,
            'imagesUrl' : this.getArrUrlImages()
        };
        if (this.editFields.id) {
            Entity.id = this.productsForm.value.id
        }
        console.log(Entity);
        this.api.post('Product', Entity).then(param => {
             if (this.editFields.id){
                 this.api.obsGet(`product_offer/update_instances?productId=${this.editFields.id}`).subscribe(res =>{
                     console.log(res);
                     this.router.navigate(['product/library']);
                 }, err =>{
                     console.warn(err);
                 })
             } else {
                 this.router.navigate(['product/library']);
             }
        });
    }

    disableSubmit(): boolean {
        if (this.productsForm.value.name && this.productsForm.value.price) {

            return false;
        }
        return true;
    }

    ngOnInit(): void {
        // this.activeRoute.params.subscribe(params => console.log(params));
        this.colorForm = this.fb.group({
            colors: []
        });
        this.colors = [];
        this.sizeForm = this.fb.group({ sizes: [] });
        this.sizes = [];
        this.filteredSizes = this.sizeCtrl.valueChanges.pipe(
            startWith(null),
            map((size: string | null) => size ? this._filter(size, 'size') : this.sizeOptions.slice()));
        this.filteredColors = this.colorCtrl.valueChanges.pipe(
            startWith(null),
            map((color: string | null) => color ? this._filter(color, 'color') : this.colorOptions.slice()));
        // this.productsForm.

    }

    /**
     * Add category
     *
     * @param {MatChipInputEvent} event
     */
    addColor(event: MatChipInputEvent, type): void {
        const input = event.input;
        const value = event.value;
        // const currentChip = this.characteristicsList.find(myChip => {
        //     return myChip.value === event.value;
        // });

        const currentChip: any = [];

        for (const chipID in this.characteristicsList) {

            if (this.characteristicsList[chipID].value === event.value) {
                currentChip.push({
                    '_id': chipID
                });
            }
        }
        if (type === 'color') {
            // Add color
            if (value) {
                if (currentChip[0]) {
                    this.colors.push(
                        {
                            'value': value,
                            'id': currentChip[0]._id,
                        });
                }
                else {
                    const characteristicItem = {
                        'characteristicType': this.characteristicTypesList.filter(x => x.name === 'color')[0]._id,
                        'value': value,
                        '_id': null

                    };
                    this.api.post('Characteristic', characteristicItem).then(newCharacteristic => {
                        const characteristicId: any = newCharacteristic;
                        this.colors.push(
                            {
                                'value': value,
                                'id': newCharacteristic['_id'],
                            });
                    });

                }
            }
        }
        else {
            // Add size
            if (value) {
                if (currentChip[0]) {
                    this.sizes.push(
                        {
                            'value': value,
                            'id': currentChip[0]._id
                        });
                }
                else {
                    const characteristicItem = {
                        'characteristicType': this.characteristicTypesList.filter(x => x.name === 'size')[0]._id,
                        'value': value,
                        '_id': null

                    };


                    this.api.post('Characteristic', characteristicItem).then(param => {
                        const characteristicId: any = param;
                        this.sizes.push(
                            {
                                'value': value,
                                'id': param['_id'],
                            });
                    });
                }
            }

        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    /**
     * Remove category
     *
     * @param category
     */
    removeChip(value, type): void {
        if (type === 'color') {
            const index = this.colors.indexOf(value);
            if (index >= 0) {
                this.colors.splice(index, 1);
            }
        }
        else {
            const index = this.sizes.indexOf(value);
            if (index >= 0) {
                this.sizes.splice(index, 1);
            }
        }

    }

    onCipClick(chip, type): void {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '250px';

        dialogConfig.data = {
            chip,
            type
        };
        const dialogRef = this.dialog.open(ProductDialogDataComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(result => {

            if (result === '') {

                return;
            }
            chip.value = result;
            const characteristicUpdate = {
                '_id': chip.id,
                'value': result,
                'characteristicType': this.characteristicTypesList.filter(x => x.name === type)[0]._id,
            };
            this.api.post('Characteristic', characteristicUpdate).then(param => {

            });
        });

    }
}

@Component({
    selector: 'dialog-data',
    templateUrl: 'dialog-data.html',
})
export class ProductDialogDataComponent {
    chipForm: FormGroup;
    public chipController: FormControl = new FormControl();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<ProductDialogDataComponent>,
    ) {
        this.chipForm = fb.group({
            'value': this.data.chip.value,
        });

        this.chipController.setValue(this.data.chip.value);
    }

    close(): void {
        this.dialogRef.close(this.chipController.value);
    }

    save(): void {
        this.dialogRef.close(this.chipController.value);
    }
}
