import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { ProductDialogDataComponent, ProductsDefineComponent } from './define/products.define.component';
import { ProductsLibraryComponent } from './library/products.library.component';
import { EmagOfferComponent } from './emag-offer/emag-offer.component'
import { EanModalComponent } from './ean-modal/ean-modal.component'

import { NgMatModule } from 'app/angular-material-module/angular-material-module.module';
import { MatChipInputEvent, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AuthGuardService as AuthGuard } from '../../authGuard/auth-guard.services';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
const routes = [
    {
        path: 'product/define',
        component: ProductsDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'product/define/:id',
        component: ProductsDefineComponent, canActivate: [AuthGuard]
    },
    {
        path: 'product/library',
        component: ProductsLibraryComponent, canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ProductsDefineComponent,
        ProductsLibraryComponent,
        ProductDialogDataComponent,
        EmagOfferComponent, 
        EanModalComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        TranslateModule,
        FuseSharedModule,
        NgMatModule,
        MatDialogModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        // EmagOfferComponent
    ],
    exports: [
        ProductsDefineComponent,
        ProductsLibraryComponent,
        // EmagOfferComponent
    ],
    entryComponents: [
        EmagOfferComponent,
        EanModalComponent,
        ProductDialogDataComponent
      ],
    // bootstrap: [ProductDialogDataComponent],
    providers: [
        {provide: MAT_DIALOG_DATA, useValue: {}},
        {provide: MatDialogRef, useValue: {}},
    ]
})

export class ProductsModule {
}
