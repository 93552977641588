import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ProductionLibraryComponent} from './dailyProduction/production.library.component';
import {ProductionHistoryComponent} from './historyProduction/production.history.component';
import {AuthGuardService as AuthGuard} from '../authGuard/auth-guard.services';
import {NgMatModule} from 'app/angular-material-module/angular-material-module.module';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';

const routes = [
    {
        path: 'production/daily',
        component: ProductionLibraryComponent, canActivate: [AuthGuard]
    },
    {
        path: 'production/history',
        component: ProductionHistoryComponent, canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        ProductionLibraryComponent,
        ProductionHistoryComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        NgMatModule,
        CommonModule,
        ReactiveFormsModule
    ],
    exports: [
        ProductionLibraryComponent,
        ProductionHistoryComponent
    ]
})

export class ProductionModule {
}
