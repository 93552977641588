import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../../../services/api/api.service';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { UserData } from '../../invoices/library/invoices.library.component';
import { MatTableDataSource } from '@angular/material';


@Component({
    selector: 'InvoicesPDefineComponent',
    templateUrl: './invoicesP.define.component.html',
    styleUrls: ['./invoicesP.define.component.scss']
})
export class InvoicesPDefineComponent implements OnInit {
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    invoiceFormP: FormGroup;
    editFields: any;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private api: ApiService,
        private router: Router
    ) {

        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.activeRoute.params.subscribe(params => {
            this.editFields = params;
        }
        );
        this.invoiceFormP = fb.group({
            'serialKey': null,
            'serialNumber': 1,
            'reservedNumbers': null
        });
        if (this.editFields.id) {
            this.api.getOne('ProformDetailSet', { 'id': this.editFields.id }).then(param => {

                this.invoiceFormP = fb.group({
                    'serialKey': param['serialKey'],
                    'serialNumber': param['serialNumber'],
                    'reservedNumbers': param['reservedNumbers'].join(),
                    'id': param['_id']
                });
            });
        }

    }

    onSubmit(): void {
        const strReservedNumbers = this.invoiceFormP.value.reservedNumbers;
        this.invoiceFormP.value.serialNumber = this.invoiceFormP.value.serialNumber - 1;
        if (this.invoiceFormP.value.reservedNumbers) {

            this.invoiceFormP.value.reservedNumbers = strReservedNumbers.split(',');
        }
        this.api.post('ProformDetailSet', this.invoiceFormP.value).then(param => {
            this.router.navigate(['invoiceP/library']);
        });

    }

    disableSubmit(): boolean {
        if (this.invoiceFormP.value.serialNumber && this.invoiceFormP.value.serialKey) {

            return false;
        }
        return true;
    }

    ngOnInit(): void {

    }

}
