import { Component, OnInit, Inject } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '../../../services/api/api.service';
import { MatTableDataSource, MatSnackBar, MatPaginator, MatSort, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MergeOPsService } from '../../../services/mergeOPs/mergeOPs.service';
import { ViewChild } from '@angular/core';
@Component({
    selector: 'StocksLibraryComponent',
    templateUrl: './stocks.library.component.html',
    styleUrls: ['./stocks.library.component.scss']
})

export class StocksLibraryComponent implements OnInit {

    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    displayedColumns = ['name', 'color', 'size', 'quantity', 'newQuantity', 'details', 'price', 'buttons'];
    dataSource: MatTableDataSource<UserData>;
    public quantity = new FormControl();
    public filteredProducts: any = [];
    public products: any = [];
    characteristicsList: any = [];
    characteristicTypesList: any;
    productForm: FormGroup;
    public orderProductsFromApi: any = [];
    public productIdName: any = [];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;


    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private fb: FormBuilder,
        private api: ApiService,
        public snackBar: MatSnackBar,
        public MergeOp: MergeOPsService,
        public dialog: MatDialog,
    ) {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        // API CALLS HERE

        this.api.getAll('CharacteristicType').then(param => {
            this.characteristicTypesList = param;
            this.api.getAll('Characteristic').then(param => {
                for (const characteristic of <any>param) {
                    this.characteristicsList[characteristic._id] = {
                        'value': characteristic.value,
                        'type': this.characteristicTypesList.filter(x => x._id === characteristic.characteristicType)[0].name
                    };
                }
                this.api.getAll('Product').then(param => {
                    this.products = param;
                    for (const product of <any>param) {
                        this.productIdName[product._id] = product.name;
                    }
                    const getWithParamsObject = [];
                    getWithParamsObject.push({
                        $or: [
                            { 'inProductionAt': { $ne: null } },
                            { 'producedAt': { $ne: null } },
                        ],
                        'deletedAt': null,
                        'order': null
                    });
                    this.api.getWithParams('OrderProduct', getWithParamsObject).then(param => {
                        this.orderProductsFromApi = param;
                        console.log(param);
                        for (const product of this.orderProductsFromApi) {
                            product.productID = product.product;
                            product.product = this.productIdName[product.product];
                            product.characteristics[2] = this.characteristicsList[product.characteristics[0]];
                            product.characteristics[3] = this.characteristicsList[product.characteristics[1]];
                        }
                        this.orderProductsFromApi.forEach(op => {
                            op.name = op.product;
                        });
                        console.log(this.orderProductsFromApi);
                        console.log(this.orderProductsFromApi.length);
                        const merge = this.MergeOp.mergeOrderProducts(this.orderProductsFromApi);
                        this.dataSource = new MatTableDataSource(merge);
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                    });
                });
            });
        });
    }
    applyFilter(filterValue: string): void {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }
    // lista de order product cu id null sau status order "returnat"
    ngOnInit(): void {
    }
    edit(row: UserData): void {
        if (Number(row['newQuantity']) === 0) {
            // this.api.hardDelete('OrderProduct', row.OPsIDs).then(response => {
            this.api.softDelete('OrderProduct', row.OPsIDs).then(response => {
                this.dataSource.data.splice(this.dataSource.data.indexOf(row), 1);
                this.dataSource._updateChangeSubscription();
            });
        }
        else if (Number(row['newQuantity']) < 0) {
            this.snackBar.open('Cantitate invalida!', 'Inchide', {
                duration: 10000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
            });
        }
        else {
            const orderProductUpdate = [{ 'id': row['_id'], 'quantity': row['newQuantity'] }];
            if (row['quantity'] === Number(row['newQuantity'])) {
                row['newQuantity'] = null;


            } else if (row['quantity'] > Number(row['newQuantity'])) {
                let counter = row['quantity'] - Number(row['newQuantity']);
                const opToDelete = [];

                row.OPsIDs.forEach(opID => {
                    if (counter > 0) {
                        opToDelete.push(opID);
                        counter--;
                    }
                });
                // this.api.hardDelete('OrderProduct', opToDelete).then(response => {
                this.api.softDelete('OrderProduct', opToDelete).then(response => {
                    const index = this.dataSource.data.indexOf(row);
                    const updateDataSource = this.dataSource.data;
                    row['quantity'] = row['newQuantity'];
                    row['newQuantity'] = null;
                    this.dataSource = new MatTableDataSource(updateDataSource);
                    this.dataSource._updateChangeSubscription();
                });

            } else if (row['quantity'] < Number(row['newQuantity'])) {

                const dialogRef = this.dialog.open(DialogConfirmationComponent, {
                    width: '30%',
                    data: {}
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result === 'confirmed') {


                        const OPsToAdd = [];
                        let counter = Number(row['newQuantity']) - row['quantity'];
                        const obj = {
                            'characteristics': [row.characteristics[0], row.characteristics[1]],
                            'details': row.details,
                            'product': row.productID,
                            'producedAt': Date.now(),
                            'price': row.price
                        };
                        while (counter > 0) {
                            OPsToAdd.push(obj);
                            counter--;
                        }
                        this.api.post('OrderProduct', OPsToAdd).then((createdOrderProducts: any) => {
                            const updateDataSource = this.dataSource.data;
                            row['quantity'] = row['newQuantity'];
                            row['newQuantity'] = null;
                            this.dataSource = new MatTableDataSource(updateDataSource);
                            this.dataSource._updateChangeSubscription();
                        });
                    } else {
                        row['newQuantity'] = null;
                        this.dataSource._updateChangeSubscription();
                    }
                });


            }


        }


    }
}
export interface UserData {
    id: string;
    name: string;
    size: string;
    color: string;
    details: string;
    quantity: number;
    price: string;
    newQuantity: number;
    OPsIDs: any;
    characteristics: any;
    productID: string;
}
@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: 'modalConfirm.html',
})
export class DialogConfirmationComponent {
    constructor(
        public dialogRef: MatDialogRef<DialogConfirmationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
